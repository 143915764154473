module.exports = Object.freeze({
  Westbeach: {
    "coordinates": [
        [
          -479.6938562,
          34.4080338
        ],
        [
          -479.6987164,
          34.4116364
        ],
        [
          -479.6951544,
          34.4127074
        ],
        [
          -479.6922469,
          34.4134332
        ],
        [
          -479.691056,
          34.4125126
        ],
        [
          -479.690305,
          34.4120789
        ],
        [
          -479.6894574,
          34.4122117
        ],
        [
          -479.6893609,
          34.4121409
        ],
        [
          -479.6937811,
          34.4079984
        ]
    ],
  },
  Downtown: {
    coordinates: [
        [
          -479.7199541,
          34.4258282
        ],
        [
          -479.7169769,
          34.4285937
        ],
        [
          -479.7146729,
          34.4308016
        ],
        [
          -479.6933627,
          34.4149998
        ],
        [
          -479.6954226,
          34.4133181
        ],
        [
          -479.6991348,
          34.4122029
        ],
        [
          -479.7037911,
          34.4133889
        ],
        [
          -479.7084689,
          34.4171771
        ],
        [
          -479.7119236,
          34.4194251
        ],
        [
          -479.7149277,
          34.4217616
        ],
        [
          -479.7177172,
          34.4238148
        ],
        [
          -479.7200131,
          34.4257087
        ]
    ],
  },
  OakPark: {
    "coordinates": [
      
        [
          -479.7200614,
          34.4258636
        ],
        [
          -479.7213542,
          34.4260716
        ],
        [
          -479.7222769,
          34.4262441
        ],
        [
          -479.723382,
          34.42643
        ],
        [
          -479.7256994,
          34.4269521
        ],
        [
          -479.7277594,
          34.4272751
        ],
        [
          -479.729197,
          34.427461
        ],
        [
          -479.7295511,
          34.4280893
        ],
        [
          -479.7296369,
          34.4284742
        ],
        [
          -479.7283226,
          34.4286247
        ],
        [
          -479.7280383,
          34.4297662
        ],
        [
          -479.728049,
          34.4298857
        ],
        [
          -479.7281241,
          34.4305229
        ],
        [
          -479.7280383,
          34.4310096
        ],
        [
          -479.7277808,
          34.4314166
        ],
        [
          -479.727695,
          34.4318679
        ],
        [
          -479.7273302,
          34.4321157
        ],
        [
          -479.7274268,
          34.4326113
        ],
        [
          -479.7268796,
          34.4346377
        ],
        [
          -479.7262359,
          34.4363366
        ],
        [
          -479.7261822,
          34.436434
        ],
        [
          -479.7208071,
          34.4316379
        ],
        [
          -479.7170091,
          34.4285937
        ],
        [
          -479.7200346,
          34.4258857
        ]
    ],
  },
  UpperEast: {
    "coordinates": [
        [
          -479.7070956,
          34.4252131
        ],
        [
          -479.722352,
          34.4364163
        ],
        [
          -479.7247767,
          34.4387523
        ],
        [
          -479.7247338,
          34.4393186
        ],
        [
          -479.7230601,
          34.4398495
        ],
        [
          -479.7214723,
          34.4398672
        ],
        [
          -479.720335,
          34.4400087
        ],
        [
          -479.7195625,
          34.4410174
        ],
        [
          -479.7190475,
          34.4408936
        ],
        [
          -479.7169018,
          34.4413183
        ],
        [
          -479.7160435,
          34.4402742
        ],
        [
          -479.7159791,
          34.4398849
        ],
        [
          -479.7141552,
          34.4395663
        ],
        [
          -479.7132325,
          34.4394425
        ],
        [
          -479.7126317,
          34.440168
        ],
        [
          -479.7128677,
          34.4407166
        ],
        [
          -479.7135329,
          34.4421854
        ],
        [
          -479.7142839,
          34.4428755
        ],
        [
          -479.714005,
          34.4428755
        ],
        [
          -479.7066879,
          34.4446274
        ],
        [
          -479.703598,
          34.4420792
        ],
        [
          -479.7072458,
          34.4404512
        ],
        [
          -479.7085118,
          34.4391593
        ],
        [
          -479.7063661,
          34.4380621
        ],
        [
          -479.7061729,
          34.4380444
        ],
        [
          -479.7014737,
          34.4370534
        ],
        [
          -479.7008514,
          34.4347881
        ],
        [
          -479.7007227,
          34.4345934
        ],
        [
          -479.6991777,
          34.4325582
        ],
        [
          -479.7070098,
          34.4253547
        ]
    ],
  },
  Laguna: {
    "coordinates": [
        [
          -479.6885991,
          34.419372
        ],
        [
          -479.6870971,
          34.4197437
        ],
        [
          -479.6776128,
          34.4206819
        ],
        [
          -479.6943498,
          34.4325582
        ],
        [
          -479.6999073,
          34.4275495
        ],
        [
          -479.6979117,
          34.4261335
        ],
        [
          -479.6960235,
          34.4247175
        ],
        [
          -479.6921396,
          34.4218501
        ],
        [
          -479.6885991,
          34.419372
        ]
    ],
  },
  
    
    Funkzone: {
      "coordinates": [
          [
            -479.6933842,
            34.4143448
          ],
          [
            -479.690702,
            34.4123976
          ],
          [
            -479.6903157,
            34.412256
          ],
          [
            -479.6899939,
            34.4122825
          ],
          [
            -479.6897256,
            34.4123533
          ],
          [
            -479.689039,
            34.4125746
          ],
          [
            -479.6887493,
            34.4126897
          ],
          [
            -479.6881163,
            34.413265
          ],
          [
            -479.6870542,
            34.4142563
          ],
          [
            -479.6873653,
            34.4151503
          ],
          [
            -479.687376,
            34.4156371
          ],
          [
            -479.6873546,
            34.4160265
          ],
          [
            -479.6874619,
            34.4162124
          ],
          [
            -479.6880627,
            34.4167611
          ],
          [
            -479.6885026,
            34.4170443
          ],
          [
            -479.6893072,
            34.4178409
          ],
          [
            -479.6894467,
            34.4179028
          ],
          [
            -479.6933305,
            34.4144156
          ]
        ],
      },
    Eastbeach: {
      "coordinates": [
          [
            -479.6867108,
            34.4142386
          ],
          [
            -479.6870971,
            34.4152299
          ],
          [
            -479.6871185,
            34.4161504
          ],
          [
            -479.6877623,
            34.4168231
          ],
          [
            -479.6883631,
            34.4172125
          ],
          [
            -479.6892858,
            34.4180622
          ],
          [
            -479.6878266,
            34.418841
          ],
          [
            -479.6852946,
            34.4193189
          ],
          [
            -479.6839428,
            34.4194251
          ],
          [
            -479.6814752,
            34.4197437
          ],
          [
            -479.6770334,
            34.4199916
          ],
          [
            -479.674201,
            34.4197791
          ],
          [
            -479.6718192,
            34.4195313
          ],
          [
            -479.6704674,
            34.4197083
          ],
          [
            -479.6676135,
            34.420381
          ],
          [
            -479.6656179,
            34.4208058
          ],
          [
            -479.6649313,
            34.4208943
          ],
          [
            -479.6631289,
            34.4198146
          ],
          [
            -479.6636009,
            34.4186286
          ],
          [
            -479.6639442,
            34.4181153
          ],
          [
            -479.664309,
            34.4176727
          ],
          [
            -479.6669912,
            34.417301
          ],
          [
            -479.6684718,
            34.4173364
          ],
          [
            -479.672184,
            34.4166637
          ],
          [
            -479.680295,
            34.4153892
          ],
          [
            -479.6832132,
            34.4149821
          ],
          [
            -479.6857667,
            34.4145395
          ],
          [
            -479.6865177,
            34.4142032
          ]
      ],
    },
    
Westside: {
coordinates: [
    
      [
        -479.7336388,
        34.4273194
      ],
      [
        -479.7279954,
        34.4264875
      ],
      [
        -479.7209573,
        34.424806
      ],
      [
        -479.7183609,
        34.4234785
      ],
      [
        -479.7143698,
        34.4205934
      ],
      [
        -479.7102714,
        34.4176727
      ],
      [
        -479.7019887,
        34.4116364
      ],
      [
        -479.6999288,
        34.4113531
      ],
      [
        -479.698813,
        34.4115656
      ],
      [
        -479.6976435,
        34.4106362
      ],
      [
        -479.6986735,
        34.4095917
      ],
      [
        -479.6989524,
        34.4090075
      ],
      [
        -479.6987379,
        34.4078656
      ],
      [
        -479.6991885,
        34.4071132
      ],
      [
        -479.7006798,
        34.4068565
      ],
      [
        -479.7014737,
        34.4065556
      ],
      [
        -479.7017634,
        34.4067769
      ],
      [
        -479.7036409,
        34.4079542
      ],
      [
        -479.7037697,
        34.4089101
      ],
      [
        -479.7049713,
        34.409512
      ],
      [
        -479.7067523,
        34.4112115
      ],
      [
        -479.7080398,
        34.4117957
      ],
      [
        -479.710443,
        34.4135128
      ],
      [
        -479.7122669,
        34.4127162
      ],
      [
        -479.7139192,
        34.4132296
      ],
      [
        -479.7141981,
        34.413442
      ],
      [
        -479.7166443,
        34.4140793
      ],
      [
        -479.7176743,
        34.4132296
      ],
      [
        -479.7191548,
        34.4136544
      ],
      [
        -479.720614,
        34.4143448
      ],
      [
        -479.7250557,
        34.4165044
      ],
      [
        -479.725914,
        34.4173541
      ],
      [
        -479.7256565,
        34.4183719
      ],
      [
        -479.7271264,
        34.4198854
      ],
      [
        -479.7280812,
        34.4210359
      ],
      [
        -479.7296476,
        34.4211598
      ],
      [
        -479.7298408,
        34.421266
      ],
      [
        -479.7287893,
        34.4223634
      ],
      [
        -479.7267938,
        34.4245051
      ],
      [
        -479.7267723,
        34.4246998
      ],
      [
        -479.7270191,
        34.4246998
      ],
      [
        -479.7306991,
        34.4249565
      ],
      [
        -479.7319865,
        34.4251423
      ],
      [
        -479.7324371,
        34.4241334
      ],
      [
        -479.7325552,
        34.4239033
      ],
      [
        -479.7328019,
        34.4236998
      ],
      [
        -479.7341859,
        34.4239387
      ],
      [
        -479.7346687,
        34.4243724
      ],
      [
        -479.7351515,
        34.4247264
      ],
      [
        -479.7355807,
        34.4250627
      ],
      [
        -479.7358704,
        34.4250538
      ],
      [
        -479.7359025,
        34.4254521
      ],
      [
        -479.7358596,
        34.4255937
      ],
      [
        -479.7355807,
        34.425753
      ],
      [
        -479.7351515,
        34.4259654
      ],
      [
        -479.7347546,
        34.4259919
      ],
      [
        -479.7340035,
        34.4262663
      ],
      [
        -479.7336602,
        34.4268415
      ],
      [
        -479.7336388,
        34.4272309
      ]
    ]
},

Harbor: {
  coordinates: [
    
      [
        -479.7012806,
        34.4064936
      ],
      [
        -479.7004437,
        34.4067326
      ],
      [
        -479.6991777,
        34.4071221
      ],
      [
        -479.6987164,
        34.4076266
      ],
      [
        -479.6989095,
        34.408503
      ],
      [
        -479.6988881,
        34.4092199
      ],
      [
        -479.6983731,
        34.4097864
      ],
      [
        -479.6976006,
        34.4104857
      ],
      [
        -479.6974611,
        34.41053
      ],
      [
        -479.6939635,
        34.4080692
      ],
      [
        -479.6945322,
        34.4072018
      ],
      [
        -479.6946502,
        34.4061218
      ],
      [
        -479.6948969,
        34.4043692
      ],
      [
        -479.6954334,
        34.4036344
      ],
      [
        -479.6967423,
        34.4031476
      ],
      [
        -479.6977079,
        34.403183
      ],
      [
        -479.6976972,
        34.4035105
      ],
      [
        -479.6978796,
        34.4039089
      ],
      [
        -479.6992099,
        34.4047144
      ],
      [
        -479.6997035,
        34.4053075
      ],
      [
        -479.7006154,
        34.406175
      ],
      [
        -479.7013128,
        34.4064936
      ]
    ]
},


Samarkand: {
  "coordinates": [
    
      [
        -479.7292614,
        34.4273548
      ],
      [
        -479.7335958,
        34.4286114
      ],
      [
        -479.7335958,
        34.4351775
      ],
      [
        -479.7339177,
        34.438363
      ],
      [
        -479.7342181,
        34.4396017
      ],
      [
        -479.7341537,
        34.4401503
      ],
      [
        -479.7301197,
        34.4401326
      ],
      [
        -479.7269869,
        34.4371242
      ],
      [
        -479.7262788,
        34.4364871
      ],
      [
        -479.7264183,
        34.436257
      ],
      [
        -479.7267509,
        34.4351421
      ],
      [
        -479.7271585,
        34.4336731
      ],
      [
        -479.7273946,
        34.4330183
      ],
      [
        -479.7273517,
        34.4326821
      ],
      [
        -479.7273731,
        34.4318502
      ],
      [
        -479.7275448,
        34.431337
      ],
      [
        -479.7277594,
        34.430399
      ],
      [
        -479.7279954,
        34.4298503
      ],
      [
        -479.7280169,
        34.4292309
      ],
      [
        -479.7281349,
        34.4290539
      ],
      [
        -479.7281992,
        34.4288857
      ],
      [
        -479.7296584,
        34.4284344
      ],
      [
        -479.7293901,
        34.4276025
      ],
      [
        -479.7292399,
        34.4274079
      ]
  ],
},
Uptown: {
  "coordinates": [
      [
        -479.7337246,
        34.4279034
      ],
      [
        -479.7363424,
        34.428222
      ],
      [
        -479.740119,
        34.4290362
      ],
      [
        -479.7423077,
        34.4301335
      ],
      [
        -479.7451401,
        34.4317971
      ],
      [
        -479.7495174,
        34.4350536
      ],
      [
        -479.7526503,
        34.4378143
      ],
      [
        -479.7536373,
        34.4389469
      ],
      [
        -479.7539377,
        34.4400087
      ],
      [
        -479.7540236,
        34.4403627
      ],
      [
        -479.7529078,
        34.440345
      ],
      [
        -479.7509766,
        34.4403096
      ],
      [
        -479.746685,
        34.4402565
      ],
      [
        -479.7425222,
        34.4402919
      ],
      [
        -479.7373295,
        34.4401857
      ],
      [
        -479.7358704,
        34.4401857
      ],
      [
        -479.734261,
        34.440168
      ],
      [
        -479.7341967,
        34.4394955
      ],
      [
        -479.7339177,
        34.438363
      ],
      [
        -479.7337675,
        34.4369649
      ],
      [
        -479.7336602,
        34.4351598
      ],
      [
        -479.7337031,
        34.4333369
      ],
      [
        -479.7336817,
        34.4316202
      ],
      [
        -479.7336602,
        34.4294078
      ],
      [
        -479.7337246,
        34.4280981
      ],
      [
        -479.7337031,
        34.4279388
      ]
  ],
},
Uptown2: {
  "coordinates": [
      [
        -479.7540236,
        34.4404335
      ],
      [
        -479.7545171,
        34.4408228
      ],
      [
        -479.7594738,
        34.4416191
      ],
      [
        -479.7599244,
        34.4416899
      ],
      [
        -479.7597528,
        34.4441496
      ],
      [
        -479.7510624,
        34.4441142
      ],
      [
        -479.7493243,
        34.4441142
      ],
      [
        -479.7492599,
        34.4417961
      ],
      [
        -479.7467065,
        34.4416899
      ],
      [
        -479.7465777,
        34.4424154
      ],
      [
        -479.7450328,
        34.4424508
      ],
      [
        -479.744153,
        34.4428048
      ],
      [
        -479.7429943,
        34.4427871
      ],
      [
        -479.7422433,
        34.4424685
      ],
      [
        -479.7401404,
        34.4417961
      ],
      [
        -479.7400546,
        34.4403981
      ],
      [
        -479.7537875,
        34.440345
      ]
  ],
},
SanRoque: {
  "coordinates": [
      [
        -479.7466421,
        34.4425039
      ],
      [
        -479.7465348,
        34.4551733
      ],
      [
        -479.741621,
        34.4525016
      ],
      [
        -479.7400975,
        34.4511037
      ],
      [
        -479.7366214,
        34.4500244
      ],
      [
        -479.7352052,
        34.4497767
      ],
      [
        -479.7332311,
        34.4492458
      ],
      [
        -479.73351,
        34.4466447
      ],
      [
        -479.7347331,
        34.4460077
      ],
      [
        -479.7348619,
        34.4454591
      ],
      [
        -479.7366858,
        34.4439727
      ],
      [
        -479.7375441,
        34.4436011
      ],
      [
        -479.7367287,
        34.443141
      ],
      [
        -479.7350228,
        34.4412475
      ],
      [
        -479.7343898,
        34.4405396
      ],
      [
        -479.7402048,
        34.4405485
      ],
      [
        -479.740119,
        34.441858
      ],
      [
        -479.742254,
        34.4423889
      ],
      [
        -479.7428656,
        34.4428048
      ],
      [
        -479.7442818,
        34.4428844
      ],
      [
        -479.7452796,
        34.4425128
      ],
      [
        -479.7466207,
        34.4425305
      ]
  ],
},
eSanRoque: {
  "coordinates": [
      [
        -479.7342181,
        34.4405396
      ],
      [
        -479.7373724,
        34.4435834
      ],
      [
        -479.7349906,
        34.4454414
      ],
      [
        -479.7347975,
        34.4463439
      ],
      [
        -479.7338319,
        34.4481842
      ],
      [
        -479.7341537,
        34.4494405
      ],
      [
        -479.724648,
        34.4467686
      ],
      [
        -479.7247338,
        34.4403096
      ],
      [
        -479.7341752,
        34.4403804
      ]
  ],
},

Eastside: {
  "coordinates": [
      [
        -479.6728384,
        34.4209208
      ],
      [
        -479.6780205,
        34.4246644
      ],
      [
        -479.686904,
        34.4310892
      ],
      [
        -479.6878052,
        34.4325051
      ],
      [
        -479.6851659,
        34.4335139
      ],
      [
        -479.6833205,
        34.4338147
      ],
      [
        -479.6807027,
        34.4321511
      ],
      [
        -479.6776342,
        34.429868
      ],
      [
        -479.6775913,
        34.4296379
      ],
      [
        -479.6752739,
        34.4278857
      ],
      [
        -479.6733856,
        34.4268592
      ],
      [
        -479.6728706,
        34.4265229
      ],
      [
        -479.6727204,
        34.4264344
      ],
      [
        -479.6725273,
        34.4275141
      ],
      [
        -479.6727419,
        34.4294609
      ],
      [
        -479.6722913,
        34.430045
      ],
      [
        -479.6720338,
        34.4301866
      ],
      [
        -479.6684933,
        34.4279565
      ],
      [
        -479.6679568,
        34.4270185
      ],
      [
        -479.6674633,
        34.4265583
      ],
      [
        -479.6671629,
        34.4263813
      ],
      [
        -479.6651888,
        34.426045
      ],
      [
        -479.6650815,
        34.4259919
      ],
      [
        -479.6651459,
        34.4260008
      ],
      [
        -479.6651673,
        34.4253636
      ],
      [
        -479.6651995,
        34.4246467
      ],
      [
        -479.6647811,
        34.423629
      ],
      [
        -479.6648991,
        34.4232396
      ],
      [
        -479.6652424,
        34.4226732
      ],
      [
        -479.6656179,
        34.4217439
      ],
      [
        -479.6714437,
        34.4200447
      ],
      [
        -479.6727526,
        34.420912
      ]
    ],
},




Montecito: {
  "coordinates": [
      [
        -479.6524429,
        34.4171771
      ],
      [
        -479.6528077,
        34.4196021
      ],
      [
        -479.6528506,
        34.4212837
      ],
      [
        -479.6528506,
        34.4215138
      ],
      [
        -479.650147,
        34.4208589
      ],
      [
        -479.6437097,
        34.4192127
      ],
      [
        -479.6401691,
        34.4187879
      ],
      [
        -479.6399331,
        34.4202217
      ],
      [
        -479.6399546,
        34.4209828
      ],
      [
        -479.6409202,
        34.4204872
      ],
      [
        -479.6428943,
        34.4198677
      ],
      [
        -479.6443748,
        34.4199562
      ],
      [
        -479.6465635,
        34.4204518
      ],
      [
        -479.6495676,
        34.421089
      ],
      [
        -479.6523142,
        34.4223457
      ],
      [
        -479.6524858,
        34.4227705
      ],
      [
        -479.6494174,
        34.4247883
      ],
      [
        -479.6480656,
        34.4262574
      ],
      [
        -479.6477652,
        34.426753
      ],
      [
        -479.6508551,
        34.427107
      ],
      [
        -479.6527433,
        34.4273194
      ],
      [
        -479.6543312,
        34.4272486
      ],
      [
        -479.6548462,
        34.4361508
      ],
      [
        -479.6546745,
        34.4394779
      ],
      [
        -479.6551895,
        34.4415306
      ],
      [
        -479.6584511,
        34.4420261
      ],
      [
        -479.6648026,
        34.4411767
      ],
      [
        -479.6708107,
        34.4414599
      ],
      [
        -479.6752739,
        34.4420261
      ],
      [
        -479.6739006,
        34.453634
      ],
      [
        -479.6733856,
        34.4577388
      ],
      [
        -479.6376801,
        34.4520769
      ],
      [
        -479.6110725,
        34.4486796
      ],
      [
        -479.5975113,
        34.4474056
      ],
      [
        -479.5881557,
        34.4462023
      ],
      [
        -479.5874691,
        34.4445743
      ],
      [
        -479.5897865,
        34.4391239
      ],
      [
        -479.5940781,
        34.4343811
      ],
      [
        -479.6050644,
        34.4295671
      ],
      [
        -479.608326,
        34.4252485
      ],
      [
        -479.6103859,
        34.4236201
      ],
      [
        -479.6119308,
        34.4220625
      ],
      [
        -479.6131325,
        34.4209297
      ],
      [
        -479.6187115,
        34.4196552
      ],
      [
        -479.6209431,
        34.4177435
      ],
      [
        -479.6308994,
        34.4185224
      ],
      [
        -479.6345043,
        34.4161858
      ],
      [
        -479.6375942,
        34.4154777
      ],
      [
        -479.6520138,
        34.4171771
      ]
  ],
},
IslaVista: {
  "coordinates": [
      [
        -479.8695731,
        34.4092111
      ],
      [
        -479.8695731,
        34.4173541
      ],
      [
        -479.8535872,
        34.4174249
      ],
      [
        -479.8534155,
        34.4081489
      ],
      [
        -479.8586297,
        34.4090518
      ],
      [
        -479.8635221,
        34.4092996
      ],
      [
        -479.8668051,
        34.4091757
      ],
      [
        -479.869616,
        34.4092642
      ]
  ],
}, 

});