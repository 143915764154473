import React, { useEffect, useState } from "react";
import { getPosts, getAll } from "../webRequests/posts";
import { Typography, makeStyles, Paper, Box } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
const useStyles = makeStyles((theme) => ({

mainContainer: {
    width: "100%",
    height: "100%",
    backgroundColor: "#f7f7f7",
    paddingTop: "12vh",
    paddingBottom: "10vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: 0
},
categoryText: {
    color: "#a99136",
    fontFamily: "sweet-sans-pro",
},
headlineText: {
    fontSize: "2em",
    color: "black",
    fontWeight: 800,
    fontFamily: "sweet-sans-pro",
    marginBottom: 20,
    marginTop: 10,
    [theme.breakpoints.down("sm")]: {
        fontSize: 16
    }
},
mainText: {
    fontFamily: "sweet-sans-pro",
    color: "black",
    marginBottom: 10, 
    [theme.breakpoints.down("sm")]: {
        fontSize: 12
    }
},
box: {
    height: "150px",
    width: "225px",
    marginTop: 10,
},
boxImage: {
    height: "100%",
    width: "100%"
},
posts: {
    maxWidth: "90%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: 0,
    margin: 0
},
date: {
    fontFamily: "sweet-sans-pro",
    fontWeight: 600,
    marginBottom: 40
},
allPostHolder: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: "#f7f7f7"
},

flexWide: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    marginTop: 10, 
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "center"
    }
},
allPostBox: {
    width: "250px",
    height: "400px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    padding: 10,
    marginRight: 20,
    cursor: "pointer",
    transitionDuration: ".5s",
    boxShadow: "0px 2px 18px -3px #DBDBDB",
    "&:hover": {
        boxShadow: "10px 10px 18px -3px #DBDBDB"
    },
    [theme.breakpoints.down("sm")]: {
        marginBottom: 10
    }
},

allPostImageBox: {
    width: "250px",
    height: "150px",
    margin: 0,
    padding: 0
},

allPostContentBox: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    padding: 5,
    boxSizing: "border-box",
    overFlow: "hidden",
    color: "black",
    margin: 0,
    padding: 0
},
overflowBox: {
    height: 85,
    overflow: "hidden",
    width: "100%",
    whiteSpace: "no-wrap",
    textOverflow: "ellipsis"
},
smallBoxText: {
    fontFamily: "sweet-sans-pro",
    fontSize: "12",
    marginTop: 10,
},
back: {
    width: "84%",
    display: "flex",
    padding: 0,
    justifyContent: "flex-start",
    alignItems: "flex-start"
},
smallBoxHeadline: {
    fontFamily: "sweet-sans-pro",
    fontSize: "24",
    fontWeight: 800
},
postHeading: {
    fontFamily: "sweet-sans-pro",
    fontSize: "36",
    fontWeight: 800,
    color: "#a99136"
},
postPaper: {
    backgroundColor: "white",
    width: "70%",
    maxWidth: "70%",
    borderRadius: 2,
    boxShadow: "0px 2px 18px -3px #DBDBDB",
    paddingTop: "8%",
    paddingLeft: "7%",
    overflow: "auto",
    paddingRight: "7%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    textAlign: "left",
    minHeight: "100vh", 
    [theme.breakpoints.down("sm")]: {
    width: "98%",
    minHeight: "50vh",
    }
}
}))
const Posts = (props) => {
    console.log(props.pageType)
const classes = useStyles();
const { id } = useParams();
const history = useHistory();
const [post, SetPost] = useState(null);
const [loading, SetLoading] = useState(true);
const [allPostsLoading, SetAllPostsLoading] = useState(true)
const [allPosts, SetAllPosts] = useState(null);

const fetchData = async (id) => {
    const fetchedPost = await getPosts(id); 

    if (fetchedPost) {
        SetPost(fetchedPost.data);
    }
    SetLoading(false);
    }

const fetchAllPosts = async () => {
    const postsArr = await getAll();

    if (postsArr) {
        SetAllPosts(postsArr.data)
    }
    SetAllPostsLoading(false)
}


useEffect(() => {
    if (props.pageType === "singlePost") {
    fetchData(id);
    }
if (props.pageType === "allPosts") {
    SetLoading(false)
}
    }, [id]);

useEffect(() => {
    fetchAllPosts()
},[])

return (
    <div>
    {loading || allPostsLoading ? <Typography>Loading</Typography> :
    <div className={classes.mainContainer}>
        <div className={classes.back}>
            <ArrowLeftIcon />
        <Typography onClick={() => history.push("/")} style={{cursor: "pointer"}} className={classes.categoryText}>Back Home</Typography></div>
        {props.pageType === "singlePost" ? 
        <div className={classes.postPaper}>
            <div className={classes.posts}>
                
<Typography className={classes.categoryText}>{post.category.toUpperCase()} </Typography>
<Box className={classes.box}>
    <img className={classes.boxImage} src={post.source} />
</Box>
<Typography className={classes.headlineText}>{post.headline}</Typography>
<Typography className={classes.date}>{new Date(post.date).toDateString().replace(/^\S+\s/,'')}</Typography>

<Typography className={classes.mainText}>{post.paragraph1}</Typography>
<Typography className={classes.mainText}>{post.paragraph2}</Typography>
</div>
</div> : null}

<div className={classes.allPostHolder}>
      <div className={classes.flexWide}>
                <Typography className={classes.categoryText}>View All Articles</Typography></div>
                <div className={classes.flexWide}>
{
    allPosts ? 
    allPosts.map((p) => {
        return (
            <div className={classes.allPostBox} onClick={() => history.push(`/posts/${p.id}`)}>
                <div className={classes.allPostImageBox}>
                    <img style={{height: "100%", width: "100%"}} src={p.source} />
                </div>
                
                <div className={classes.allPostContentBox}>
                    <Typography className={classes.categoryText}>{p.category}</Typography>
                    <Typography className={classes.smallBoxHeadline}>{p.headline}</Typography>
                    <Typography className={classes.smallBoxText}>{new Date(p.date).toDateString().replace(/^\S+\s/,'')}</Typography>
                    <Box className={classes.overflowBox}>
                    <Typography className={classes.smallBoxText}>{p.text}</Typography></Box>
                    </div>
                    </div>
        )
    }) : null}
</div>
</div>
    </div>
}
</div>
)

}
export default Posts;