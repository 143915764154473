import SETTINGS from "../settings";
import axios from "axios";
const getSoldProperties = async (transactionType, baseLat, baseLng, originalPropertyId) => {
    console.log(baseLat, baseLng)
    let baseUrl = SETTINGS.DOMAINREQUIREMENTS.BACKEND.baseUrl;
    let properties;
    properties = await axios.get(`${baseUrl}/sold/spark/any/santa_barbara/ca/any?baselat=${baseLat}&baselng=${baseLng}&originalPropId=${originalPropertyId}`);
    return properties;
}

export default getSoldProperties