module.exports = Object.freeze({

East_Mesa: {
type: "beach",
coordinates: [
[34.402722064252494, -119.7019974460169],
[34.402474201740205, -119.70145832200491],
[34.40215552028836, -119.70109085936987],
[34.40206699744742, -119.70076362987005],
[34.40190765609773, -119.70038812060797],
[34.400531664526966, -119.70049473841631],
[34.399953071848465, -119.70135824208104],
[34.399285949386176, -119.70205008436882],
[34.39860996895135, -119.70208746765697],
[34.39802251026928, -119.70201756258452],
[34.39732563257885, -119.70304719557011],
[34.396734983386985, -119.70429140527689],
[34.39558908440104, -119.70733772416555],
[34.395284209551335, -119.70990124543154],
[34.39540427836339, -119.712550597386],
[34.3958922982136, -119.71707682509863],
[34.39531796163109, -119.72282614012205],
[34.39963036948451, -119.72210596700155],
[34.40047633668863, -119.72198727925264],
[34.40130459060314, -119.72183640499556],
[34.402256768172876, -119.72167480190241],
[34.402697444013555, -119.72155644942963],
[34.40305844791627, -119.72130935092413],
[34.40382858436951, -119.72035448451483],
[34.40456330564263, -119.71672277350866],
[34.4049882017476, -119.71400301356756],
[34.40564877812542, -119.7134330441519],
[34.40558128228586, -119.70868151088202],
[34.40522277888096, -119.70609049697363],
[34.40461752427613, -119.70506186981642],
[34.403906040229636, -119.70401178498709],
],
},

West_Mesa: {
    type: "beach",
    coordinates: [
[34.39530357550036, -119.72289721866095],
[34.395887871742424, -119.72930233378851],
[34.39818960512924, -119.73459164996588],
[34.40086750372064, -119.73975222011053],
[34.40166975004556, -119.7409350742861],
[34.40211789809236, -119.74228958983862],
[34.40315582188223, -119.74508445163214],
[34.404340898227325, -119.74481891293966],
[34.40389774139087, -119.7437634636923],
[34.4044460162336, -119.74309425254309],
[34.40504573959585, -119.73955910106146],
[34.40467174287449, -119.73366360564673],
[34.405989575959104, -119.73296891351187],
[34.40489968190365, -119.7292272319361],
[34.40628943264521, -119.7284708489939],
[34.405342280186616, -119.7251395453974],
[34.40482001396406, -119.72298841376745],
[34.40452347152216, -119.72169827123129],
[34.40318901752659, -119.721375065045],
[34.4024919062297, -119.72165267367804],
],
},
Alta_Mesa: {
    type: "mountain/hill",
    coordinates: [
[34.405997421264566, -119.73296740797058],
[34.40815946328842, -119.73200717714325],
[34.409098838750225, -119.73146268871322],
[34.409745275630485, -119.73112271872058],
[34.4109581957054, -119.73155522492424],
[34.4121888006295, -119.73192335811153],
[34.41233498389531, -119.73242928978696],
[34.41283521362968, -119.73250606802002],
[34.41321111003472, -119.73077134933963],
[34.41340790869293, -119.72977524396673],
[34.41342768534204, -119.72903663065925],
[34.41354039832287, -119.72800029215112],
[34.41377702593935, -119.72695322480693],
[34.41435649109184, -119.72505220916763],
[34.41308083266401, -119.72393909242645],
[34.41320744512207, -119.72127515596047],
[34.41331635519341, -119.71877215203538],
[34.41365847019357, -119.71516030613974],
[34.414475650895334, -119.71298960276589],
[34.414726364780265, -119.71086181473628],
[34.41314780383247, -119.70858034451201],
[34.412947114902266, -119.70879144274339],
[34.41273314875132, -119.70897035446659],
[34.412557015664746, -119.70892569987744],
[34.41247824532247, -119.70885422319814],
[34.41233840682116, -119.70873272751167],
[34.41209855964961, -119.70840390545025],
[34.41162771452074, -119.7077248036553],
[34.41150071934244, -119.70751668099955],
[34.411400277879174, -119.70713153254881],
[34.411347844275674, -119.7068397165899],
[34.41124230274847, -119.70668201108172],
[34.41110578143049, -119.70647468470678],
[34.41093606733251, -119.70640415099159],
[34.410672384844425, -119.70629562630027],
[34.41026044034065, -119.70591888070747],
[34.40989965552202, -119.70550417033199],
[34.40953001764861, -119.70493925625168],
[34.40904743235106, -119.70390062319755],
[34.40880724510516, -119.70382521226246],
[34.40851837334983, -119.70380344550766],
[34.40804684678681, -119.70377064083412],
[34.4088073769279, -119.70285621710762],
[34.40872310971289, -119.70262492896698],
[34.4069658745257, -119.7000976699096],
[34.40618240056775, -119.7034984018578],
[34.40571541969752, -119.70467305486498],
[34.40556710589201, -119.705997911577],
[34.40577052255792, -119.71314269822732],
[34.403977639796686, -119.71990465192765],
[34.40360908227166, -119.72135642375041],
[34.40395150387724, -119.72138493531791],
[34.40437499780554, -119.7215493948065],
[34.404285072027626, -119.72098429344304],
[34.40426174677539, -119.72110568959441],
[34.404356308773, -119.72134877788311],
[34.40471643133911, -119.72195664659783],
[34.40475608815475, -119.72235232481442],
[34.404848856935125, -119.72280164721131],
[34.40503439418734, -119.72356081713632],
[34.40533907791429, -119.7248592158471],
[34.40569687197683, -119.72617907223001],
[34.40582210385945, -119.72654395742983],
[34.4059384836759, -119.72696248680994],
[34.40605486333047, -119.72744002478838],
[34.40611813167905, -119.72758496884897],
[34.40615876032416, -119.72792334628001],
[34.4063550914904, -119.72854109254375],
[34.40498055618247, -119.72926327732101],
    ],
},

Bel_Air: {
    type: "suburban",
    coordinates: [
[34.405264097102204, -119.73916517813035],
[34.411247801645644, -119.74036680776902],
[34.41356681464402, -119.74028097708054],
[34.41463225564285, -119.73995106537171],
[34.419379554129755, -119.74112319071122],
[34.425188570876514, -119.73579364139863],
[34.424623838436204, -119.73420175034829],
[34.424389419424145, -119.7340593418134],
[34.424234649278226, -119.73383110259005],
[34.42407555757645, -119.73294547070094],
[34.42397399039952, -119.73229050878712],
[34.42380162321978, -119.73159263152905],
[34.42225369055977, -119.72889768202134],
[34.42257118917877, -119.72850339729615],
[34.42242156672195, -119.72816342730351],
[34.42208788831926, -119.7274891870124],
[34.42185024708672, -119.72703941408821],
[34.42159932984706, -119.72664328534432],
[34.42071858985581, -119.72541785110064],
[34.4203512318327, -119.72582437340446],
[34.42023835517264, -119.72585060876139],
[34.41986881701031, -119.72548524160214],
[34.41925035810479, -119.72458879705795],
[34.41880890515522, -119.72407322619387],
[34.41734184992494, -119.72195847202369],
[34.415590470207775, -119.72372277882226],
[34.41501569836885, -119.72449764386096],
[34.41426363485343, -119.72566291699462],
[34.41404249358112, -119.72658887631536],
[34.412814470144944, -119.73315702993699],
[34.41195037201004, -119.73199563343354],
[34.41131639932707, -119.73186420519181],
[34.40978289579579, -119.73140822965928],
[34.4068917336703, -119.73277883846589],
[34.40611000734689, -119.73312082011529],
[34.40589479619916, -119.73434256632157],
    ],
},

West_Side: {
    type: "suburban",
    coordinates: [
[34.42531867183119, -119.73631035141813],
[34.42507530120485, -119.73536621384488],
[34.425018606809495, -119.73521802179681],
[34.424955274976, -119.73504032544957],
[34.42481091140337, -119.73465274624692],
[34.42467318500399, -119.73417128972875],
[34.42433993042825, -119.73383668415414],
[34.42422197734933, -119.73341725371944],
[34.42415269885145, -119.7329924588667],
[34.423598468801465, -119.73095800332891],
[34.423121534861664, -119.73025308527218],
[34.4228879759034, -119.72975201510059],
[34.422583131391036, -119.72932713642882],
[34.4223136863593, -119.72888616450297],
[34.422650959218316, -119.72852993361818],
[34.42168131336723, -119.72655247502195],
[34.42121252732747, -119.72596322722899],
[34.42070391224423, -119.72530424200164],
[34.42029264808487, -119.72566449619995],
[34.41946541189753, -119.7246621882187],
[34.419337356884895, -119.7244792950915],
[34.41918717548635, -119.72426421545612],
[34.41879830674427, -119.723769683169],
[34.41729149243362, -119.72178618960248],
[34.41664234801805, -119.72243746347891],
[34.414612460001194, -119.72484826646911],
[34.413343720396306, -119.7238902149366],
[34.41321385812791, -119.72335754499005],
[34.413225613506555, -119.72265321366655],
[34.41334344379952, -119.7206941952573],
[34.41346431648621, -119.7190709558891],
[34.41350705060942, -119.71816277668046],
[34.41362059327076, -119.71725459747182],
[34.41371463579685, -119.71595439665185],
[34.41374395491571, -119.71522919438945],
[34.41396799689966, -119.71461128048765],
[34.41445922759362, -119.7131353949772],
[34.41472918197164, -119.71163805179464],
[34.41482875508783, -119.7106489872204],
[34.41493385991521, -119.7099962046014],
[34.41501517776993, -119.70968724765049],
[34.41511419736386, -119.70936756186353],
[34.41530642780508, -119.70888694353567],
[34.415542912130185, -119.70846533380615],
[34.415841351628096, -119.70806249953972],
[34.41613536466109, -119.7076972161995],
[34.41700605758907, -119.70899875812398],
[34.418084730664454, -119.71053097002374],
[34.418145578474615, -119.71061478905546],
[34.41812020386047, -119.71076385438545],
[34.420333116376185, -119.71361475797457],
[34.42247516932468, -119.71668023828488],
[34.424599467588294, -119.71972426092309],
[34.425909537411584, -119.72362659044606],
[34.42697385658413, -119.73004015766004],
[34.427442886869486, -119.73359140239576],
    ],
},

Lower_West: {
    type: "suburban",
    coordinates: [
[34.41471572370908, -119.71053374536768],
[34.413950112999046, -119.70946086176173],
[34.413329054864676, -119.70861156964892],
[34.41323879153653, -119.70846829961374],
[34.413166230341545, -119.70838403817687],
[34.41273787113284, -119.70883778779464],
[34.412597173398076, -119.70883019746184],
[34.41243877304584, -119.70864021691601],
[34.41206886407552, -119.70815296746379],
[34.41166354838326, -119.7072741154954],
[34.41100469052058, -119.70622140549382],
[34.410361317439026, -119.70577487472961],
[34.41017435395543, -119.70550140348367],
[34.40989444976955, -119.70525475432787],
[34.40971412385244, -119.70494104994667],
[34.40924391046086, -119.70397758043163],
[34.40905513677736, -119.70379056752076],
[34.40890321462023, -119.70372656536448],
[34.408662776324924, -119.70370279634342],
[34.40815687716325, -119.70367477548457],
[34.40842992073612, -119.70337987482874],
[34.40861887330233, -119.70318153369746],
[34.40864000028085, -119.70312577665142],
[34.4086326108709, -119.70309083211285],
[34.408663049613324, -119.7030677550969],
[34.40873221403171, -119.70298835169163],
[34.40880580418094, -119.70295186363057],
[34.40893970694377, -119.70274477705772],
[34.40704217747862, -119.700070842817],
[34.40706251456462, -119.69975168846263],
[34.40710055516, -119.69942716969021],
[34.407362522803446, -119.69898734444855],
[34.40780679053399, -119.69861194926001],
[34.4081642219837, -119.69883211854636],
[34.40855263252082, -119.69898255039831],
[34.408980418478365, -119.69903236742154],
[34.40924887488009, -119.6989802605022],
[34.40937978343979, -119.69889653954871],
[34.409537246293986, -119.69881818301324],
[34.41011771464272, -119.69815721464752],
[34.410463617183325, -119.69783420461769],
[34.41071657892263, -119.69758629644026],
[34.41070469628159, -119.69763765072446],
[34.4108897565249, -119.69783652650449],
[34.41107481635874, -119.6981641483172],
[34.41172817580637, -119.69905542633596],
[34.411986014117936, -119.70120812721753],
[34.41270411338967, -119.70316770905004],
[34.41412489530629, -119.7048262268097],
[34.41483370245771, -119.70566672637626],
[34.415573906017464, -119.70672632937612],
[34.41598441077639, -119.70743992741306],
[34.41606743429143, -119.70758757934786],
[34.41593803829, -119.70775668895479],
[34.4154160268442, -119.7085082292349],
[34.41510441012253, -119.70915568511934],
[34.4148702379481, -119.70990104163283],
[34.41475972587226, -119.71026621507366],
    ],
},

West_Beach: {
    type: "beach",
    coordinates: [
[34.40542472890483, -119.70611765274307],
[34.404526252828, -119.70477654823563],
[34.40354777545699, -119.70323408261943],
[34.402844027994576, -119.7021129192512],
[34.40207388247882, -119.70039630548168],
[34.402958080700685, -119.69921685292553],
[34.40309503312835, -119.69752724109877],
[34.40305845375921, -119.69684734607664],
[34.40315465708491, -119.69617817989058],
[34.40337921657262, -119.6955787511389],
[34.403674592437135, -119.6951938991084],
[34.40444238197434, -119.69468705153086],
[34.407968673961705, -119.69411176266033],
[34.410175964520946, -119.69173402933178],
[34.4112309057177, -119.69047542523312],
[34.411838032658615, -119.69141253324848],
[34.41232968274888, -119.69207957072328],
[34.41256886832733, -119.6924184538787],
[34.41283460667432, -119.69276270145214],
[34.41309858287113, -119.69315711331097],
[34.41333157940158, -119.69353006749769],
[34.4135446603682, -119.69381987320494],
[34.413704634464565, -119.6940560347319],
[34.41376921148502, -119.6940940941927],
[34.41298670361129, -119.69509825743344],
[34.412668875973296, -119.69559280096135],
[34.41262060142554, -119.69598491201212],
[34.412022140493804, -119.69767617192964],
[34.411706916360195, -119.69864859983119],
[34.410642747965646, -119.69722460111157],
[34.40974042335262, -119.69832097652798],
[34.40925411187186, -119.69877362178083],
[34.408692298518304, -119.69880816646268],
[34.408528804568235, -119.69874615162],
[34.40827445082764, -119.69870173315357],
[34.408117464504905, -119.69853929749051],
[34.40746712639011, -119.69859529984448],
[34.406853629787044, -119.69976510268019],
[34.40672255296881, -119.70092954109785],
[34.406530451186434, -119.70151234093265],
[34.40631179344813, -119.70207904751335],
[34.40606036565516, -119.70265992561767],
    ],
},

Funk_Zone: {
    type: "beach",
    coordinates: [
[34.41387989410569, -119.69398673826437],
[34.41126797141231, -119.69030185560952],
[34.41427515169796, -119.68699200968514],
[34.41533560505047, -119.68736617784272],
[34.41599195089722, -119.68737891833554],
[34.416222763246125, -119.68741747509013],
[34.41640047034252, -119.6875418625332],
[34.41655895432943, -119.6876967601038],
[34.416651472329505, -119.68785467515956],
[34.41674841559495, -119.68799649696122],
[34.417277798520054, -119.6886764369465],
[34.4176293872845, -119.68894596941928],
[34.4179013190898, -119.68935497676081],
[34.41639625131532, -119.69133170573834],
[34.41536865540456, -119.69240983562942],
    ],
},

East_Beach: {
    type: "beach",
    coordinates: [
[34.41803436211265, -119.68929145686991],
[34.41883546204935, -119.68804546983694],
[34.41928253638025, -119.68645616005008],
[34.42011472524141, -119.67734449413543],
[34.41935773784204, -119.67217587736373],
[34.42085006403864, -119.66702134218936],
[34.421390272053856, -119.66540099457775],
[34.42170037140711, -119.66446729247396],
[34.42224057392855, -119.66241779141997],
[34.42260377083787, -119.66077598613624],
[34.422869615265476, -119.65902689249192],
[34.42281685611004, -119.65744946022456],
[34.42230423192065, -119.65523873326306],
[34.42207004408011, -119.65552275405203],
[34.42155264880791, -119.65617155526702],
[34.42085824451677, -119.65672379695748],
[34.42060635197417, -119.6571258349431],
[34.420356500563386, -119.65856067008276],
[34.420283655814366, -119.65926594437038],
[34.42012026537107, -119.66140605379765],
[34.41922705518045, -119.66216721442466],
[34.41847323176927, -119.6629699492914],
[34.41807342611139, -119.66394434553509],
[34.41743312114249, -119.66730934438233],
[34.41740930041001, -119.67015550342326],
[34.416901422801544, -119.67262917068724],
[34.41608922709377, -119.67619248536353],
[34.41578933639003, -119.67808143106227],
[34.41560398721701, -119.67906881925587],
[34.41550714634599, -119.68003474977736],
[34.414891732263605, -119.68424313572173],
[34.414364546981034, -119.68674295452361],
[34.41623595671655, -119.68708627727752],
    ],
},

East_Side: {
    type: "suburban",
    coordinates: [
[34.422594673978566, -119.66210356990119],
[34.42215659206768, -119.66411522666236],
[34.42169859488834, -119.66598740855476],
[34.421687532123514, -119.66606519261619],
[34.42157026672635, -119.66565883795043],
[34.42152878128701, -119.66565250961354],
[34.42150140088577, -119.66584112338965],
[34.42142313160853, -119.66595195310434],
[34.421396719168065, -119.66599865078238],
[34.42139464489228, -119.66610167484974],
[34.42133960742266, -119.66633722728362],
[34.42130448298846, -119.66639575392252],
[34.42128705905107, -119.66654547566792],
[34.42092530410188, -119.66787392350157],
[34.42063435020893, -119.66919164249917],
[34.42021617135756, -119.67059862876555],
[34.420019250433405, -119.67199488619586],
[34.42004358899973, -119.67328385526558],
[34.42042194216483, -119.67452990899106],
[34.42094189839983, -119.67715998256823],
[34.4212494454595, -119.67691472808143],
[34.42352061228252, -119.68006800005456],
[34.42430079307803, -119.68111381011327],
[34.425080966592894, -119.68212743366381],
[34.42583900839756, -119.68311959954222],
[34.426029623993806, -119.68340921525156],
[34.426224664021625, -119.68367737328877],
[34.42661031786657, -119.6842136893632],
[34.42862498113265, -119.68705121504387],
[34.43059092487191, -119.68985118979832],
[34.43179718227273, -119.68845593819638],
[34.43240445245012, -119.68126661220809],
[34.43148524942809, -119.67980112025758],
[34.43106104101406, -119.67903618777414],
[34.43054833774961, -119.67850728968402],
[34.43038075444726, -119.67801057810208],
[34.42990759251747, -119.67798752786835],
[34.429354783339996, -119.6778142739298],
[34.429056392074536, -119.67685513274988],
[34.42913665915313, -119.67686479289328],
[34.42921250144682, -119.67649357935657],
[34.42918878777443, -119.67604189954942],
[34.42892613939017, -119.67565459275862],
[34.4288720747875, -119.67521257309487],
[34.42873836499335, -119.67482419761141],
[34.4280461679548, -119.67351100484153],
[34.42774089285894, -119.67306786409823],
[34.42718340255464, -119.67208828155195],
[34.42711927727662, -119.67091826216561],
[34.42694895615085, -119.66928153841069],
[34.42694902528881, -119.66789100162929],
[34.42617028654817, -119.66764634435309],
[34.42596235201775, -119.66643072741351],
[34.42593144699097, -119.66612112086511],
[34.42585183365364, -119.66582952493115],
[34.425550958161345, -119.66576419323462],
[34.425347429718144, -119.66509268230071],
[34.42545376582938, -119.66350787395855],
[34.42556355875945, -119.66214438976964],
[34.42560255319106, -119.66024446377774],
[34.425522075301565, -119.66060832219442],
[34.42540218799257, -119.66144471040239],
[34.425211501826304, -119.66245275998732],
[34.42508594463525, -119.66438210645934],
[34.42390448095003, -119.66453901568671],
[34.42209021582035, -119.66556093732139],
    ],
},

Milpas: {
    type: "business_district",
    coordinates: [
[34.43050771366832, -119.68988676959972],
[34.42865749483996, -119.68730912349184],
[34.42507593633101, -119.68219069631579],
[34.42126626139998, -119.67713743224819],
[34.42075474776973, -119.67786318729377],
[34.42063616991161, -119.67825825132475],
[34.42062822101564, -119.67867477302784],
[34.42090413762706, -119.67902653206167],
[34.4212287297777, -119.679464121784],
[34.421579871344, -119.67992853359645],
[34.42175986667663, -119.68015001066664],
[34.42191331106034, -119.68037685215484],
[34.42255806120594, -119.68127080706257],
[34.42318510627355, -119.68220767731452],
[34.42439231049512, -119.68385230645474],
[34.42520387757298, -119.68496429959846],
[34.42571585565732, -119.68564904220304],
[34.426334027320614, -119.68654836152882],
[34.42845629453504, -119.68940846636688],
[34.429570504221424, -119.69095653598255],
    ],
},

Lower_East: {
    type: "business_district",
    coordinates: [
[34.41732912699741, -119.69108789046788],
[34.41978957810592, -119.69447820266271],
[34.42162823126263, -119.692729402385],
[34.42332524348142, -119.69085185607457],
[34.42675898813262, -119.6872362383225],
[34.42606511537542, -119.68625186761403],
[34.425384511591595, -119.68533186992192],
[34.42406753721517, -119.683545518718],
[34.42234343650459, -119.68106179317022],
[34.4214813728166, -119.67988966783071],
[34.42102378679559, -119.67928751190686],
[34.420653387815264, -119.67880404373193],
[34.420601599587414, -119.67883555968785],
[34.4203944463552, -119.67966436227346],
[34.420308570265334, -119.68008949240232],
[34.42027579627541, -119.68064336856389],
[34.42007389715123, -119.68266575416112],
[34.419863147134485, -119.68460230906987],
[34.419829404956495, -119.6867319830277],
[34.419128007091246, -119.68944637855077],
    ],
},

Laguna: {
    type: "arts",
    coordinates: [
[34.41989371591862, -119.69453315656521],
[34.42728103396441, -119.70470828197729],
[34.43059072644532, -119.70121068142187],
[34.43148148463783, -119.70254053348982],
[34.43275867411112, -119.70116698067245],
[34.43359120923622, -119.70023350648485],
[34.43361351398062, -119.69979629005282],
[34.43345883958115, -119.69948781965351],
[34.43272473689112, -119.69923565928089],
[34.43168127266071, -119.69785157397875],
[34.43222125171017, -119.6973017047681],
[34.432690434919934, -119.69677329322954],
[34.431805707843445, -119.69540803247837],
[34.43264938112425, -119.69444082614547],
[34.431245379153374, -119.69235782086237],
[34.43038992026488, -119.69100036129912],
[34.43033091702343, -119.69091848847373],
[34.43009492747912, -119.69055766591079],
[34.43001454212209, -119.69050600066203],
[34.42974832035269, -119.69087276001962],
[34.42966350997834, -119.69093911196752],
[34.429596398270256, -119.69106983693177],
[34.42907941388542, -119.69041913944395],
[34.42831464036484, -119.68931783084162],
[34.427580833413025, -119.68837209036212],
[34.4268027716763, -119.68726541734176],
    ],
},

Lower_Riviera: {
    type: "mountain/hill",
    coordinates: [
[34.43149078224451, -119.70266208334876],
[34.43399554300042, -119.70630933105127],
[34.434108366519574, -119.70656414090769],
[34.43420791655705, -119.708782327763],
[34.437214764627534, -119.71244495374258],
[34.43890084550425, -119.71281535439182],
[34.43928376027893, -119.71276452032313],
[34.439489706933166, -119.71258494022175],
[34.439406093343564, -119.71166788054386],
[34.43920501622872, -119.71086602795101],
[34.43877388045677, -119.70991397165332],
[34.4388517495195, -119.70956285807756],
[34.438929618509654, -119.70916882915759],
[34.438501361780126, -119.70833785597337],
[34.43800763039253, -119.70547427996628],
[34.43758379320241, -119.70327001576645],
[34.437053770902686, -119.70181677009077],
[34.43739714569694, -119.69706262418158],
[34.43729673979161, -119.69435563951815],
[34.437252835098924, -119.69347906878068],
[34.437060716717966, -119.69296727846925],
[34.43675832837739, -119.69222264758392],
[34.43646792240154, -119.69145560537537],
[34.43626259081449, -119.69057183489738],
[34.4362121106742, -119.68958614047685],
[34.435938454282635, -119.68909563585137],
[34.43584619581813, -119.68832618148832],
[34.43593533588144, -119.68760500688755],
[34.435900594033434, -119.6870662224998],
[34.435685106367806, -119.68606375557668],
[34.435407676850055, -119.68546362000583],
[34.43515691940106, -119.68516187366569],
[34.4349769516738, -119.68473138129286],
[34.434854500898915, -119.68423383369466],
[34.434661259263045, -119.68382211678494],
[34.433698036237416, -119.68231472401477],
[34.43342723685055, -119.68200627867428],
[34.43305024853935, -119.68178366402228],
[34.43253876354574, -119.68125795540345],
[34.432296293510205, -119.68463083541373],
[34.432195408491786, -119.68808418169446],
[34.430545914628915, -119.68992149704373],
[34.43011567251459, -119.69039390124236],
[34.430791591588864, -119.69136519631775],
[34.43282479168845, -119.69442358541875],
[34.431882584211564, -119.69546025974651],
[34.432776781664245, -119.6967879542959],
[34.4318007163423, -119.69787693550406],
[34.43216479975885, -119.69822830597204],
[34.43242269274515, -119.69867623596453],
[34.43277349989503, -119.69908661503084],
[34.43336322827641, -119.69931460388413],
[34.433655896980206, -119.69969548191237],
[34.433710296621285, -119.70023193241155],
    ],
},

Riviera: {
    type: "mountain/hill",
    coordinates: [
[34.44566274107091, -119.70360920426461],
[34.44523805164477, -119.70120594498727],
[34.44504340160285, -119.70063731667611],
[34.44460101345731, -119.70032618043038],
[34.444255949077466, -119.6992854833326],
[34.443999361819756, -119.69820187089059],
[34.44483990334689, -119.6978800058088],
[34.445450396627734, -119.69502613541695],
[34.44433115553812, -119.69288841483208],
[34.44319420358423, -119.69081506726357],
[34.44430461208911, -119.69084457156274],
[34.44495824206696, -119.69005801376912],
[34.44525795900985, -119.68914270994279],
[34.445337035441, -119.68798231926772],
[34.44515068037533, -119.68722962436291],
[34.44490944118992, -119.68702535738262],
[34.44445585433663, -119.68703566712352],
[34.444250004446296, -119.68661682342204],
[34.44409724110371, -119.68553279188487],
[34.44506579744047, -119.68398700130197],
[34.44560315749581, -119.68383637850198],
[34.44589278027524, -119.68317077157113],
[34.446680217371345, -119.67895970341775],
[34.44590383983643, -119.67672676441285],
[34.44314553992571, -119.6746654867849],
[34.443925261042615, -119.67176803049657],
[34.44289945621847, -119.66860268858287],
[34.44169667710743, -119.66378510591599],
[34.44107965852877, -119.66577522118601],
[34.44071038531535, -119.66671391052219],
[34.44020838680212, -119.66727709059629],
[34.439617902178796, -119.66764715162131],
[34.436972767421494, -119.66869824227902],
[34.43615896931235, -119.66945445758316],
[34.43525667556576, -119.66967423108431],
[34.43460200184688, -119.67035274614605],
[34.43417290088357, -119.67050424904588],
[34.433734948780426, -119.67043581080648],
[34.43260241543167, -119.66937089000854],
[34.432058213941175, -119.66918748348976],
[34.431514008907186, -119.66902553464311],
[34.43154499828761, -119.66906743368159],
[34.431328210651905, -119.66975306288364],
[34.429755286566625, -119.67292676574576],
[34.429556452849, -119.67298808951742],
[34.429348769257466, -119.67300918015385],
[34.42905508031198, -119.67316937862338],
[34.42896382156955, -119.67352806056002],
[34.42893450886743, -119.67398866643921],
[34.42889634670616, -119.67433863119655],
[34.42883606089116, -119.67468323153585],
[34.4289477873553, -119.67520345304652],
[34.42935486942448, -119.67626778187255],
[34.429169033962005, -119.67713065558488],
[34.42971769838996, -119.67775749490389],
[34.43013362170731, -119.67768079787052],
[34.43086812054345, -119.67847154137704],
[34.431399073291004, -119.67916891572091],
[34.431744190768214, -119.67984483239266],
[34.432239741576545, -119.68059585091683],
[34.43278893675188, -119.68134083447072],
[34.433417769091854, -119.68182832595917],
[34.433801039252764, -119.68222998675915],
[34.43408696981526, -119.68274966475579],
[34.43401451941709, -119.68263902363393],
[34.434844653422175, -119.68400896188828],
[34.43533189012709, -119.68509190377804],
[34.4355713595663, -119.68553647992226],
[34.43607020594031, -119.68694195744607],
[34.43599831030509, -119.68783245083901],
[34.436023750306155, -119.68879268166634],
[34.43607767202197, -119.68898982402894],
[34.43614044238577, -119.6891440510473],
[34.43633234796324, -119.68944177624795],
[34.43631465063737, -119.69021089968297],
[34.43636774260375, -119.69050795433137],
[34.43667633899047, -119.6916579514465],
[34.43683229587073, -119.6920462012014],
[34.43719066376927, -119.69302654859635],
[34.437442847665366, -119.69407663342568],
[34.437562302929514, -119.6961875319204],
[34.43763530328465, -119.69728053209397],
[34.4374870903757, -119.69839498993966],
[34.43728689192463, -119.70003516075226],
[34.437157481620936, -119.70166460272881],
[34.43779236443848, -119.70339999196145],
[34.43821266748273, -119.70590517518136],
[34.438280966527735, -119.70641915348384],
[34.438384659303146, -119.70713697967145],
[34.4386451349873, -119.70828295347306],
[34.43903391140887, -119.70926263031575],
[34.43897141973052, -119.7100384592733],
[34.439336414713026, -119.71069023606393],
[34.43961513708058, -119.71307203766915],
[34.443441945406256, -119.70919892785165],
[34.444112171831854, -119.70816627738091],
[34.444534655033415, -119.70713362691018],
    ],
},

Upper_East: {
    type: "suburban",
    coordinates: [
[34.42528748341081, -119.70702619803943],
[34.437986026793496, -119.72422452224292],
[34.43917613980461, -119.72352178348102],
[34.43949136206569, -119.72270236862697],
[34.43906581173238, -119.72180014056958],
[34.43920655603962, -119.72081208182372],
[34.43979013083912, -119.71900913445153],
[34.44024982619431, -119.71791429025926],
[34.44004590149926, -119.71690527675547],
[34.43994815572318, -119.71542419446506],
[34.440478221261905, -119.71415986819066],
[34.44060126677198, -119.71283116889991],
[34.44010777023059, -119.71312931319572],
[34.43927803414823, -119.7133845421473],
[34.438067806128565, -119.71286729490258],
[34.436892954998406, -119.71232858998574],
[34.4354280836443, -119.7101031946937],
[34.434264049107526, -119.70901505602397],
[34.434138851173884, -119.70775886019565],
[34.43404019977101, -119.70653485087551],
[34.433322121895344, -119.70543958758809],
[34.432524396300934, -119.70426922244825],
[34.43157491386451, -119.70287799415983],
[34.4305811742986, -119.70142239285506],
    ],
},

Oak_Park: {
    type: "suburban",
    coordinates: [
[34.42507526268263, -119.70723982486014],
[34.419986441855116, -119.71256132754569],
[34.42517261101823, -119.71966381701712],
[34.42738504262581, -119.72728129061942],
[34.42776004397974, -119.72785260113959],
[34.428754508450524, -119.72793038520102],
[34.42954376632518, -119.72777615818266],
[34.42980122760075, -119.72772586676363],
[34.430306468851455, -119.72748245629553],
[34.430816131671264, -119.72728196117167],
[34.431352338955456, -119.72700636419539],
[34.43366360462744, -119.72690578135733],
[34.43633760228845, -119.72625534567122],
[34.43784352028854, -119.72457494172339],
[34.43459052643218, -119.72022708091025],
    ],
},

Samarkand: {
    type: "suburban",
    coordinates: [
[34.42830539692876, -119.73350401553397],
[34.440092115598006, -119.73406191500906],
[34.44018059818974, -119.73047848376517],
[34.44004345013262, -119.72949679526572],
[34.43679164799525, -119.72643371257071],
[34.433061874229566, -119.72725446852927],
[34.43133740594716, -119.72713242801909],
[34.43024507680994, -119.72798335882906],
[34.42918813107849, -119.72816910180335],
[34.428617618186955, -119.72814932051186],
[34.42737453371414, -119.72782913181071],
[34.427968008607166, -119.73075072798018],
    ],
},

Eucalyptus_Hill: {
    type: "mountain/hill",
    coordinates: [
[34.42294753763999, -119.65342998504639],
[34.425502704710944, -119.64897980041142],
[34.42603688309426, -119.65091749648506],
[34.42596927660458, -119.6529410232472],
[34.43948423284092, -119.65234865493413],
[34.43978870185015, -119.65358352689425],
[34.440128562797334, -119.65619168987003],
[34.440213074521616, -119.6565016169879],
[34.44049224725982, -119.656489679024],
[34.44097980460901, -119.6563370570635],
[34.44198145496747, -119.65618201684727],
[34.44326622748369, -119.65585531525409],
[34.44361662645326, -119.65581534607219],
[34.44383430604543, -119.65596849593935],
[34.44393407113413, -119.65648220495405],
[34.44399844471058, -119.65701737164085],
[34.44416258305327, -119.65792677247354],
[34.44424312005961, -119.65855467333635],
[34.44399339528881, -119.6585748558923],
[34.44371712613587, -119.65862722495642],
[34.443153298525026, -119.65876542470902],
[34.44253637909578, -119.6589036244616],
[34.44204332800395, -119.65895599352571],
[34.44155027400233, -119.65907273560619],
[34.440599550117895, -119.65930621976716],
[34.44132997755456, -119.6624364896642],
[34.44157131546917, -119.66356174233428],
[34.44099862520921, -119.6653092674958],
[34.44069824025427, -119.66639032970545],
[34.44052470747905, -119.66671155722916],
[34.44033790198768, -119.66704351358892],
[34.43958381295764, -119.66740165448074],
[34.43531972163339, -119.66939748194116],
[34.43439568651957, -119.67021692978938],
[34.43370171395954, -119.67019952842494],
[34.432968581461886, -119.66939761391782],
[34.431714677065344, -119.6689685924521],
[34.43149035623061, -119.66898877500802],
[34.43134722342878, -119.66947629949064],
[34.43115541974017, -119.66994236630116],
[34.430875789674346, -119.67061700785673],
[34.4305619742811, -119.67122823273309],
[34.42977787038948, -119.67277436684064],
[34.42923980049898, -119.67296941785173],
[34.42894066249135, -119.67319129095294],
[34.42885504679204, -119.67448884255806],
[34.42874488190638, -119.67465795370268],
[34.42829931558078, -119.67390834227135],
[34.42770706257794, -119.67286566651097],
[34.427256399465925, -119.67208048281603],
[34.427166144148885, -119.67078510783851],
[34.42710331717745, -119.67017497127596],
[34.42711028762867, -119.66991179601345],
[34.427078540905605, -119.66970092382674],
[34.42711571211422, -119.6692617450947],
[34.4271009449601, -119.66904365622271],
[34.42704746061523, -119.66892212687524],
[34.42700448519571, -119.6686731925996],
[34.42699690836321, -119.66834915647152],
[34.42714801872883, -119.66788449675387],
[34.4269374531551, -119.66768116003146],
[34.42626670247651, -119.6675743828336],
[34.42610663423392, -119.66664736083992],
[34.42593992836337, -119.66579544069864],
[34.42565375004561, -119.66569453908154],
[34.42546857065175, -119.66506358240495],
[34.42552233596504, -119.66400347228598],
[34.42569336102251, -119.66183829205288],
[34.4257877231989, -119.66087371913298],
[34.42577588798019, -119.6600378922458],
[34.42561526568553, -119.65931605924175],
[34.42559623998123, -119.65975294053213],
[34.424761702520705, -119.66395264229138],
[34.42415460350876, -119.66406938437186],
[34.423352799519, -119.66450799153412],
[34.4225509878396, -119.66494659869637],
[34.42345017707563, -119.65683049817062],
    ],
},

East_San_Roque: {
    type: "suburban",
    coordinates: [
[34.440932696436256, -119.73397608432059],
[34.44926726137152, -119.7332036081243],
[34.44866564334225, -119.73058577212576],
[34.44805074984932, -119.7280698600698],
[34.44920533030392, -119.72739930781607],
[34.44970409527649, -119.72639750274901],
[34.44871651562278, -119.72548152837042],
[34.44838363339939, -119.72439389261488],
[34.448574266695495, -119.72381537365798],
[34.44858795246288, -119.72358017745499],
[34.44792522697024, -119.72201544377093],
[34.44767915734273, -119.72122486266379],
[34.44653672121738, -119.71957396301512],
[34.446629067796344, -119.71695679756884],
[34.446544462850675, -119.71333112153296],
[34.44402922374067, -119.71337973657135],
[34.44243092785824, -119.7135381545413],
[34.44149019871766, -119.71363882119839],
[34.44072199900728, -119.71279535028224],
[34.44026560706033, -119.71581788552065],
[34.440486104355614, -119.71768170646463],
[34.43929016871027, -119.72170975576226],
[34.43941121126883, -119.72206255006675],
[34.439611888522485, -119.72265137876455],
[34.43916663737462, -119.72364790323262],
[34.43888950345629, -119.72392023126666],
[34.43893232827624, -119.72402957319353],
[34.43831152177802, -119.7244071360219],
[34.43883658591234, -119.72485175407563],
[34.43917875202541, -119.72551394538094],
[34.43959170330212, -119.72602593298141],
[34.43992510445084, -119.72651820215468],
[34.44029548716527, -119.7266739379156],
[34.44102864377718, -119.72716226759438],
[34.44101855133872, -119.72839088696126],
[34.440919977184016, -119.72985554072146],
[34.440997122184484, -119.73187289717679],
    ],
},

San_Roque: {
    type: "suburban",
    coordinates: [
[34.44116786448947, -119.74505424499512],
[34.44702396314799, -119.74431272184293],
[34.44779487382076, -119.74434230323361],
[34.44863655627987, -119.74446844414881],
[34.450884934301655, -119.74415470278558],
[34.45147562490864, -119.74407391087543],
[34.451730124828565, -119.74379999991623],
[34.454780230523795, -119.7455752966444],
[34.453619239395344, -119.74348821239111],
[34.45230164069525, -119.74144843098105],
[34.451079127588315, -119.74014893925909],
[34.449254982802, -119.7334421141631],
[34.44613743281493, -119.73373991645526],
[34.44089298571515, -119.73422823267899],
[34.44092867126174, -119.74189965882759],
    ],
},

Hope: {
    type: "suburban",
    coordinates: [
[34.44138906794501, -119.76917266845703],
[34.44180050481467, -119.75986540317535],
[34.44203497868344, -119.75733876228333],
[34.442130648256914, -119.7513446956873],
[34.44182456053669, -119.74530067294836],
[34.446183923654495, -119.74483212456107],
[34.4488235828056, -119.74481242708862],
[34.451905510341135, -119.74425628781319],
[34.452404535656456, -119.74457982927561],
[34.45299202701214, -119.74496774375439],
[34.45351136592904, -119.74508659914136],
[34.45394223369442, -119.74537711590528],
[34.454963203104704, -119.74589377641678],
[34.45538632256625, -119.74667513743043],
[34.4556855874052, -119.74773544818163],
[34.45587869213843, -119.74869919940829],
[34.45575331925224, -119.74936254322529],
[34.45547755353152, -119.75089492276311],
[34.454900999801595, -119.75184794515371],
[34.45348247545402, -119.75304588675499],
[34.45166553477878, -119.75659310817719],
[34.45139348791557, -119.75943893194199],
[34.45128068773678, -119.76228475570679],
[34.450360586471376, -119.76488515734673],
[34.449228140229735, -119.76748555898666],
[34.44917505643542, -119.76886287331581],
[34.4495112532296, -119.77002561092377],
[34.4473635517353, -119.77025829255581],
[34.44656063781939, -119.77027639746666],
[34.44582849746642, -119.77020867168903],
[34.44488400481362, -119.76992636919022],
[34.44409655078567, -119.7693121433258],
[34.44257470848443, -119.76985931396484],
    ],
},

Downtown: {
    type: "business_district",
    coordinates: [
[34.41995358867949, -119.71245032800775],
[34.42705794221397, -119.70484626545053],
[34.42593264710137, -119.70312328143459],
[34.42463033900958, -119.70140029741864],
[34.42216726467153, -119.69795432938676],
[34.417223196940895, -119.69119113935571],
[34.414143101848296, -119.69464582456689],
[34.41259416058779, -119.69879788412194],
[34.4126339908653, -119.70091682924371],
[34.41316948497607, -119.70290702833276],
    ],
},

Hitchcock: {
    type: "business_district",
    coordinates: [
[34.43000260937181, -119.74183124555688],
[34.432958234067996, -119.74624079717736],
[34.43471032151733, -119.74567216886621],
[34.43564829263128, -119.74528593076806],
[34.43677207434817, -119.74461269630532],
[34.4376657797282, -119.74449736131768],
[34.438993047306944, -119.74452418340783],
[34.439373526791364, -119.74449870242219],
[34.439404495975474, -119.74313412858587],
[34.43948855513165, -119.74187684311013],
[34.43950625178533, -119.74013072504144],
[34.439453161813056, -119.73737609638314],
[34.43938237513092, -119.7357842053328],
[34.43954164508149, -119.73427814497094],
[34.43771444657839, -119.73418694986444],
[34.43452449580193, -119.73396700872522],
[34.428533607317675, -119.7337846185122],
[34.42859112896813, -119.73596793665033],
[34.428619889778524, -119.73720979942422],
[34.42889643552705, -119.73851603521447],
    ],
},

Upper_State: {
    type: "business_district",
    coordinates: [
[34.44137910660034, -119.75835988648096],
[34.4413260178181, -119.76228664047876],
[34.44150298029444, -119.76050565369287],
[34.44164455000562, -119.75499103195826],
[34.44179227211053, -119.75470794110893],
[34.441798424743695, -119.75440339258749],
[34.441916906923424, -119.7520991394472],
[34.441936331651284, -119.75046421525437],
[34.441778794857306, -119.74955885191359],
[34.44158759394684, -119.74654649559338],
[34.44145463054343, -119.74592483475507],
[34.44096774083289, -119.7440157135896],
[34.44057793695964, -119.74131327020885],
[34.44055090888153, -119.7377847064515],
[34.44076744815682, -119.73222241660774],
[34.440843180554715, -119.72849623419044],
[34.440446248039656, -119.72754859533202],
[34.440176995066025, -119.7270747759028],
[34.438520452103376, -119.72497104871384],
[34.43800012947392, -119.72438074390024],
[34.43762138013237, -119.72527101846288],
[34.436810784647776, -119.72610743001488],
[34.43782646582162, -119.72702118796768],
[34.43900140563025, -119.72823535333013],
[34.4395215554862, -119.72870922900447],
[34.440130184749286, -119.72892561261338],
[34.44035262768166, -119.7309927766877],
[34.440256342626995, -119.73426924791266],
[34.43969214415014, -119.73438488872688],
[34.43962039078635, -119.7446011872681],
[34.43883719048266, -119.74473560354541],
[34.43820667940424, -119.74469552332347],
[34.437293010119156, -119.74478418913424],
[34.43663151718845, -119.74481921076472],
[34.43598771642015, -119.74539067419816],
[34.43501650568398, -119.74573683207437],
[34.43423995932556, -119.7459971592621],
[34.43317784481297, -119.74645911086579],
[34.43641627286927, -119.75080490112305],
[34.43873209741023, -119.75317644508522],
[34.43963338494576, -119.75440513241054],
[34.44039309116752, -119.75533341232617],
    ],
},
})
