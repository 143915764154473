import React, { useState } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles, Typography, Container } from '@material-ui/core';
import { NavLink } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
    link: {
        marginRight: 25,
        fontWeight: 600,
        color: "black",
        transition: "all .5s ease",
        "&:hover": {
        color: "#a99136",
          cursor: "pointer",
        },
    },
    menuHolder: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
    menuDropDown: {
      backgroundColor: "white",
      color: "#ee844d",
    },
    menuEntries: {
      transition: "all .5s ease",
      color: "#a99136",
      "&:hover": {
        color: "#ead983",
    },
  },
}));
const MenuEntry = (props) => {
const classes = useStyles();
const [open, setOpen] = React.useState(false);
const anchorRef = React.useRef(null);

const handleToggle = (event, props) => {
//setMenuIndex(event.target.id);
setOpen((prevOpen) => !prevOpen);
}

const handleClose = (event, props) => {
if (anchorRef !== anchorRef.current) {
  setOpen(false)
;}
if (anchorRef.current && anchorRef.current.contains(event.target)) {
return; }
setOpen(false);
};
function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
const prevOpen = React.useRef(open);
React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
       anchorRef.current.focus();
     }
     prevOpen.current = open;
     console.log(anchorRef.current);
   }, [open]);

// const menuLists = {
//     SERVICES: "SERVICES",
//     PROPERTIES: "SERVICES",
//     RESEARCH,
//     POSTS,
//     ABOUT:,
//     };
    
    let link;
    if (props.name.toLowerCase() === "properties") {
      link = "properties/sale/Santa_Barbara/CA/any?"
    }
    if (props.name.toLowerCase() !== "properties") {
      link = props.name.toLowerCase()
    }
    return (
      <NavLink key="01" to={link} style={{ textDecoration: 'none' }}><Typography variant="link" ref={anchorRef} 
      aria-haspopup="true"
      className={classes.link}>{props.name}</Typography></NavLink>
  
      // <Popper key="02" onMouseEnter={handleToggle} onMouseLeave={handleClose} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
      //         {({ TransitionProps, placement }) => (
      //           <Grow
      //           {...{ timeout: 1000 } }
      //             {...TransitionProps}
                  
      //           >
      //             <Container className={classes.menuDropDown}>
      //               <ClickAwayListener>
      //                 <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
      //                   {menuLists[props.name].map((m) => (
      //                   <NavLink style={{ textDecoration: "none" }} to={props.name}><MenuItem className={classes.menuEntries}>{m}</MenuItem></NavLink>
      //                   ))}
      //                 </MenuList>
      //               </ClickAwayListener>
      //               </Container>
      //           </Grow>
      //         )}
      //       </Popper>]
    )
    }
    export default MenuEntry;