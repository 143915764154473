import SETTINGS from "../settings";
import axios from "axios";

const getListings = async (listingId) => {
    let baseUrl = SETTINGS.DOMAINREQUIREMENTS.BACKEND.baseUrl;
    const listings = await axios.get(`${baseUrl}/listings/${listingId}`);
    return listings;
}

const getAll = async () => {
    let baseUrl = SETTINGS.DOMAINREQUIREMENTS.BACKEND.baseUrl;
    const listings = await axios.get(`${baseUrl}/listings`);
    return listings;
}

export { getListings, getAll };