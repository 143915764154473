import React, { useState, useEffect } from "react"
import { Paper, Typography, makeStyles, Button } from "@material-ui/core";
import Register from "../webRequests/register";

const useStyles = makeStyles((theme) => ({
    popup: {
        width: "25%",
        height: "80%",
        border: "2px solid gray",
        display: "flex",
        backgroundColor: "white",
        zIndex: 50, 
        fontFamily: "sweet-sans-pro",
        flexDirection: "column"
    },
    modal: {
        width: "100%",
        height: "100%",
        position: "absolute",
        zIndex: 25,
        backgroundColor: "rgba(255, 255, 255, 0.6)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",  
    },
    heading: {
        fontSize: 24,
        fontWeight: 800,
        fontFamily: "sweet-sans-pro"
    }
}));

const register = async (username, password, email) => {
    Register(username, password, email)
    console.log(username, password, email)
}

const Login = (props) => {  
    const [username, SetUsername] = useState(null);
    const [password, SetPassword] = useState(null);
    const [email, SetEmail] = useState(null);
    const [loggingIn, SetLoggingIn] = useState(false);

useEffect(() => { 
    if (loggingIn) {
        (async () => {
            await Register(username, password, email);
            SetLoggingIn(false);
        })();
    }
    },[loggingIn]);
const classes = useStyles();
return (
    <div className={classes.modal}>
        <Paper className={classes.popup}>
            <Typography className={classes.heading}>Welcome to Townz.io</Typography>
            <form style={{display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex", justifyContent: "center"}}>
            <label>Username</label>
            <input type="text" onChange={(event) => SetUsername(event.target.value)}></input>
            </div>
            <div style={{display: "flex", justifyContent: "center"}}>
            <label>Password</label>
            <input type="text" onChange={(event) => SetPassword(event.target.value)}></input>
            </div>
            <div style={{display: "flex", justifyContent: "center"}}>
            <label>E-mail Address</label>
            <input type="text" onChange={(event) => SetEmail(event.target.value)}></input>
            </div>
            <Button type="submit" onClick={() => SetLoggingIn(true)}>Submit</Button>
            </form>
        </Paper></div>
)
}
export default Login