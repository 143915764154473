import React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography, Paper, Container, Box, makeStyles } from "@material-ui/core"
import CompEntry from "./compEntry";
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import { TramOutlined, TramRounded } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
mainBox: {
    position: "absolute",
    left: "0%",
    height: "100%",
    maxHeight: "100%",
    width: "35%",
    backgroundColor: "rgba(255, 255, 255, .85)",
    display: "flex",
    top: "0%",
    marginLeft: 0,
    marginRight: 0,
    zIndex: 10,
    flexDirection: "column",
    paddingLeft: "2%",
    paddingRight: "2%"
}

}))

const CompsShow = (props) => {
    const classes = useStyles()
    
return (
    
<Paper className={classes.mainBox}>
    <div style={{display: "flex", position: "relative", justifyContent: "center", width: "100%", marginTop: 5, marginBottom: 5}}>
    <Typography style={{fontWeight: 800}}>Property Comparison</Typography><CancelPresentationIcon onClick={() => props.closeDialog()} style={{color: "gray", position: "absolute", right: 0, transform: "scale(1.5)", cursor: "pointer"}}/></div>
    <CompEntry imageURL={props.subject.Photos.Uri1280[0]}
    subject={true}
    address={props.subject["Unparsed First Line Address"]}
    price={props.subject["Current Price"]} />
    <Typography>Comparable Sales</Typography>
    <div style={{height: "80%", width: "100%", display: "flex",
    flexDirection: "column", overflowY: "scroll"}}>
        
    {props.comps.length > 0 ? 
    props.comps.map((com) => {
        console.log(props.comps)
        return (
<CompEntry imageURL={com.Photos.Uri1280[0]}
 address={com["Unparsed First Line Address"]}
 subject={false}
 price={com["Sold Price"]}
 distance={com["Distance From Subject"]}
 date={com["Sold Date"]}/>
        )
        
    }) : <div style={{height: "100%", width: "100%", display: "flex",
    flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
    <CircularProgress style={{color: "#a99136"}} /></div>}</div>
</Paper>
)
}

export default CompsShow;