import React, { useEffect, useState } from "react";
import { getListings, getAll } from "../webRequests/listings";
import { Typography, Button, makeStyles, Paper, Chip, CircularProgress, Box } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
const useStyles = makeStyles((theme) => ({

mainContainer: {
    width: "100%",
    height: "100%",
    backgroundColor: "#f7f7f7",
    paddingTop: "12vh",
    paddingBottom: "10vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: 0
},
categoryText: {
    color: "#a99136",
    fontSize: 24,
    fontFamily: "sweet-sans-pro",
},
button: {
    backgroundColor: "#a99136",
    "&:hover": {
    backgroundColor: "#ead983",
    },
    color: "white",
    borderRadius: 0,
    width: '350px',
    position: 'absolute',
    bottom: 5,
    left: 10,
    
    bottom: 10,
    fontWeight: 800,

},
headlineText: {
    fontSize: "2em",
    color: "black",
    fontWeight: 800,
    fontFamily: "sweet-sans-pro",
    marginBottom: 20,
    marginTop: 10,
    [theme.breakpoints.down("sm")]: {
        fontSize: 16
    }
},
mainText: {
    fontFamily: "sweet-sans-pro",
    color: "black",
    marginBottom: 10, 
    [theme.breakpoints.down("sm")]: {
        fontSize: 12
    }
},
box: {
    height: "150px",
    width: "225px",
    marginTop: 10,
},
boxImage: {
    height: "100%",
    width: "100%"
},
posts: {
    maxWidth: "90%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: 0,
    margin: 0
},
date: {
    fontFamily: "sweet-sans-pro",
    fontWeight: 600,
    marginBottom: 40
},
allPostHolder: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: "#f7f7f7"
},

flexWide: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    marginTop: 10, 
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "center"
    }
},
allPostBox: {
    width: "350px",
    height: "500px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    padding: 10,
    marginRight: 20,
    position: 'relative',
    cursor: "pointer",
    transitionDuration: ".5s",
    boxShadow: "0px 2px 18px -3px #DBDBDB",
    "&:hover": {
        boxShadow: "10px 10px 18px -3px #DBDBDB"
    },
    [theme.breakpoints.down("sm")]: {
        marginBottom: 10
    }
},

allPostImageBox: {
    width: "350px",
    height: "250px",
    position: 'relative',
    margin: 0,
    padding: 0
},

allPostContentBox: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    padding: 5,
    boxSizing: "border-box",
    overFlow: "hidden",
    color: "black",
    margin: 0,
    padding: 0
},
overflowBox: {
    overflow: "hidden",
    width: "100%",
    marginBottom: 20,
    whiteSpace: "no-wrap",
    textOverflow: "ellipsis"
},
smallBoxText: {
    fontFamily: "sweet-sans-pro",
    fontSize: "12",
    marginTop: 10,
},
back: {
    width: "84%",
    display: "flex",
    padding: 0,
    justifyContent: "flex-start",
    alignItems: "flex-start"
},
smallBoxHeadline: {
    fontFamily: "sweet-sans-pro",
    fontSize: "24",
    fontWeight: 800,
},
postHeading: {
    fontFamily: "sweet-sans-pro",
    fontSize: "36",
    fontWeight: 800,
    color: "#a99136"
},
postPaper: {
    backgroundColor: "white",
    width: "70%",
    maxWidth: "70%",
    borderRadius: 2,
    boxShadow: "0px 2px 18px -3px #DBDBDB",
    paddingTop: "8%",
    paddingLeft: "7%",
    overflow: "auto",
    paddingRight: "7%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    textAlign: "left",
    minHeight: "100vh", 
    [theme.breakpoints.down("sm")]: {
    width: "98%",
    minHeight: "50vh",
    }
}
}))
const Listings = (props) => {
    console.log(props.pageType)
const classes = useStyles();
const { id } = useParams();
const history = useHistory();
const [listing, SetListing] = useState(null);
const [loading, SetLoading] = useState(true);
const [allListingsLoading, SetAllListingsLoading] = useState(true)
const [allListings, SetAllListings] = useState(null);

const fetchData = async (id) => {
    const fetchedListing = await getListings(id); 

    if (fetchedListing) {
        SetListing(fetchedListing.data);
    }
    SetLoading(false);
    }

const fetchAllListings = async () => {
    const ListingsArr = await getAll();

    if (ListingsArr) {
        SetAllListings(ListingsArr.data)
    }
    SetAllListingsLoading(false)
}


useEffect(() => {
    if (props.pageType === "singleListing") {
    fetchData(id);
    }
if (props.pageType === "allListings") {
    SetLoading(false)
}
    }, [id]);

useEffect(() => {
    fetchAllListings()
},[])

return (
    <div>
    {loading || allListingsLoading ? <div className={classes.mainContainer}><CircularProgress /></div>:
    <div className={classes.mainContainer}>
        <div className={classes.back}>
            <ArrowLeftIcon />
        <Typography onClick={() => history.push("/")} style={{cursor: "pointer"}} className={classes.categoryText}>Back Home</Typography></div>

<div className={classes.allPostHolder}>
      <div className={classes.flexWide}>
                <Typography className={classes.categoryText}>Featured Listings</Typography></div>
                <div className={classes.flexWide}>
{
    allListings ? 
    allListings.map((p) => {
        return (
            <div className={classes.allPostBox} onClick={() => history.push(`/listing?id=${p.id}`)}>
                <div className={classes.allPostImageBox}>
                    <img style={{height: "100%", width: "100%"}} src={p.photos[0]}></img>
                    <Chip label="FOR SALE" color="#a99136" style={{position: 'absolute',color: 'white', backgroundColor: "#a99136", right: 5, top: 5}}>For Sale</Chip>
                </div>
                
                <div className={classes.allPostContentBox}>
                    <Typography className={classes.categoryText}>{p.address}</Typography>
                    <Typography className={classes.smallBoxHeadline}>{p.city}, {p.state} {p.zip_code}</Typography>
                    <Typography className={classes.smallBoxText}>{p.price}</Typography>
                    <Box className={classes.overflowBox}>
                    <Typography className={classes.smallBoxText}>36 Unit Multi-Family Property</Typography>
                    <Typography style={{fontSize: 12}} className={classes.smallBoxText}>Student Housing</Typography>
                    </Box>
                    <Typography style={{fontWeight: 800}} className={classes.categoryText}>${parseInt(p.list_price, 10).toLocaleString()}</Typography>
                    </div>
                    <Button style={{position: 'absolute', bottom: 5}} className={classes.button}>VIEW</Button>
                    </div>
        )
    }) : null}
</div>
</div>
    </div>
}
</div>
)

}
export default Listings;