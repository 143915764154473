import React, { useState } from 'react';
import { makeStyles, Toolbar, Box, Typography, Container, fade, Paper, Button } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
const useStyles = makeStyles((theme) => ({
    toolbar: {
        maxHeight: 30,
        marginLeft: 0,
        width: "55%",
        display: "flex",
        
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
         display: "none",

          },
      },
      button2: {
        width: "auto",  
        height: 30,
        color: "black", 
        color: "#a99136",
        marginTop: 5,
        [theme.breakpoints.down("sm")]: {
          fontSize: 10,
        },
    fontFamily: "sweet-sans-pro",
       borderRadius: 2,

        display: "flex",
        justifyContent: "space-between",
        marginBottom: 5, 
        marginLeft: 0,
        marginRight: 5,
        border: "1px solid gray",
        
        padding: 5,
        paddingLeft: 30,
        paddingRight: 30,
        cursor: "pointer",
     
      },
      text: {
          marginRight: 20,
      
          fontFamily: "sweet-sans-pro",
          "&:hover": {
            fontWeight: 600,
            cursor: "pointer"
        },
      }
}));

const LoginNav = () => {
const classes = useStyles();
    return (
        <Toolbar className={classes.toolbar}>
        <div style={{display: "flex", justifyContent: "center", right: 70, fontWeight: 600, fontSize: 14, fontFamily: "sweet-sans-pro"}}>

            

        </div>
        <Button className={classes.button2}>LOG IN</Button>
        </Toolbar>  
    );
}

export default LoginNav;