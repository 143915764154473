import React, { useState } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles, Typography, Container } from '@material-ui/core';
import { NavLink } from "react-router-dom";
import DehazeIcon from '@material-ui/icons/Dehaze';
import ClearIcon from "@material-ui/icons/Clear";
import ToggleIconComponent from "../components/ToggleIconComponent";
const useStyles = makeStyles((theme) => ({
    link: {
        marginRight: 25,
        fontWeight: 600,
        color: "black",
        transition: "all .5s ease",
        "&:hover": {
        color: "#a99136",
          cursor: "pointer",
        },
    },
    main: {
        [theme.breakpoints.down("sm")]: {
          width: "100",
          marginRight: "10%",
          padding: 0,
          display: "flex",
          justifyContent: "flex-end",
          },
          [theme.breakpoints.up("md")]: {
            display: "none",
            },
      [theme.breakpoints.up("lg")]: {
        display: "none",
        },

    },
    menuHolder: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
    menuDropDown: {
      backgroundColor: "white",
      color: "#ee844d",
      width: "100%",
      marginTop: 100,
      padding: 0,
      marginRight: 0,
      marginLeft: 0,

    },
    popper: {
      width: "100%", 
      padding: 0,
       margin: 0,
       [theme.breakpoints.up("lg")]: {
        display: "none",
        },
    },
    menuEntries: {
      transition: "all .5s ease",
      textAlign: "center",
      width: "100%",
      margin: 0,
      color: "#a99136",
      display: "flex",
      justifyContent: "center",
      "&:hover": {
        color: "#ead983",
    },
    iconMenuMobile: {
      [theme.breakpoints.up("lg")]: {
        display: "none",
        },
      width: "100",
    },
  },
}));
const MobileMenuEntry = (props) => {
const scrollbarWidth = document.body.offsetWidth - document.body.clientWidth;
const classes = useStyles();


const menuLists = {
    Services: ["Brokerage", "Valuation", "Analytics"],
    Listings: ["Listings", "Sold", "Market Rents"],
    Research: ["Market Reports", "Rent Analytics", "Sale Analytics"],
    "Property Values": ["Values by Neighborhood", "Investment Performance Tracker", "Expense Benchmarks"],
    About: ["Focus", "Experience", "References"],
    };
    return (
     [<div className={classes.main} style={{position: "relative", right: scrollbarWidth}} key="01"><ToggleIconComponent click={props.tog} iconState={props.opn}/></div>,
      <Popper key="02" onClick={props.tog} open={props.opn} className={classes.popper} anchorEl={props.ankr.current} role={undefined} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow style={{ width: "100%", padding: 0, margin: 0 }}
                {...{ timeout: 500 } }
                  {...TransitionProps}
                  
                >
                  <Container className={classes.menuDropDown}>
                    <ClickAwayListener>
                      <MenuList style={{width: "100%", margin: 0 }} autoFocusItem={props.opn} onKeyDown={props.keydown}>
                      {Object.keys(menuLists).map((m) => (
                        <NavLink style={{ width: "100%", textDecoration: "none", textAlign: "center" }} to={m}><MenuItem style={{ textAlign: "center" }} className={classes.menuEntries}>{m}</MenuItem></NavLink>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                    </Container>
                </Grow>
              )}
            </Popper>]
    )
    }
    export default MobileMenuEntry;