import React, { useEffect, useState } from "react";
import { getListings, getAll } from "../webRequests/listings";
import { Typography, Button, Chip, makeStyles, Paper,  CircularProgress, Box } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import { useParams, useHistory, useLocation, Link } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Sharif from '../assets/images/sharif.png';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
const useStyles = makeStyles((theme) => ({

mainContainer: {
    width: "100%",
    height: "100%",
    backgroundColor: "#f7f7f7",
    paddingTop: "12vh",
    paddingBottom: "10vh",
    display: "flex",
    position: 'relative',
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: 0
},
categoryText: {
    color: "#a99136",
    fontSize: 24,
    fontFamily: "sweet-sans-pro",
},
button: {
    backgroundColor: "#a99136",
    "&:hover": {
    backgroundColor: "#ead983",
    },
    color: "white",
    borderRadius: 0,
    width: '350px',
    fontWeight: 800,
    [theme.breakpoints.down("sm")]: {
        marginLeft: 0,

    }

},
headlineText: {
    fontSize: "2em",
    color: "black",
    fontWeight: 800,
    fontFamily: "sweet-sans-pro",
    marginBottom: 20,
    marginTop: 10,
    [theme.breakpoints.down("sm")]: {
        fontSize: 16
    }
},
mainText: {
    fontFamily: "sweet-sans-pro",
    color: "black",
    marginBottom: 10, 
    [theme.breakpoints.down("sm")]: {
        fontSize: 12
    }
},
brokerBox: {
    width: '350px',
    height: '150px',
    boxShadow: "0px 2px 18px -3px #DBDBDB",
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
},
boxImage: {
    height: "100%",
    width: "100%"
},
posts: {
    maxWidth: "90%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: 0,
    margin: 0
},
headerImgBox: {
    width: '100%',
    height: '100%',
    marginTop: 10,
    marginBottom: 10,
    padding: 0,
    position: 'relative'
},
headerImg: {
    width: '80vw',
    height: '100vh',
    [theme.breakpoints.down("sm")]: {
       maxHeight: '400px',
        }

},
sliderBox: {
    width: '80%',
    height: '20vh',
    minHeight: '200px',
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.down("sm")]: {
        overflowX: 'scroll',
        overflowY: 'hidden',
        }
},
Box: {
    width: '80%',
    display: 'flex',
    marginTop: 10,
    padding: 0, 
    marginLeft: 0,
    overflow: 'ellipsis',
    textAlign: 'left',
    justifyContent: 'flex-start',
},
buttonBox2: {
    width: '80%',
    display: 'flex',
    marginTop: 10,
    padding: 0, 
    marginLeft: 0,
    textAlign: 'left',
    justifyContent: 'flex-start',
    justifyContent: 'flex-start',
    position: 'absolute', 
    bottom: 50, 
    left: 0, 
    right: 0, 
    width: '350px', 
    margin: '0 auto',
    [theme.breakpoints.down("sm")]: {
        position: 'relative',
        margin: 0
        }
},
date: {
    fontFamily: "sweet-sans-pro",
    fontWeight: 600,
    marginBottom: 40
},
allPostHolder: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    position: 'relative',
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: "#f7f7f7"
},

flexWide: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    marginTop: 10, 
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "center"
    }
},
allPostBox: {
    width: "350px",
    height: "500px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    padding: 10,
    marginRight: 20,
    position: 'relative',
    cursor: "pointer",
    transitionDuration: ".5s",
    boxShadow: "0px 2px 18px -3px #DBDBDB",
    "&:hover": {
        boxShadow: "10px 10px 18px -3px #DBDBDB"
    },
    [theme.breakpoints.down("sm")]: {
        marginBottom: 10
    }
},
sliderImg: {
    height: '20vh',
    minHeight: '200px',
    minWidth: '200px',
    width: '20vw',
    boxSizing: 'border-box',
    position: 'relative',
    boxShadow: "0px 2px 18px -3px #DBDBDB",
    padding: 5,
},
allPostImageBox: {
    width: "350px",
    height: "250px",
    position: 'relative',
    margin: 0,
    padding: 0
},

allPostContentBox: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    padding: 5,
    boxSizing: "border-box",
    overFlow: "hidden",
    color: "black",
    margin: 0,
    padding: 0
},
overflowBox: {
    overflow: "hidden",
    width: "100%",
    marginBottom: 20,
    whiteSpace: "no-wrap",
    textOverflow: "ellipsis"
},
smallBoxText: {
    fontFamily: "sweet-sans-pro",
    fontSize: "12",
    marginTop: 10,
},
back: {
    width: "84%",
    display: "flex",
    padding: 0,
    justifyContent: "flex-start",
    alignItems: "flex-start"
},
smallBoxHeadline: {
    fontFamily: "sweet-sans-pro",
    fontSize: "24",
    fontWeight: 800,
},
buttonLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 2,
    height: 20,
    boxSizing: 'border-box',
    width: 50,
    margin: 0, 
    backgroundColor: 'white'
},
postHeading: {
    fontFamily: "sweet-sans-pro",
    fontSize: "36",
    fontWeight: 800,
    color: "#a99136"
},
columnBox: {
    width: '50%',
    height: '100%',
    display: 'flex',
    padding: 10,
    margin: 0,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
},

postPaper: {
    backgroundColor: "white",
    width: "70%",
    maxWidth: "70%",
    borderRadius: 2,
    boxShadow: "0px 2px 18px -3px #DBDBDB",
    paddingTop: "8%",
    paddingLeft: "7%",
    overflow: "auto",
    paddingRight: "7%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    textAlign: "left",
    minHeight: "100vh", 
    [theme.breakpoints.down("sm")]: {
    width: "98%",
    minHeight: "50vh",
    }
},
bottomBox: {
    width: '80%',
    display: 'flex',
    marginTop: 10,
    padding: 0, 
    marginLeft: 0,
    overflow: 'ellipsis',
    textAlign: 'left',
    justifyContent: 'flex-start', 
    bottom: 50, 

    margin: '0 auto',
    [theme.breakpoints.down("sm")]: {
        position: 'relative', 
        top: 0,
        }
}
}))
const Listing = (props) => {
const classes = useStyles();
const { search } = useLocation();
const searchParams = new URLSearchParams(search);
const id = searchParams.get("id");
const [idx, setIDX] = useState(0);
const history = useHistory();
const [listing, SetListing] = useState(null);
const [loading, SetLoading] = useState(true);

const fetchData = async (id) => {
    console.log(id)
    const fetchedListing = await getListings(id); 

    if (fetchedListing) {
        SetListing(fetchedListing.data);
        SetLoading(false);
    }
    
    }


useEffect(() => {
    fetchData(id);
    }, [id]);

return (
    <div>
    {loading ? <div className={classes.mainContainer}><CircularProgress /></div>:
    <div className={classes.mainContainer}>
        <div className={classes.back}>
            <ArrowLeftIcon />
        <Typography onClick={() => history.push("/")} style={{cursor: "pointer"}} className={classes.categoryText}>Back Home</Typography></div>

<div className={classes.allPostHolder}>
      <div className={classes.flexWide}>
                <Typography className={classes.categoryText}>{listing.address}</Typography></div>
             
</div>

<div className={classes.headerImgBox}><img className={classes.headerImg} src={listing.photos[idx]} alt='img' /><Chip label="FOR SALE" color="#a99136" style={{position: 'absolute', color: 'white', backgroundColor: "#a99136", right: '10%', top: 5}}>For Sale</Chip></div>
<div style={{width: '80%', display: 'flex', marginBottom: 10, marginTop: 5, justifyContent: 'space-between', alignItems: 'flex-end'}}>
    <Typography style={{fontFamily: 'sweet-sans-pro', fontWeight: 600, color: "#a99136"}}>Photos ({listing.photos.length})</Typography>
    <a href={listing.flyer_mq}>Download Brochure</a></div>
<div className={classes.sliderBox}>
    {listing.photos.map((p, index) => {
        const selected = index === idx;
        return (
            <div onClick={()=> setIDX(index)} style={{border: selected ? '5px solid #a99136' : 'none'}} className={classes.sliderImg}><img style={{width: '100%', cursor: 'pointer', height: '100%'}} src={p} alt='img' /></div>
        )
    })}
</div>
<div className={classes.Box}><Typography style={{fontFamily: 'sweet-sans-pro', fontWeight: 600, color: "#a99136"}}>Description</Typography></div>
<div style={{justifyContent: 'flex-start',  marginLeft: 0, padding: 0}} className={classes.Box}><Typography style={{marginTop: 0 }} className={classes.smallBoxText}>{listing.description.replace(/\s+/g, ' ').trim()}</Typography></div>
<div className={classes.Box}><Typography style={{fontFamily: 'sweet-sans-pro', fontWeight: 600, color: "#a99136"}}>Property Characteristics</Typography></div>
<div style={{height: '300px', boxSizing: 'border-box'}} className={classes.Box}>
        <div style={{height: '300px', boxSizing: 'border-box'}} className={classes.brokerBox}>
            <div style={{height: '300px', boxSizing: 'border-box'}} className={classes.columnBox}>
                <Typography className={classes.smallBoxHeadline}>Property Type</Typography>
                <Typography className={classes.smallBoxHeadline}>Listing Price</Typography>
                <Typography className={classes.smallBoxHeadline}>Units</Typography>
                <Typography className={classes.smallBoxHeadline}>City</Typography>
                </div>
                <div style={{height: '300px', boxSizing: 'border-box'}} className={classes.columnBox}>
                <Typography className={classes.smallBoxText}>Multi-Family</Typography>
                <Typography className={classes.smallBoxText}>$19,750,000</Typography>
                <Typography className={classes.smallBoxText}>36 Units</Typography>
                <Typography className={classes.smallBoxText}>Isla Vista, CA</Typography>
                </div>
            </div></div>
<div className={classes.Box}><Typography style={{fontFamily: 'sweet-sans-pro', fontWeight: 600, color: "#a99136"}}>Listing Broker</Typography></div>
<div className={classes.Box}><div style={{width: '350px', maxWidth: '350px'}} className={classes.brokerBox}>
        <img src={Sharif} style={{width:'150px', height: '150px', boxSizing: 'border-box'}} alt='img' />
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', boxSizing: 'border-box', position: 'relative', alignItems: 'center', padding: 0, margin: 0, height: '150px', width: '200px'}}>
            <div style={{position: 'absolute', left: '0', display: 'flex', width: '200px', boxSizing: 'border-box', justifyContent: 'center', alignItems: 'center', top: 0 }}><Typography className={classes.smallBoxHeadline}>Sharif Elseify</Typography></div>
            <Typography className={classes.smallBoxText}>805.858.8872</Typography>
            <Typography style={{margin: 0}} className={classes.smallBoxText}>sharif@sbapt.com</Typography>
            <Typography style={{margin: 0}} className={classes.smallBoxText}>DRE # 01984886</Typography>
            </div>
        </div></div>

    <div style={{marginTop: 50}} className={classes.Box}><Button component="a" href={listing.flyer_mq} className={classes.button}>Download Brochure</Button></div>
    

    </div>
}
</div>
)

}
export default Listing;