import React, { useState, useEffect } from "react";
import {
makeStyles, Box, Container, Slide, Typography, Paper, Button, Chip, MenuList, MenuItem
} from "@material-ui/core";
import Cities from "../util/latLong";
import { Header } from "../components/Header";
import Headerslideshow from "../components/Headerslideshow";
import Footer from "../components/Footer";
import FeaturedCity from "../components/FeaturedCity";
import { NavLink, useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  
infoPanel: {
  color: "black",
  backgroundColor: "white",
  marginTop: 0,
  height: "15vh",
  width: "100%"
},
top: {
  backgroundColor: "black",
  marginBottom: 20,
},
logo: {
  [theme.breakpoints.down("xs")]: {
    width: "100%",

  },
  width: "100%",
  marginTop: 0,
  position: "relative",
  backgroundColor: "rgba(255, 255, 255, .95)",
  color: "white",
  left: 0,
},
        link: {
            marginRight: 25,
            fontWeight: 500,
            color: "black",
            "&:hover": {
              textDecoration: "underline",
              cursor: "pointer",
            },
          },
          infoBox: {
            zIndex: 2,
            marginTop: 100,
            height: "65vh",
            backgroundColor: "rgba(255, 255, 255, 1.0)",
            color: "white",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            left: "0",
          
          },
  map: {
    display: "flex",
    justifyContent: "flex-start",
    padding: 0,
    //"-webkit-mask-image": "linear-gradient(0deg, transparent 50%, black 100%)",
   //"mask-image": "linear-gradient(0deg, transparent 5%, black 50%)",
  },
  slideshow: {
  marginTop: 20,
  maxWidth: "100%",
  position: "relative",
  padding: 0,
  margin: 0,
  },

  Typography: {
    fontFamily: "sweet-sans-pro",
  },
  slideshow2: {
    marginTop: 50,
    marginBottom: 50,
    },
    button: {
      backgroundColor: "#a99136",
      "&:hover": {
      backgroundColor: "#ead983",
      },
      color: "white",
      borderRadius: 0,
      fontWeight: 800,
    
      
  },
  button2: {
    backgroundColor: "#a99136",
    "&:hover": {
    backgroundColor: "#ead983",
    },
    color: "white",
    borderRadius: 0,
    fontWeight: 800,
    marginBottom: 50,
    marginTop: 20,
},
  leftText: {
    marginTop: 0,
    maxWidth: "100%",
  },
  santabarb: {
    top: 0,
    position: "absolute",
    borderBottom: "1px solid black",
    height: "80vh",
    width: "100%",
    overflow: "hidden",
    zIndex: -1,
    opacity: 1.0,

    [theme.breakpoints.down("md")]: {
      height: "120vh"
    },
    [theme.breakpoints.down("sm")]: {
      height: "80vh"
    },
   // "-webkit-mask-image": "linear-gradient(0deg, transparent 5%, black 50%)",
   // "mask-image": "linear-gradient(0deg, transparent 5%, black 50%)",
  },
  container: {
    position: "relative",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "100%",
    bottomBorder: "5px solid black",
    display: "flex",

    minHeight: "80vh",
    textAlign: "center",

    [theme.breakpoints.down("md")]: {
      height: "120vh"
    },
    [theme.breakpoints.down("sm")]: {
      height: "80vh"
    },
  },
  widget: {
    width: "25%",
    height: "auto",
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 2,
    backgroundColor: "#dbdbdb",
    color: "black",
    marginLeft: 12.5,
    marginRight: 12.5,
    //backgroundColor: "rgba(146, 129, 122, .15)",
    [theme.breakpoints.down('sm')]: {
      width: "80%",
      marginLeft: "10%",
      marginRight: "10%",
      marginBottom: 20,
    },
   
 
  },
  widgetHolder: {
    marginLeft: 0,
    marginRight: 0,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#dbdbdb",
    maxWidth: "100%",
    marginTop: 210,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      marginTop: 0
    },
  },
  widgetHolder2: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  widgetText: {
    display: "flex",
    justifyContent: "center",
    marginLeft: 0,
    width: "100%",
  },
  infoBox2: {
    height: "100%",
    backgroundColor: "rgba(146, 129, 122, .15)",
    color: "black",
    width: "25%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: 50,
    [theme.breakpoints.down('sm')]: {
      width: "80%",
    },
  },
  slideshowImage: {
    display: "flex",
    objectFit: "cover",
    objectPosition: "top",
    width: "100%",
    height: "80vh",
    [theme.breakpoints.down("md")]: {
      height: "120vh"
    },
    [theme.breakpoints.down("sm")]: {
      height: "80vh"
    },
  },
  flexText: {
    display: "flex",
    justifyContent: "center",

  },
  chip: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down('sm')]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "auto",
    },
  },
  normalChip: {
    width: 200,
   
  },
  middleChip: {
    marginLeft: 20,
    marginRight: 20,
    width: 200,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 0,
      marginTop: 10,
      marginBottom: 10,
   
    },
  },
  aboutHolder: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  featured: {
    display: "flex",
    width: "100vw",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  text: {
    marginTop: 10,
    marginBottom: 10,
},
textDiv: {
    textAlign: "center",
    [theme.breakpoints.down('sm')]: {
      width: "80%",
      marginLeft: "10%",
      marginRight: "10%",
    },

},
contentWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginBottom: 25,
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      flexDirection: "column",
    },
},
aboutHolder2: {
    minWidth: "25%",
    maxWidth: "25%",
    [theme.breakpoints.down('sm')]: {
      maxWidth: "100%",
      width: "100%",
    },
},
flex: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: "50%",
    [theme.breakpoints.down('sm')]: {
      maxWidth: "100%",
      width: "100%",
    },
    
},
infoBox3: {
    height: "100%",
    marginTop: 10,
    backgroundColor: "rgba(146, 129, 122, .15)",
    color: "black",
    display: "flex",
    marginRight: 50,
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: 50,
    [theme.breakpoints.down('sm')]: {
      width: "80%",
      marginLeft: "10%",
      marginRight: "10%",
    },
  },
        }));

const Home = () => {
    const classes = useStyles();
    const history = useHistory();
    return (
      
     [
     
    <Container className={classes.widgetHolder}>
       <div style={{width: "100%", display: "flex", justifyContent: "center", padding: 0}}><NavLink to="/services" style={{ textDecoration: "none"}}><Button key="05" className={classes.button} style={{ fontFamily: "sweet-sans-pro" }}>Services</Button></NavLink></div>
       <div className={classes.widgetHolder2}>
      <div className={classes.widget}>
        
        <Box
        href="/"
        style={{ lineHeight: 0 }}
    >
    <img
    alt="logo"
    width="100%"
    src={require("../assets/images/sales3.svg")}
    />
    
    </Box>
    <Typography variant="h4" style= {{ marginBottom: 50, fontWeight: 800, fontFamily: "sweet-sans-pro"  }}>Apartment Sales</Typography>
        <MenuList className={classes.widgetText}>
          <ul>
         
        </ul>
        </MenuList>
      </div>
      <div className={classes.widget}>
        
        <Box
        href="/"
        style={{ lineHeight: 0 }}
    >
    <img
    alt="logo"
    width="100%"
    src={require("../assets/images/valuation3.svg")}
    />
    
    </Box>
    <Typography variant="h4" style= {{ marginBottom: 50, fontWeight: 800, fontFamily: "sweet-sans-pro"  }}>Property Valuation</Typography>
        <MenuList className={classes.widgetText}>
          <ul>
 
       
          
        </ul>
        </MenuList>
      </div>
      <div className={classes.widget}>
        
        <Box
        href="/"
        style={{ lineHeight: 0 }}
    >
    <img
    alt="logo"
    width="100%"
    src={require("../assets/images/analytics3.svg")}
    />
    
    </Box>
    <Typography variant="h4" style= {{ marginBottom: 50, fontWeight: 800, fontFamily: "sweet-sans-pro"  }}>Analytics</Typography>
        <MenuList className={classes.widgetText}>
          <ul>
       
       
         
        </ul>
        </MenuList>
      </div>
      </div>
    </Container>,  
     <div key="09" className={classes.contentWrapper}>
    
 <div className={classes.flex}>
 <div className={classes.textDiv}>
     <Typography style= {{ fontFamily: "sweet-sans-pro", fontWeight: 800 }} className={classes.text}><h1>SB APARTMENT GROUP</h1></Typography>
     <Typography style= {{ fontFamily: "sweet-sans-pro" }} className={classes.text}>SB Apt was created to fill a much needed gap in the local residential income sector. 
     With rapid changes in rents, interest rates, and regulations occurring almost daily, property values are a quickly moving target.
     To rely on a broker to guide you, you need someone who can take into account all those variables and work to earn you the highest price possible for your asset.</Typography>
     <Typography style= {{ fontFamily: "sweet-sans-pro", fontWeight: 800 }} className={classes.text}>
     I built SBAPT with that in mind. 
     </Typography>
     <Typography style= {{ fontFamily: "sweet-sans-pro" }} className={classes.text}>
     Over the past 5 years, my passion and business has been the sale of real estate investments on the central coast. I have been a broker specializing in the sale of multi-family and have worked on various large-scale transactions in residential and commercial real estate. My passion is analysis. My goal is to understand the best possible price for your asset, and achieve that for you. 
     </Typography>
     <Container key="07" style={{ width: "100%", maxWidth: "100%", marginBottom: 25 }} className={classes.featured}><NavLink to="/listings" style={{ textDecoration: "none", width: "100%"}}><Button className={classes.button} style={{ width: "100%", height: 50, marginBottom: 10, marginTop: 50, backgroundColor: "#a991363", fontFamily: "sweet-sans-pro"  }}>Featured Listings</Button></NavLink>
         </Container>
     </div>
     </div>
     </div>, 
]
    );
}
export default Home;
