import React, { useState } from "react";
import { Container, Button, Box, Typography, Paper, makeStyles } from "@material-ui/core";
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import SETTINGS from "../settings"
import HouseIcon from '@material-ui/icons/House';
const useStyles = makeStyles((theme) => ({
    propertyContainer: {
        padding: 20,
        width: 200,
        maxWidth: 200,
        marginBottom: 10,
        borderRadius: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    button: {
        backgroundColor: "#a99136",
        "&:hover": {
        backgroundColor: "#ead983",
        },
     
        color: "white",
        borderRadius: 2,
        
        fontWeight: 800,
      
        
    },
ul: {
    listStyleType: "none",
    fontFamily: "sweet-sans-pro"
},
buttonToggles: {
width: "95%",
position: "absolute",
top: "45%",
left: "2.5%",
height: 30, 
display: "flex",
zIndex: 1000,
justifyContent: "space-between"
},
dot: {
    height: "25px",
    width: "25px",
    padding: 0,
    backgroundColor: "rgba(255, 255, 255, 1.0)",
    display: "flex",
    boxShadow: "1px 1px 2px gray",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "45%",
    "&:hover": {
        backgroundColor: "rgba(255, 255, 255, .9)",
        boxShadow: "1px 1px 5px gray",
        height: "26px",
        width: "26px"
    }
  },
  smalldot: {
    height: "5px",
    width: "5px",
    padding: 0,
    backgroundColor: "rgba(255, 255, 255, 1.0)",
    display: "flex",
    zIndex: 1000,
    marginLeft: 5,
    marginRight: 5,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "45%",
  
  },
btn: {
    width: "48%",  
    height: 30,
    color: "black", 
    color: "#a99136",
    marginTop: 5,

   borderRadius: 2,

    display: "flex",
    justifyContent: "center",
    marginBottom: 5, 
    marginLeft: 0,
  
    border: "1px solid gray",
    
    padding: 5,
    paddingLeft: 30,
    paddingRight: 30,
    cursor: "pointer",
    "&:hover": {
    backgroundColor: "#ebebeb",
    borderColor: "#a99136",
    },
  },
    streetViewBox: {
        height: 200, 
        position: "relative",
        marginBottom: 20,
        width: 300,
        backgroundColor: "#ebebeb",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            width: "98%"
        }
    },
    complianceBox: {
        width: 50,
        height: 20,
        position: "absolute",
        bottom: 2,
        right: 2,
    },
    propEntry: {
    marginTop: 5,
    marginBottom: 5,
     marginLeft: 10,
     marginRight: 10,
     borderRadius: 2,
     maxWidth: 300,
     direction: "ltr",
     display: "flex",
     justifyContent: "space-between",
     alignItems: "center",
    [theme.breakpoints.down("sm")]: {
        marginLeft: "0", 
        marginRight: "0",
        width: "48%",
    }
    },
    imageDots: {
        position: "absolute",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        bottom: 20,
    }
}));

const rand = Math.floor(Math.random() * 6);
const formatter = (numStr) => {
    const numRet = new Intl.NumberFormat('en-US', {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
    })
    return numRet.format(numStr)
}
const PropertyEntry = (props) => {
const arr = props.imageArray;

const classes = useStyles();
const [slideshow, SetSlideshow] = useState(false);
const [imageIndex, SetImageIndex] = useState(0);

const changeIndexHandler = (direction) => {
    if (direction === "left" && imageIndex - 1 >= 0) {
        SetImageIndex(imageIndex - 1)
    }
    if (direction === "right" && imageIndex + 1 <= (arr.length - 1)) {
        SetImageIndex(imageIndex + 1);
    }
}

const style = {
   marginTop: 5,
   marginBottom: 5,
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 2,
    maxWidth: 300,
    direction: "ltr",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
};
const fontStyle = {
    fontFamily: "sweet-sans-pro"
};


   let baseImageURL = arr[imageIndex];
return (
    <Paper onMouseEnter={()=> SetSlideshow(!slideshow)} onMouseLeave={()=> SetSlideshow(!slideshow)} className={classes.propEntry}>
       
    <div style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "space-evenly"}}>

    <div style={{ display: "flex", justifyContent: "space-between"}}>
    
    <Box style={{display: "flex", flexDirection: "column", 
        justifyContent: "center", alignItems: "center" }}>
    <Paper className={classes.streetViewBox}>
    {slideshow ? <div className={classes.buttonToggles}><div className={classes.dot}><ArrowLeftIcon onClick={()=> changeIndexHandler("left")}
    style={{margin: 0}} /></div><div className={classes.dot}><ArrowRightIcon onClick={()=> changeIndexHandler("right")}
    style={{margin: 0}}/></div></div> : null}
    {props.imageArray.length > 0 ? 
    <div className={classes.imageDots}>
    {props.imageArray.map((image, index) => {
        if (index <= 6) {
        return (
    <div className={classes.smalldot}></div>
        )
        }
    })}
    </div>
     : null }
    
        <img src={baseImageURL}
        alt="img"
        style={{ borderRadius: 2, height: "98%", width: "98%"}} />
        <Box className={classes.complianceBox}>
            <img style={{width: "100%", height: "100%" }} src={require("../assets/images/sbmlsimg.jpg")}></img>
            </Box>
        </Paper>
    
    </Box>

    </div>
    <div style={{ display: "flex", flexDirection: "column",  justifyContent: "space-between" }}>
    <div style={{display: "flex", flexDirection: "column"}}>
    <Typography style={{fontSize: 16, fontWeight: 600}}>{formatter(props.price)}</Typography>
        <Typography style={{fontSize: 12, whiteSpace: "nowrap", marginLeft: 3, marginRight: 3, overflow: "hidden"}}>{props.Address}</Typography>
    

    </div>
    <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
    <Button className={classes.btn}><Typography>View</Typography></Button>
    <Button className={classes.btn} onClick={() => props.fetchCompsHandler("sale", props.latitude, props.longitude, props.id, props.originalPropertyId)}><Typography>Compare</Typography></Button>
    </div>

    </div>
 
    <Box style={{display: "flex", flexDirection: "column", 
        justifyContent: "center", alignItems: "center" }}>
    <ul className={classes.ul}>


    </ul>
    </Box>
    <Box style={{display: "flex", flexDirection: "column", 
        justifyContent: "center", alignItems: "center" }}>
   
  
    </Box>
    
    </div>
    </Paper>
)
}

export default PropertyEntry;