import React from "react";
import { Box, Typography, makeStyles} from "@material-ui/core";
import { classes } from "istanbul-lib-coverage";

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: "white",
        width: "100%",
        borderTop: "1px solid gray",
        borderBottom: "1px solid gray",
        color: "black",
        fontWeight: 800,
        fontSize: 36,
        display: "flex",
        justifyContent: "flex-start",
        paddingLeft: "10%",
        margin: 0,
        paddingTop: 5,
        paddingBottom: 5,
    },
}))

const Banner = (props) => {
    const classes = useStyles();
    return (
        <Box className={classes.banner}>{props.page}</Box>
    )
}
export default Banner;