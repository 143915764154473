import SETTINGS from "../settings";
import axios from "axios";
const getAvailableProperties = async (transactionType, neighborhood, city, state, requestType, filters) => {
    let baseUrl = SETTINGS.DOMAINREQUIREMENTS.BACKEND.baseUrl;
    console.log(baseUrl)
    let properties;
    if (transactionType === "sale") {
       properties = await axios.get(
            `${baseUrl}/sales/spark/${neighborhood}/${city}/${state}/${requestType}?${filters}`);
    }

    if (transactionType === "vacation") {
        properties = await axios.get(
            `${baseUrl}/vacation/airbnb/${neighborhood}/${city}/${state}/${requestType}?${filters}`);
    }


    return properties;
}

export default getAvailableProperties