import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core"
import SETTINGS from "../settings";
import getGooglePlaces from "../webRequests/googlePlaces";
import { Typography, Paper } from "@material-ui/core";
import stateConv from "../util/stateConv";
import CompsShow from "../components/compsShow";
import CircularProgress from '@material-ui/core/CircularProgress';

const NeighborhoodMap = (props) => {
    const useStyles = makeStyles((theme) => ({
      container: {
        width: "100%", 
        position: "relative", 
        display: "flex", 
        height: "100%"
      },
      subContainer: {
        width: "100%",
        height: "100%"
      },
      neighborhoodContainer: {
          position: "absolute",
          paddingTop: 5, 
          paddingBottom: 10, 
          display: "flex", 
          flexDirection: "column", 
          justifyContent: "space-evenly", 
          backgroundColor: "rgba(255, 255, 255, .90)", 
          right: "75%", 
          width: "25%", 
          zIndex: 15, 
          height: "100%",
      },
      neighborhoodText: {
        fontWeight: 600, 
        textDecoration: "underline", 
        fontFamily: "sweet-sans-pro"
      },
      img: {
        height: "98%",
        width: "98%"
      },

      imagePaper: {
        height: 200, 
        width: "98%", 
        display: "flex",
        flexDirection: "column", 
        justifyContent: "center", 
        alignItems: "center"
      },
      circularProg: {
        position: "absolute", 
        width: "100%", 
        height: "100%", 
        left: 0
      }
    }))

    const classes = useStyles();
    const [mapLoaded, SetMapLoaded] = useState(false);
    const [mapState, SetMapState] = useState(null);
    const [hoveredNeighborhood, SetHoveredNeighborhood] = useState(null);
    const [markers, SetMarkers] = useState(null);
    const [hoveredNeighborhoodDescription, SetHoveredNeighborhoodDescription] = useState(null);
    const [hoveredNeighborhoodTags, SetHoveredNeighborhoodTags] = useState(null);
    const [hoveredNeighborhoodGoogleRef, SetHoveredNeighborhoodGoogleRef] = useState(null);
    const [hoveredNeighborhoodHash, SetHoveredNeighborhoodHash] = useState({});
    

    const onScriptLoad = () => {
    const map = new window.google.maps.Map(document.getElementById(props.id), props.options);
      SetMapLoaded(true);

      if (!props.geoLoading) {
        LoadData(map, props.geoJSON);
        SetStyles(map)
        SetListeners(map)
      }

      AddMarkers(map, markers)
      SetMapState(map);
}
const LoadData = (map, geoData) => {
    map.data.addGeoJson(geoData);
}
const SetStyles = (map) => {
    map.data.setStyle((feature) => {
      let fillOpacity = .15;
      let color = "rgb(30, 139, 195)"
      let selected = false;

      if (props.neighborhoodParams.includes(feature.getProperty("NAME"))
        && !feature.getProperty("manuallyRemoved")) {
        selected = true;
        feature.setProperty("isSelected", selected);
        feature.setProperty("byType", true);
      }



      if (feature.getProperty("isHovered")) {
        color = "rgb(30, 139, 195)";
        fillOpacity = .55;
      }
      if (feature.getProperty("isSelected")) {
        color = "rgb(30, 139, 195)";
        fillOpacity = .55;
      }
      return {
        fillColor: color,
        strokeColor: "#a99136",
        fillOpacity: fillOpacity,
        strokeOpacity: .65,
        strokeWeight: 1,
      }
    })


  }

  const SetListeners = (map) => {
    map.data.addListener('mouseover', async (event) => {
      event.feature.setProperty("isHovered", true)
      SetHoveredNeighborhood(event.feature.getProperty("NAME"));
      SetHoveredNeighborhoodDescription(event.feature.getProperty("description"));
      SetHoveredNeighborhoodTags(event.feature.getProperty("neighborhoodTypes"));
      if (!hoveredNeighborhoodHash[event.feature.getProperty("NAME")]) {
        try {
          const oldHash = hoveredNeighborhoodHash;
          const placeRef = await getGooglePlaces(event.feature.getProperty("NAME").split(" ").join("_"),
            event.feature.getProperty("CITY").split(" ").join("_"), stateConv[event.feature.getProperty("STATE")].split(" ").join("_").toLowerCase())
          if (placeRef.data.candidates.length > 0) {
            if (placeRef.data.candidates[0].photos) {
              const reference = placeRef.data.candidates[0].photos[0].photo_reference;

              oldHash[event.feature.getProperty("NAME")] = reference;
              SetHoveredNeighborhoodGoogleRef(reference);
              SetHoveredNeighborhoodHash(oldHash);
            }
            if (!placeRef.data.candidates[0].photos) {
              oldHash[event.feature.getProperty("NAME")] = "none"
              SetHoveredNeighborhoodHash(oldHash);
              SetHoveredNeighborhoodGoogleRef("none");
            }
          }
          if (!placeRef.data.candidates.length > 0) {
            oldHash[event.feature.getProperty("NAME")] = "none"
           SetHoveredNeighborhoodHash(oldHash);
            SetHoveredNeighborhoodGoogleRef("none");
          }
        }
        catch (error) {
          const copyHash = hoveredNeighborhoodHash;
          copyHash[event.feature.getProperty("NAME")] = "none";
          SetHoveredNeighborhoodHash(copyHash);
        }
      }
      if (hoveredNeighborhoodHash[event.feature.getProperty("NAME")]) {
        const oldHash = hoveredNeighborhoodHash;
        SetHoveredNeighborhoodGoogleRef(oldHash[event.feature.getProperty("NAME")]);
      }
    });

    map.data.addListener('mouseout', (event) => {
      event.feature.setProperty("isHovered", false)
      SetHoveredNeighborhood(null);
    });
    map.data.addListener('click', (event) => {

      event.feature.setProperty("isSelected", !event.feature.getProperty("isSelected"))
      if (event.feature.getProperty("byType")) {
        event.feature.setProperty("manuallyRemoved", true)
        props.callBack(event.feature.getProperty("NAME"), "remove")
      }


      if (event.feature.getProperty("isSelected")) {
        props.callBack(event.feature.getProperty("NAME"), "add")
      }
      if (!event.feature.getProperty("isSelected")) {
        props.callBack(event.feature.getProperty("NAME"), "remove")
      }

    });
  }

const RemoveMarkers = () => {
  if (markers !== null) {
  for (let i = 0; i < markers.length; i++) {
    markers[i].setMap(null);
    markers[i] = null;
  }
}
}

 const AddMarkers = (map, markers) => {
    const newMarkers = [];
    props.listings.map((property) => {
      const position = { lat: property["Geo Lat"], lng: property["Geo Lon"] }
      const svgMarker = {
        path:
          "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
        fillColor: "#a99136",
        fillOpacity: 1.0,
        strokeWeight: 1.5,
        strokeColor: "white",
        rotation: 0,
        zIndex: -1,
        clickable: false,
        pointerEvents: "none",
        scale: .30,
        anchor: new window.google.maps.Point(15, 30),
      };
      const marker = new window.google.maps.Marker({
        position: position,
        icon: svgMarker,
        map
      });
      newMarkers.push(marker);
    })
    SetMarkers(newMarkers);
    SetMapState(map);
    props.setLoadStatus();
  }




  useEffect(() => {
    if (!window.google) {
      let s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = `https://maps.google.com/maps/api/js?key=${SETTINGS.APIKEYS.GOOGLE3}&map_ids=20d24c72b591046f&callback=initMap`;
      let x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
      // Below is important. 
      //We cannot access google.maps until it's finished loading
      s.addEventListener('load', e => {
        onScriptLoad()
      })
    } else {
      onScriptLoad()
    }
  }, [])

useEffect(() => {
if (mapState) {
const mapCopy = mapState;
if (props.center[0] && props.center[1]) {
  mapCopy.setCenter({ lat: props.center[0], lng: -1 * props.center[1] })
}

SetMapState(mapCopy)
}
}, [props.center[0], props.center[1]]);

useEffect(() => {
  const mapCopy = mapState;
  if (!props.geoLoading && mapLoaded && mapState) {
    
    mapCopy.data.forEach((feature) => {
      mapCopy.data.remove(feature);
    });
    window.google.maps.event.clearListeners(mapCopy.data, "click");
    window.google.maps.event.clearListeners(mapCopy.data, "mouseover");
    window.google.maps.event.clearListeners(mapCopy.data, "mouseout");
    LoadData(mapCopy, props.geoJSON);
    SetStyles(mapCopy)
    SetListeners(mapCopy)
    mapCopy.setZoom(12);
    SetMapState(mapCopy);
  }
}, [props.geoLoading, mapLoaded, mapState]);

useEffect(() => {
  const mapCopy = mapState;
    if (props.lastSetter === "checkbox") {
      window.google.maps.event.clearListeners(mapCopy.data, "click");
      window.google.maps.event.clearListeners(mapCopy.data, "mouseover");
      window.google.maps.event.clearListeners(mapCopy.data, "mouseout");
      mapCopy.data.forEach((feature) => {
        feature.removeProperty("isSelected")
        feature.removeProperty("byType")
        feature.removeProperty("manuallyRemoved")
      })

      SetStyles(mapCopy)
      SetListeners(mapCopy)
    }
SetMapState(mapCopy);

}, [props.neighborhoodParamString]);

useEffect(() => {
  if (props.shouldUpdateMap && mapLoaded && !props.geoLoading) {
    const mapCopy = mapState;
    RemoveMarkers();
    AddMarkers(mapCopy, markers);
  }
}, [props.shouldUpdateMap, mapLoaded, props.geoLoading]);

    return (
      <div className={classes.container}>
        <div className={classes.subContainer} id={props.id}>
        </div>
        {props.compMode ? <CompsShow closeDialog={props.closeDialog} comps={props.comps} subject={props.subject} /> : null}
        {hoveredNeighborhood && hoveredNeighborhoodTags ? <Paper className={classes.neighborhoodContainer}><Typography className={classes.neighborhoodText} >{hoveredNeighborhood}</Typography>

          <Paper className={classes.imagePaper}>
            {hoveredNeighborhoodGoogleRef && hoveredNeighborhoodGoogleRef !== "none" ?
              <img className={classes.img}
                src={`https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=${hoveredNeighborhoodGoogleRef}&key=${SETTINGS.APIKEYS.GOOGLE2}`}
                alt="placeImage"></img> : <Typography>No Imagery Available</Typography>}</Paper>

          <div style={{ fontWeight: 600 }}>{hoveredNeighborhoodDescription}</div></Paper> : !mapLoaded ? <div className={classes.circularProg}>
            <CircularProgress /></div> : null}
      </div>
    );
  }

export default NeighborhoodMap;