import React from "react";
import './App.css';
import { Header } from "./components/Header";
import Headerslideshow from "./components/Headerslideshow";
import BodyContent from "./pages/home";
import { Typography, Paper
} from "@material-ui/core";
import Footer from "./components/Footer";
import Home from "./pages/home";
import About from "./pages/about";
import Services from "./pages/services";
import { createBrowserHistory } from "history";
import Research from "./pages/research";
import Banner from "./components/Banner";
import Properties from "./pages/properties";
import Valuation from "./pages/valuation";
import Posts from "./pages/posts";
import Listings from "./pages/listings";
import Listing from "./pages/listing";
import Analyzer from "./pages/neighborhoodAnalyzer";
import { Router, Route, Switch, Redirect, useParams } from "react-router-dom";
const history = createBrowserHistory();

const HOC = ({ page, props, pageType }) => {
  console.log(page);
return (
  <div className="App" style={{ margin: 0, padding: 0 }}>
    
  <Header style={{ maxWidth: "100%", width: "100%" }} page={page} />
  {page !== "Posts" && page !== "Analyzer" && page !== 'Listings' && page !== 'Listing' ? 
  <Headerslideshow style={{ maxWidth: "100%" }} page={page} /> : null}
  {page !== "Home" && page !== "Analyzer" && page !== "Listing" && page !== "Listings" ? <Banner page={page} /> : null}
  {page === "Home" ? <Home /> : page === "Services" ? <Services /> :
page === "Valuation" ? <Valuation /> : page === "Analyzer" ? <Analyzer /> : page === "Posts" ? <Posts {...props} pageType={pageType} /> : page === 'Listings' ? <Listings {...props} pageType={pageType} /> : page === 'Listing' ? <Listing {...props} /> :  page === "About" ? <About /> :
page === "Research" ? <Research /> : null}
</div>
)
}


const App = () => {

  return (
<Router history={history}>    
<Switch>
  <Route exact path="/properties/:transactiontype/:region/:state/:neighborhood" component={Properties} />
  <Route exact path="/analyzer/:region/:state" render={(props) => (
    <HOC {...props} page="Analyzer" />)} />
  <Route>
  <Switch>
  <Route path="/about" render={(props) => (
    <HOC {...props} page="About" />)} />
  <Route path="/services" render={(props) => (
    <HOC {...props} page="Services" />)} />
  <Route path="/research" render={(props) => (
    <HOC {...props} page="Research" />)} />
   <Route path="/valuation" render={(props) => (
    <HOC {...props} page="Valuation" />)} />
    <Route exact path="/posts/:id" render={(props) => (
    <HOC {...props} page="Posts" pageType="singlePost"  />)} />
        <Route path="/posts" render={(props) => (
    <HOC {...props} page="Posts" pageType="allPosts" />)} />
            <Route path="/listings" render={(props) => (
    <HOC {...props} page="Listings" pageType="allListings" />)} />
     <Route path="/listing" render={(props) => (
    <HOC {...props} page="Listing" />)} />
  <Route path="/" render={(props) => (
    <HOC {...props} page="Home" />)} />
  </Switch>
  <Footer />
  </Route>
  </Switch> 
    </Router>
 
  );
}

export default App;
