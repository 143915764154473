let backendUrl; 
let webScraperUrl;

if (process.env.REACT_APP_IS_DEVELOPMENT === "true") {
 backendUrl = "http://localhost:5000";
 webScraperUrl = "http://localhost:8001";
}

if (process.env.REACT_APP_IS_DEVELOPMENT === "false") {
    backendUrl = "https://api.townz.io";

}

const SETTINGS = {
    DOMAINREQUIREMENTS: {

        BACKEND: {
            baseUrl: backendUrl,
            "X-Access-Token": null,
            headers: null,
        },

        WEBSCRAPER: {
            baseUrl: webScraperUrl,
            "X-Access-Token": null,
            headers: null,
        },
    },

    APIKEYS: {
        GOOGLE: "AIzaSyBO_WspjC-jheeQbPUJVjX_dPcLVhOIwFs",
        GOOGLE2: "AIzaSyAOraQ-cIt4HaOhzV2Wqa52GrEucexGK9E",
        GOOGLE3: "AIzaSyC52Z5JcPDqwZgFC79x7U7GTfeD46MHHpY"

    }

}
export default SETTINGS