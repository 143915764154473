module.exports = Object.freeze({

Marina: {
    type: "beach",
coordinates: [

[37.80487762882982, -122.44797230469956],
[37.80449788017346, -122.44850814869832],
[37.804377511885185, -122.44869709498279],
[37.80428681273715, -122.44892359219351],
[37.804139321988714, -122.4493551289428],
[37.80396988943098, -122.44989354503979],
[37.80377926396298, -122.45043196113676],
[37.803618307608026, -122.45076116493055],
[37.803525167245354, -122.45091870734743],
[37.8033473632275, -122.45080463873877],
[37.80320770563091, -122.45046526457286],
[37.802602448321466, -122.44985878643337],
[37.802373936975336, -122.44968675241752],
[37.80212423192573, -122.44942352329518],
[37.801607864777765, -122.44873613250957],
[37.80060055178033, -122.44766175834805],
[37.80049675737362, -122.44764296420357],
[37.80035481451806, -122.44765635656726],
[37.79855345145612, -122.44731836086862],
[37.79864257331797, -122.4464278301129],
[37.79872104457783, -122.44575189475977],
[37.79877408300584, -122.44530126496389],
[37.79881244615396, -122.4450007828287],
[37.798963030057806, -122.4448505417611],
[37.79894557183645, -122.4448042643149],
[37.798918827895065, -122.44473016362052],
[37.79890480029901, -122.44463460525402],
[37.79889475993835, -122.44451724926893],
[37.79890167471755, -122.4444106221199],
[37.79916309295195, -122.44216121821076],
[37.79945842029199, -122.43991181430164],
[37.79974103024235, -122.43768118585562],
[37.79988233481211, -122.43655782500556],
[37.79995086763333, -122.43598541574448],
[37.800029997191906, -122.43545592182763],
[37.80056650914761, -122.43118659035369],
[37.801003132330116, -122.42785269770792],
[37.80105267416897, -122.42745221619687],
[37.801102215974595, -122.4270302770137],
[37.801151757746965, -122.42664320654772],
[37.80120129948615, -122.42627222933582],
[37.801390988827066, -122.4247212652629],
[37.80181220760646, -122.42480006679767],
[37.80203341260892, -122.42484349087859],
[37.80223554328052, -122.42488423275049],
[37.80257940331271, -122.42495632876272],
[37.80292114246693, -122.42502306035693],
[37.80320910114612, -122.42507989548292],
[37.80349705870258, -122.42514209502693],
[37.80410052061468, -122.42526112969693],
[37.8040141663365, -122.42599036691782],
[37.8039256927091, -122.4267169219297],
[37.803688356260565, -122.42850466081798],
[37.80346161535793, -122.43029239970627],
[37.80340916854979, -122.43071988841298],
[37.803341886850795, -122.43113664828364],
[37.80323699302722, -122.43175559130376],
[37.804044508677485, -122.43190598132803],
[37.80459449014188, -122.43201336284834],
[37.80487583700144, -122.4320670536085],
[37.80503982141213, -122.4320965811976],
[37.805128171122455, -122.43211268609664],
[37.805169898065664, -122.43216634192191],
[37.805081206878036, -122.43292749345541],
[37.80504525916092, -122.43318416601193],
[37.80501354986049, -122.43344352077743],
[37.80498395976441, -122.43370689885646],
[37.805007310631716, -122.43383188237343],
[37.80505185365399, -122.4339541836814],
[37.80579420708867, -122.43514925724857],
[37.80621624140582, -122.43575349955472],
[37.806376397407114, -122.43608780721596],
[37.80648569288354, -122.43646503022146],
[37.80632707039081, -122.43774250699032],
[37.80613454060722, -122.43925601815252],
[37.805749479534214, -122.4421972097884],
[37.805552709841756, -122.44374827187679],
[37.805372893280804, -122.44528860512911],
[37.80520579155226, -122.44671092118479],
[37.80511376369004, -122.44745426572081],
[37.805063511284835, -122.44774010730033],
[37.80502143133685, -122.44787766367207],
],
},

Russian_Hill: {
    type: null,
coordinates: [
[37.80415606592774, -122.4250302424625],
[37.7957980174348, -122.42334283713402],
[37.79597813856335, -122.42190393982813],
[37.796019451051656, -122.42183090354777],
[37.7961587977644, -122.42074832389179],
[37.7961903205684, -122.42019362301873],
[37.79626118815825, -122.42012816709438],
[37.79645074568835, -122.41857676737577],
[37.79729546491222, -122.41199119989754],
[37.80209065701513, -122.41305529252976],
[37.80287826755317, -122.4139801332771],
[37.80327312930614, -122.41454984201135],
[37.80342923378373, -122.4148011687658],
[37.80350781571387, -122.41496304196473],
[37.803605470879816, -122.41510211638703],
[37.80398443439733, -122.41563963861311],
[37.804352799791054, -122.41618520746626],
[37.8047280508298, -122.4167247413491],
[37.80477535443009, -122.41677642510649],
[37.804800406160254, -122.41681872113234],
[37.8048424116595, -122.41687576930777],
[37.80487699986185, -122.41693147637868],
[37.80516952538417, -122.41730316726267],
[37.8051416518725, -122.41749179738139],
[37.805116162466156, -122.41767238087307],
[37.80507074656777, -122.41803991810285],
[37.8050245359352, -122.41840695241842],
[37.80497753056697, -122.41876996342049],
[37.80493304174178, -122.41912744236645],
[37.80488961250013, -122.41947888634213],
[37.804866374679435, -122.41966734882278],
[37.804842607045956, -122.41985715240796],
[37.80481989901613, -122.42003890936608],
[37.804810266867214, -122.42012626744581],
[37.80479745588224, -122.4202193252197],
[37.80477531814128, -122.42035929377836],
[37.80475344529675, -122.42051300865823],
[37.804704931330186, -122.42086234793382],
[37.80465350339409, -122.42116072523812],
[37.80463308748272, -122.42131293137541],
[37.804612141758575, -122.42147050193074],
[37.80459106357667, -122.42161801420225],
[37.804571574811085, -122.42176150316025],
[37.8045325972644, -122.42207195040513],
[37.80449467931317, -122.4223599341495],
[37.8044578209589, -122.42265931728218],
[37.804307738311444, -122.42382768078988],
[37.80423587573242, -122.42441119199148],
],
},
North_Beach: {
    type: "beach",
coordinates: [
[37.80600549023311, -122.41036308947254],
[37.79766919152792, -122.40867463889766],
[37.79731100736613, -122.41189798358131],
[37.79854292045965, -122.41214860248614],
[37.799177943765166, -122.41228732298363],
[37.799497572746105, -122.41234796605308],
[37.799653147976365, -122.41238465783421],
[37.799821439076155, -122.41241799685407],
[37.80016040440133, -122.41248735710282],
[37.80049936817099, -122.41255135293353],
[37.80083435662845, -122.4126160193165],
[37.80100582403515, -122.4126597518963],
[37.80116881373484, -122.41269007343102],
[37.80179480740137, -122.41282074730147],
[37.8020694561432, -122.41287353233669],
[37.80215803627076, -122.41289388988402],
[37.80228370397037, -122.41291022411782],
[37.80244198867379, -122.41312576475788],
[37.803748519913775, -122.41502610984493],
[37.804614229829035, -122.41629613581348],
[37.80479171500241, -122.41655798646856],
[37.80497979584008, -122.41682117822815],
[37.80511860448922, -122.41682654264618],
[37.805198074972424, -122.41685604694534],
[37.80560284330624, -122.41358978691746],
[37.8057983392594, -122.41196302714992],
],
},

Telegraph_Hill: {
    type: null,
coordinates: [
[37.80601278055094, -122.41030765839074],
[37.79768571243806, -122.4086079278652],
[37.79806163086219, -122.405442905656],
[37.798471457857154, -122.40228593007386],
[37.79938675798147, -122.4024562867374],
[37.80029992741206, -122.40265748880464],
[37.80104459893707, -122.4028003528258],
[37.80141693188422, -122.40286910262738],
[37.80225768601056, -122.40302320749657],
[37.80309207275028, -122.40320011114238],
[37.80355976208163, -122.40329333610121],
[37.80379466526276, -122.40333994858061],
[37.80403645524355, -122.4033899138213],
[37.80400728098827, -122.40360323780591],
[37.80397916634477, -122.40381723234276],
[37.80392346683788, -122.40422845761012],
[37.80386087972642, -122.4046758926992],
[37.80383329483997, -122.4049039688334],
[37.80383911412225, -122.40494422406059],
[37.803847052654675, -122.4049831381833],
[37.803859229686665, -122.4050146762312],
[37.80387352596646, -122.40503280323402],
[37.80427484566582, -122.40511263534333],
[37.804472325848934, -122.40514986918896],
[37.804672984344286, -122.40519649076616],
[37.804908609433575, -122.40524445344786],
[37.80503118993368, -122.40526776423646],
[37.80514688278767, -122.4052877222638],
[37.80529747315715, -122.40531992696754],
[37.80537594703677, -122.40533602931939],
[37.80544753341841, -122.40535079056676],
[37.80569659710509, -122.4054064684958],
[37.805774005338726, -122.4053531816836],
[37.80580184855454, -122.40539962847318],
[37.80582199529065, -122.40541849327829],
[37.80584214202127, -122.40543333476991],
[37.80594948366975, -122.40545548508626],
[37.8059977527981, -122.40544310196154],
[37.806020827756406, -122.40543590457082],
[37.806052379456865, -122.40543105411297],
[37.806090052588004, -122.40543929047006],
[37.80610928649363, -122.40544910834275],
[37.80611686487559, -122.40545691455868],
[37.80619232618743, -122.4055344517119],
[37.806231646201, -122.40557137626982],
[37.80625700150005, -122.40560844637382],
[37.806274409862624, -122.4056404873359],
[37.80629897045444, -122.40569256104656],
[37.806312935140085, -122.40576810408614],
[37.80632345615658, -122.40581716031167],
[37.806333447376765, -122.40586353432818],
[37.806367999164486, -122.40593935091682],
[37.80640209602315, -122.40596787147958],
[37.80641702526986, -122.40597894663777],
[37.80643195451352, -122.40598599848242],
[37.80647961865141, -122.40600498576754],
[37.80650742416052, -122.40601582051463],
[37.80652410399809, -122.40602866691846],
[37.806548059832366, -122.40607397337955],
[37.806555856968174, -122.40609715161625],
[37.80643187919053, -122.40696855246553],
[37.806320616282996, -122.40785336435988],
],
},

Embarcadero: {
    type: "beach",
coordinates: [
[37.80660922218021, -122.4059709570103],
[37.80656992580136, -122.40611403814228],
[37.806554780846454, -122.40606487930259],
[37.80652654642789, -122.40601683055388],
[37.806484537363445, -122.40599627444756],
[37.806443167592455, -122.4059804755825],
[37.80640923784326, -122.40596117676166],
[37.80638379625249, -122.40594314544808],
[37.80636418812468, -122.40591904236557],
[37.80634908611225, -122.4058882153612],
[37.80633650205476, -122.40584933253014],
[37.80632226310256, -122.40577792331494],
[37.80631678285756, -122.40575001272734],
[37.806310507919676, -122.40572243741586],
[37.80630147748048, -122.40568074846806],
[37.806289015334244, -122.40565353374774],
[37.806278281001376, -122.40563255031279],
[37.806270725438466, -122.40561827240039],
[37.80625096663865, -122.40558580354609],
[37.806237643566256, -122.40556722218604],
[37.80621032000071, -122.40554183825192],
[37.80618844637928, -122.40551908800106],
[37.80616770834116, -122.40550201318148],
[37.80614197523271, -122.40547671196533],
[37.80611586376763, -122.40544763282706],
[37.806092609449244, -122.40543242270566],
[37.80605846592829, -122.40542481764496],
[37.806028149238585, -122.40542738536102],
[37.80598782552294, -122.40543772167449],
[37.80595309422667, -122.40544724842086],
[37.80592560358346, -122.40544309222297],
[37.805884338182956, -122.40543558326384],
[37.805861056483174, -122.40543048767977],
[37.80583671517936, -122.4054220393344],
[37.8058187167414, -122.40540708632567],
[37.80580389709057, -122.40538878055564],
[37.80577848146215, -122.40534734073289],
[37.805770470093805, -122.40534417241183],
[37.80575639823033, -122.40535599929636],
[37.805740472067534, -122.40536782618089],
[37.805720672664094, -122.40538242749453],
[37.80569328958285, -122.40540112753966],
[37.80568092253709, -122.40538399074822],
[37.80565725562728, -122.40536905210607],
[37.805633766574665, -122.40536258861339],
[37.80572842281533, -122.40532192695575],
[37.80505372789367, -122.40522141132617],
[37.80475720627695, -122.40514987395494],
[37.80438809349942, -122.40508919715536],
[37.80389890641665, -122.40499511471154],
[37.803885750564476, -122.4049742763096],
[37.803872594709965, -122.40492661581753],
[37.803901823844114, -122.4046402387231],
[37.803976616786784, -122.40410978060834],
[37.80405326745283, -122.40355167600492],
[37.80408117544817, -122.40334494078246],
[37.798475218891184, -122.40222604936666],
[37.798800904634255, -122.39975284752664],
[37.79892771731782, -122.3987455754774],
[37.79902079502985, -122.3982446216618],
[37.79920288679647, -122.39786973166989],
[37.801245412239076, -122.3997042658888],
[37.80229208532598, -122.40058398207205],
[37.80279846206443, -122.40106675550791],
[37.80329635826637, -122.40151197801757],
[37.8041904171192, -122.40234542609532],
[37.805067511426905, -122.40358656994331],
],
},

Waterfront: {
    type: "beach",
coordinates: [
[37.80601786481334, -122.42539102409897],
[37.80760809377323, -122.42205922328075],
[37.80848456775542, -122.42114044238772],
[37.80888635313802, -122.42112288372368],
[37.80911055510733, -122.42069299757632],
[37.80909741759467, -122.42004853470779],
[37.809122000959185, -122.41910293172461],
[37.81144100023436, -122.42062947500658],
[37.81156302980949, -122.41890825895939],
[37.81095611078417, -122.4179595191085],
[37.80928453477916, -122.41567580130855],
[37.80953956690621, -122.4152765185178],
[37.80900592562843, -122.41404690886071],
[37.80894517186533, -122.41285861605688],
[37.80920299269428, -122.41343391278545],
[37.809477765336446, -122.4133869370226],
[37.80906806140548, -122.41259821915176],
[37.809358688842366, -122.41186415496904],
[37.808971205139585, -122.41151632888445],
[37.80943136037323, -122.41039602660356],
[37.810343185981935, -122.41097710592548],
[37.810924429674344, -122.41133287969015],
[37.81112589450709, -122.41124211512732],
[37.81122564499929, -122.41097968918753],
[37.811408193313206, -122.41043337963583],
[37.81132782559777, -122.41022191566734],
[37.81102707749923, -122.4100748247152],
[37.81057814991395, -122.41010250789269],
[37.8100447679517, -122.41014714541163],
[37.80934185490463, -122.40975726507013],
[37.809200497976676, -122.40958196144983],
[37.80913542837263, -122.40920817436245],
[37.80901376540199, -122.4092223475479],
[37.810015219730374, -122.40680643936585],
[37.81004451259067, -122.40640051524376],
[37.81005261471798, -122.40616625249864],
[37.80849221259449, -122.40603568534426],
[37.80753997448443, -122.40593730469806],
[37.80718252358868, -122.40590420762902],
[37.8066301075202, -122.40603204310092],
[37.805893373715996, -122.41203251101972],
[37.80528802364705, -122.41690645115227],
[37.804731515413536, -122.42110289146152],
[37.80447445205233, -122.42314210301781],
[37.80422162627604, -122.42500965319714],
],
},

Cow_Hollow: {
    type: null,
coordinates: [
[37.79843024503725, -122.44727958066132],
[37.79568344387678, -122.44685310942795],
[37.79653017317923, -122.44040910226967],
[37.79712097041744, -122.435560338923],
[37.797542208612164, -122.43226457459595],
[37.798063057046214, -122.42819767517712],
[37.79832559929508, -122.42616958988573],
[37.79844839244843, -122.42513408956792],
[37.798564827191626, -122.42411200029518],
[37.80015489148458, -122.42450561446812],
[37.8008524213837, -122.42464877737429],
[37.80113760404276, -122.42469890115525],
[37.801176944063016, -122.42490995747711],
[37.79976121997432, -122.43610281569626],
[37.79903215387091, -122.44170997364189],
[37.798690931507444, -122.44447164309885],
[37.79871106579001, -122.44469108132388],
[37.79873967765584, -122.4448890618768],
[37.79860191671866, -122.4459475286093],
[37.79852477238122, -122.44656763518495],
],
},

Pacific_Heights: {
    type: null,
coordinates: [
[37.7956214982673, -122.44684328730563],
[37.7945654993763, -122.4468379228876],
[37.79345013804818, -122.44669308360079],
[37.79311194747055, -122.44662468727091],
[37.79285005976141, -122.44658311303118],
[37.79276833465531, -122.44649862344721],
[37.79215247570727, -122.44639267619112],
[37.791931551348966, -122.44765867884615],
[37.790035795187706, -122.44731803830126],
[37.78714625201937, -122.44674002225855],
[37.78653534439703, -122.44662410053769],
[37.785924431724524, -122.44650817881683],
[37.78482129991928, -122.4462763353751],
[37.78265738409158, -122.44581264849165],
[37.782703597977246, -122.44553780588666],
[37.782874883217346, -122.44411229561427],
[37.783078602703355, -122.44213299299935],
[37.78317113443078, -122.4414269433857],
[37.783257306519005, -122.44074503365317],
[37.783424350803195, -122.43950727801183],
[37.78344194643026, -122.43937191027561],
[37.78347443315499, -122.43907588241035],
[37.783534477702744, -122.4388710496516],
[37.783610420936405, -122.43866353468383],
[37.78367894469057, -122.43849491174677],
[37.78376925132909, -122.43816215019086],
[37.78383306005349, -122.43787230397919],
[37.78408235298933, -122.43607907961467],
[37.78417096229592, -122.43530383739034],
[37.78424928966497, -122.43511136793876],
[37.7842936999753, -122.43489744081506],
[37.78437404127971, -122.43437839146117],
[37.7846394448584, -122.43224435891369],
[37.784790164568975, -122.43111028741458],
[37.784839133842716, -122.43020956809977],
[37.78496998009807, -122.42902554045776],
[37.78511142506258, -122.42794343675831],
[37.78539431417949, -122.4257577716873],
[37.785480644139454, -122.42501161466697],
[37.78547158406441, -122.4246543779538],
[37.785455805461105, -122.42440380095849],
[37.78544214663302, -122.42408885094682],
[37.78544014658556, -122.42393617458055],
[37.785470943234145, -122.42365062409425],
[37.785499620092935, -122.423373120235],
[37.78558700981712, -122.42318352158526],
[37.78797373517732, -122.42359389956454],
[37.78874845811748, -122.42378232474783],
[37.789097662425526, -122.42383898641327],
[37.78950621564178, -122.42392247016886],
[37.79005412071625, -122.42402238245467],
[37.790219970093695, -122.42405624534348],
[37.790239564020425, -122.42385139162997],
[37.790404691758745, -122.42249687607745],
[37.79850720843204, -122.42410620148638],
[37.79586899071885, -122.44493623669604],
[37.79574948356253, -122.44586428101519],
],
},

Nob_Hill: {
    type: null,
coordinates: [
[37.79567152241025, -122.42332320636585],
[37.790476286347136, -122.42220804837565],
[37.79229442901787, -122.40761667109464],
[37.79359889994697, -122.40785534763695],
[37.79490334784453, -122.40811011743337],
[37.79616962224599, -122.40836220502078],
[37.79758847406696, -122.40865720795242],
[37.79703611996759, -122.41351997277643],
[37.796711193914334, -122.41588429996305],
[37.79656992494793, -122.41713083657271],
[37.79646537897424, -122.41795795276269],
[37.79626757632011, -122.41825935598573],
[37.79589324877167, -122.42178906292932],
],
},

Financial_District: {
    type: null,
coordinates: [
[37.797556441489355, -122.40852800947651],
[37.798921342448004, -122.39811367226653],
[37.79907817727807, -122.39779549030084],
[37.797711557183305, -122.39660638097622],
[37.79652294671541, -122.39555674652041],
[37.79491293386938, -122.39433724216566],
[37.792558919074125, -122.3973529458454],
[37.79016667571216, -122.40038474277924],
[37.78955290403509, -122.40113420002017],
[37.78892640918942, -122.40193730144138],
[37.789949014550444, -122.40214763467029],
[37.790081360031955, -122.40217021326816],
[37.78993285222314, -122.40335687057849],
[37.78978010480776, -122.40455425672491],
[37.789461891051495, -122.40694366459968],
],
},

Downtown: {
    type: null,
coordinates: [
[37.785028067572384, -122.41954661447494],
[37.79056475269377, -122.42065436679806],
[37.79219049223575, -122.40761078435867],
[37.78936503147596, -122.40698851186721],
[37.78965065591778, -122.40463621456115],
[37.789987150828765, -122.40232683259933],
[37.78875244546227, -122.40211762029617],
[37.78766927921745, -122.40353382665603],
[37.78726600240594, -122.40398980218856],
[37.78678243828172, -122.40461475688903],
[37.78680761019093, -122.40471399862258],
[37.786684135270015, -122.40499563056915],
[37.78564518897711, -122.40629247862785],
[37.78467532035883, -122.40757960367884],
[37.78473351341448, -122.40764228983718],
[37.78480018561258, -122.40766742506932],
[37.78491233189406, -122.40769355565246],
[37.785157821816476, -122.40777800332691],
[37.7854919358761, -122.40784229252424],
[37.785929916960455, -122.40792803939368],
[37.786348817678984, -122.4080030574271],
[37.78648434923145, -122.40803725559203],
],
},

Tenderloin: {
    type: null,
coordinates: [
[37.78494031671343, -122.41953009317635],
[37.78033386315049, -122.41858059118508],
[37.7806603308854, -122.41586083124398],
[37.781232705901594, -122.4116873140168],
[37.78263500595947, -122.40989895115612],
[37.78333614601161, -122.40901818077086],
[37.78368883337232, -122.40856706674217],
[37.78404575868728, -122.40811863492249],
[37.7844954238353, -122.40758303130983],
[37.784648314661915, -122.40774748425005],
[37.785276303127716, -122.40784035573719],
[37.78638600134507, -122.4080663318467],
[37.78557095241662, -122.41451033900498],
[37.78516342458158, -122.41774307142018],
],
},

Presidio_Heights: {
    type: null,
coordinates: [
[37.78966581652064, -122.45946128582966],
[37.785742226560195, -122.45914210295689],
[37.78645234607146, -122.45353360390675],
[37.787332036899244, -122.44688440775883],
[37.79187022730669, -122.44780976986897],
],
},

Laurel_Heights: {
    type: null,
coordinates: [
[37.78140013844378, -122.45882330613915],
[37.78153793145509, -122.45572669583146],
[37.78195130894778, -122.45254157262627],
[37.78265934502547, -122.44596747833077],
[37.787187223744276, -122.44687942939584],
[37.78564404530481, -122.45911030250375],
[37.78351576283418, -122.45897216873948],
],
},

Richmond: {
    type: "beach",
coordinates: [
[37.77142435609424, -122.51089751153792],
[37.77241447377408, -122.51105307966078],
[37.772979492161184, -122.51113891034926],
[37.77339185745168, -122.51114963918532],
[37.77431622578794, -122.51109063058699],
[37.775134578936154, -122.5111818256935],
[37.775193940988345, -122.50988095432128],
[37.7755585925488, -122.50989838867987],
[37.77628789027394, -122.50964961379374],
[37.77628789027391, -122.50925063520278],
[37.77878737644, -122.50940352111662],
[37.77990141195978, -122.50952287941779],
[37.78068472469371, -122.50991045862044],
[37.78082304901648, -122.50674444615449],
[37.781061803608225, -122.50620213701929],
[37.78109704700646, -122.50557399719561],
[37.78130585717181, -122.50110878973807],
[37.781655639375835, -122.49235003619994],
[37.7834956785005, -122.49244927793349],
[37.78382637192018, -122.48491897612418],
[37.78758684556803, -122.4852388295492],
[37.78735261646725, -122.48229326113666],
[37.787139583925544, -122.47924576878155],
[37.7870415463555, -122.4776683784237],
[37.786875677089455, -122.4762626494428],
[37.78695384236502, -122.47457273203487],
[37.78716767034053, -122.47331196806932],
[37.786306525403624, -122.47327953010405],
[37.78665098458256, -122.47216591044868],
[37.786724115709276, -122.47053730666245],
[37.78692973037353, -122.46633596151675],
[37.788203680643086, -122.4663403201064],
[37.788484142826526, -122.4650169223694],
[37.78873280869026, -122.46376862648482],
[37.7867515398632, -122.46367368047665],
[37.785980281335554, -122.46361279642748],
[37.78509666605709, -122.46356264121438],
[37.785639859847564, -122.45924537434782],
[37.78342943364717, -122.45913917563463],
[37.78123590053237, -122.45897396832312],
[37.77437558849721, -122.45845160811747],
[37.773565707424844, -122.46509979843701],
[37.77323072784331, -122.47222005754317],
],
},

Sea_Cliff: {
    type: "beach",
coordinates: [
[37.79037082127909, -122.48567496670461],
[37.789917217536676, -122.48540138138509],
[37.789817593911906, -122.4849534524796],
[37.7896162266006, -122.48452698124623],
[37.789393662091804, -122.48407905234075],
[37.788905077652416, -122.48376925719953],
[37.78835130938752, -122.48404619528031],
[37.78770983387947, -122.48441801650503],
[37.78766604853491, -122.4853612320689],
[37.78687681490687, -122.48531249130195],
[37.78628259051386, -122.48526375053501],
[37.783936713805275, -122.48509653156674],
[37.783685183282955, -122.49045466316915],
[37.78356581032047, -122.49257310537672],
[37.78260111469632, -122.49253676982647],
[37.78173294411536, -122.49245959345302],
[37.78169576331201, -122.49334801232493],
[37.78354818921201, -122.49351137561774],
[37.78358926096205, -122.4934996409533],
[37.784112327576025, -122.49354054464078],
[37.78604027568947, -122.49362235201573],
[37.7875219740334, -122.4937430514214],
[37.78772970619074, -122.49218737019277],
[37.78803706392364, -122.49095891846395],
[37.788717485805435, -122.48998795880055],
[37.7893025164328, -122.48979483975148],
[37.78932159344046, -122.48962854279256],
[37.78927284107765, -122.48899017704701],
[37.78932159344046, -122.48854493035054],
[37.78944665370231, -122.48797093762136],
[37.78968829496454, -122.48681222332692],
],
},

Presidio_Terrace: {
    type: null,
coordinates: [
[37.78876341433245, -122.46372489745448],
[37.786757653802724, -122.46361564146001],
[37.78519604651414, -122.46347330573843],
[37.7857386733274, -122.45923259930306],
[37.787044421972844, -122.45933311514972],
[37.78833742937537, -122.45945508866848],
[37.78962758189226, -122.45956496991619],
[37.78907043868877, -122.46229939268495],
],
},

Lone_Mountain: {
    type: null,
coordinates: [
[37.77580348718547, -122.44662855460129],
[37.77904263937003, -122.44723486102562],
[37.78231556752323, -122.44783043861389],
[37.78181109237247, -122.45241027871008],
[37.78124725663482, -122.4569793899702],
[37.78112859740824, -122.45879119036303],
[37.77435737472745, -122.45832311309319],
[37.77509527502064, -122.45243023629399],
],
},

Sunset_District: {
    type: "beach",
coordinates: [
[37.763828383534, -122.51006469029846],
[37.7574160210727, -122.50939950246277],
[37.75100310274959, -122.50821933049622],
[37.75151420557925, -122.4967260648674],
[37.75205075371399, -122.48523279923859],
[37.75254806461762, -122.47319638628426],
[37.75551012332267, -122.47338883478108],
[37.75622556969462, -122.4737050001687],
[37.75635777674683, -122.47370089303615],
[37.75684624496422, -122.47346879813733],
[37.757507537389145, -122.47369401987555],
[37.757849844519214, -122.47372884668323],
[37.75818790894543, -122.47375294465485],
[37.7591039852002, -122.47382536429825],
[37.759252952546596, -122.47379518944683],
[37.75932346011839, -122.47370064157906],
[37.75935553308932, -122.47354205597105],
[37.759334592886745, -122.47338615257206],
[37.75924606082545, -122.47290804881516],
[37.75915752865818, -122.472384347505],
[37.75911332880149, -122.47216666947963],
[37.759079731591434, -122.47194899145427],
[37.75885137618525, -122.47144389796915],
[37.75868451592737, -122.47121775422158],
[37.75844204437349, -122.47096788968804],
[37.75831196160247, -122.4707555760807],
[37.7582686227172, -122.47063806179824],
[37.75826769497316, -122.47049908984366],
[37.758297647845, -122.47019968826237],
[37.75753810769046, -122.47014656271233],
[37.7571666285957, -122.47012830587948],
[37.756583275789964, -122.47009771979094],
[37.7564622126959, -122.47009127358353],
[37.75635175246773, -122.47007409854007],
[37.75596561150506, -122.47004619466054],
[37.75573639533466, -122.47003840734862],
[37.75547748953214, -122.47003062003671],
[37.75537532194139, -122.46940350175748],
[37.75513705598491, -122.46852477446112],
[37.75464204882118, -122.46793883751991],
[37.7547293272509, -122.46667040411296],
[37.75287651065786, -122.46651533890429],
[37.752988113056276, -122.46350154179993],
[37.7538634305434, -122.4639156078166],
[37.75473370111018, -122.4637364027268],
[37.7556887867551, -122.4636859436697],
[37.75776167515435, -122.463784514851],
[37.76231440363853, -122.46406748790207],
[37.76242254608742, -122.46185600656929],
[37.7626420111593, -122.46107213098469],
[37.76298870103298, -122.45984837312164],
[37.76438286561634, -122.45993353325787],
[37.76443746544355, -122.45881203461352],
[37.76447086143452, -122.45799094337883],
[37.76477513532435, -122.4577596028513],
[37.765219881841574, -122.45784778047266],
[37.76566462568434, -122.45786085624161],
[37.76596677432593, -122.45787627894344],
[37.76603992591672, -122.45813846487465],
[37.76614276208864, -122.4584811170763],
[37.76616502557094, -122.45884522695007],
[37.76499883414526, -122.48424038190308],
],
},
Parkside: {
    type: null,
coordinates: [
[37.75092615271982, -122.50812048584096],
[37.735526439461985, -122.50604499544069],
[37.735601909190336, -122.50143354016969],
[37.734078419799594, -122.49698204532227],
[37.73430282516301, -122.49132892083617],
[37.73736494511407, -122.49135232274288],
[37.73802205392133, -122.4900765633691],
[37.737739718600615, -122.48952451437069],
[37.73739762715323, -122.48932212910472],
[37.737055534125055, -122.48877642108486],
[37.7369353950936, -122.48761307368197],
[37.7368413867642, -122.48660224653814],
[37.73688771411315, -122.48567840835992],
[37.737137673388304, -122.48488331621441],
[37.73753509736186, -122.48297967912343],
[37.7372356759266, -122.4816776494741],
[37.73723020381832, -122.48109637208381],
[37.737250185634736, -122.4803112468084],
[37.7376804414628, -122.4710376919668],
[37.743291555917835, -122.47141128098582],
[37.74338002247367, -122.4692323671442],
[37.74518904636282, -122.46938367192315],
[37.74517306251336, -122.47044101281207],
[37.74709933644757, -122.47056198748626],
[37.74882196099242, -122.47069369099655],
[37.748823928121546, -122.47181863764791],
[37.75069121712602, -122.47194659311319],
[37.75254149323812, -122.47209600625057],
],
},

Western_Addition: {
    type: null,
coordinates: [
[37.77207748437409, -122.45397791588036],
[37.77419337558021, -122.43749305927483],
[37.77026472380958, -122.43663207018105],
[37.76911449888598, -122.43572950684754],
[37.769533246436225, -122.42773518287865],
[37.76960798467366, -122.42664151215283],
[37.77073434967142, -122.42518306100098],
[37.77208808518238, -122.42331758463112],
[37.7752110171202, -122.41943106376854],
[37.77629860603109, -122.41964832269875],
[37.77613112262111, -122.42116913521019],
[37.77897510904133, -122.42177665555207],
[37.78042674014092, -122.422080415723],
[37.78179354745479, -122.42237344705788],
[37.78331640284203, -122.42268357747523],
[37.78445766262005, -122.42292933487622],
[37.78458988544868, -122.42339503341643],
[37.784806899959335, -122.42376953685013],
[37.784993176863715, -122.42396030896631],
[37.785166734581125, -122.4242047252628],
[37.785234302742545, -122.42457252317396],
[37.78525947517901, -122.42475793087212],
[37.784464022040055, -122.43071042322842],
[37.783532891133625, -122.43814349496094],
[37.78321226565333, -122.43912853622166],
[37.78309514426326, -122.4401350351545],
[37.78220268377508, -122.44731530868737],
[37.78164939525839, -122.44748764061657],
[37.77928144320546, -122.44704842889038],
[37.77793949937028, -122.44677551412312],
[37.77643640855222, -122.44647041284767],
[37.77580887499294, -122.4463483723375],
[37.775791914552535, -122.44650528156487],
[37.774731879306756, -122.45462164604393],
],
},

Van_Ness: {
    type: null,
coordinates: [
[37.77665106264782, -122.41775751113892],
[37.77970416953175, -122.41860663826125],
[37.78239644823447, -122.41908675367492],
[37.785068490878345, -122.41961984271663],
[37.78642143397924, -122.41989175165551],
[37.7870936567793, -122.42003307054298],
[37.787804028268525, -122.42017438943046],
[37.790551652283504, -122.4207279303159],
[37.79042897848177, -122.4215947867419],
[37.79030630447637, -122.42249382967609],
[37.79022416841944, -122.4232029386844],
[37.79013779298362, -122.42393350536483],
[37.783430854105994, -122.4225826258574],
[37.7800729165453, -122.4218857284316],
[37.7783981305196, -122.42155337297277],
[37.77755436326345, -122.42137378419828],
[37.77671906649193, -122.4212102886779],
[37.776216536533816, -122.42112272961651],
],
},

Soma: {
    type: "beach",
coordinates: [
[37.77797999798706, -122.3871746634255],
[37.778569353565025, -122.38463192927938],
[37.78270319047524, -122.3844924544106],
[37.78476790243637, -122.38439589488607],
[37.7868325567171, -122.38438516605001],
[37.787474834735434, -122.38448708999258],
[37.788490173746425, -122.38518982875448],
[37.78931896831773, -122.38514154899221],
[37.78984252429177, -122.3852649306069],
[37.789732302289714, -122.38880544650655],
[37.79159757555706, -122.38957792270284],
[37.79710833512745, -122.39159494388204],
[37.795891234463014, -122.39309295761686],
[37.79456389895217, -122.39465534436803],
[37.79209568033846, -122.39778011787038],
[37.78754902490593, -122.40353613841634],
[37.778141117244836, -122.41540223109823],
[37.776521428727726, -122.41752117621999],
[37.774706657031174, -122.41982251155477],
[37.77174695715859, -122.4233952139626],
[37.77094128768178, -122.4224939917336],
[37.77043243927859, -122.42124944675069],
[37.76963523640666, -122.41142183292013],
[37.76915182196858, -122.40879326808553],
[37.76909457531269, -122.4082300041924],
[37.76907867345597, -122.40746557462316],
[37.76909616549818, -122.40681513893705],
[37.76877441727206, -122.40640073764425],
[37.76862016844969, -122.40616336214643],
[37.76850832460067, -122.4060869191895],
[37.76841185251918, -122.40593403327566],
[37.76816431094312, -122.40562155592542],
[37.767434137976934, -122.40497179079156],
[37.76730652261496, -122.40488294261795],
[37.76748422992799, -122.40171491849523],
[37.76752796096866, -122.40088276314836],
[37.76749681916966, -122.40063030022483],
[37.767342700201034, -122.40045025694471],
[37.76930871998354, -122.3979276393662],
[37.770182254196314, -122.39808052528005],
[37.77507979129839, -122.39187389361959],
[37.77643238723045, -122.39022165286642],
[37.7773100773076, -122.38848358142477],
],
},
Portrero_Hill: {
    type: null,
coordinates: [
[37.74963358051264, -122.40314195318669],
[37.749519056942994, -122.40233460827321],
[37.74954026502481, -122.40109810991734],
[37.74971841267221, -122.39805648489445],
[37.749896559890665, -122.39515433474034],
[37.750108639353485, -122.39237020178288],
[37.75169656501774, -122.39174859984368],
[37.75258886354996, -122.39166343970746],
[37.75437342833276, -122.39201883240193],
[37.7550584013634, -122.39234337969273],
[37.75616219130917, -122.39264512820691],
[37.75675649213968, -122.39257069690674],
[37.75726172361397, -122.39258746071309],
[37.75929533817777, -122.39256332083195],
[37.76071660859738, -122.39267396195382],
[37.76228204194977, -122.39298845096081],
[37.764267279739165, -122.39334049089402],
[37.76500570038596, -122.39366034431904],
[37.76537576933847, -122.39387399903089],
[37.76573735506535, -122.39413056908697],
[37.76634178304025, -122.39470808221549],
[37.76643323861876, -122.39479686752483],
[37.766651913235826, -122.39507340746522],
[37.767038373196705, -122.39558357200175],
[37.76781128705986, -122.39659317223877],
[37.76817653822861, -122.39701750608683],
[37.7685417875933, -122.39739892459065],
[37.76891127565927, -122.39766500810683],
[37.769280761879074, -122.39786671860665],
[37.76727949138452, -122.40042454017848],
[37.76741419653163, -122.40061807832271],
[37.76745635395778, -122.40069328494891],
[37.767468827250575, -122.40077385599315],
[37.7674725708945, -122.40091890482753],
[37.76745991538954, -122.40117949819714],
[37.76744725988244, -122.4014508204028],
[37.76731606653976, -122.40331126763076],
[37.76721667748883, -122.40488740070313],
[37.76588750174351, -122.40475764884204],
[37.76455830210911, -122.40481028719395],
[37.76328871524791, -122.40475194914788],
[37.7614084144183, -122.40588451190442],
[37.760478053365816, -122.40630897148102],
[37.75933430287456, -122.40600621713847],
[37.75839410817291, -122.40488807125539],
[37.75774998705926, -122.40411358340234],
[37.75703800139309, -122.40342492623776],
[37.75598670995471, -122.4030795918271],
[37.75466395946384, -122.40290591879338],
[37.751851909504914, -122.40258941812962],
[37.75058157436655, -122.40286032124013],
],
},
Mission: {
    type: null,
coordinates: [
[37.748237414835216, -122.42023745947446],
[37.74849615749931, -122.40864763432111],
[37.74862605870065, -122.40765488170948],
[37.749095291752994, -122.40614714496698],
[37.74983015245297, -122.40403289371099],
[37.75168211750272, -122.4033190908369],
[37.75209910810682, -122.4032411391374],
[37.7525160963609, -122.40316318743791],
[37.75448675158359, -122.40330769144859],
[37.75621984565977, -122.40355948381986],
[37.756900560402, -122.40377003722753],
[37.75744555180239, -122.40421662502851],
[37.758213198248555, -122.40534583502378],
[37.75915047951741, -122.40621755295362],
[37.75979723907914, -122.40644956403341],
[37.76044399298613, -122.40663865976896],
[37.76152119677685, -122.40636507444944],
[37.762496603998315, -122.40579108172025],
[37.763923064200675, -122.40520629729073],
[37.76455223922366, -122.40526524302479],
[37.76570879675105, -122.40529730380442],
[37.767139816600135, -122.40561891742911],
[37.767830634718955, -122.40583299123611],
[37.768499846194246, -122.40677612298097],
[37.76869411516353, -122.40768706821765],
[37.768752687290124, -122.4084478097495],
[37.76900208211545, -122.40929438196983],
[37.7690479325072, -122.40988346212472],
[37.76925836688852, -122.411147453123],
[37.769348477145776, -122.41183007531728],
[37.76947251108498, -122.41543094091978],
[37.76955414014515, -122.41722936206426],
[37.76987323469748, -122.41895268135633],
[37.76997341526132, -122.41981165879335],
[37.77002271040961, -122.42086375527944],
[37.77012050552582, -122.42134286486473],
[37.77023526223157, -122.42172541492548],
[37.77055806420417, -122.42242077761259],
[37.7625856622778, -122.42161879711713],
],
},

Mission_Dolores: {
    type: null,
coordinates: [
[37.751823687717675, -122.4273884496604],
[37.75855037692854, -122.42799462889776],
[37.76340839659375, -122.42857130383597],
[37.7652775147085, -122.42873089527235],
[37.7664044757477, -122.42882074927435],
[37.76721655555881, -122.42892803763495],
[37.767481592731535, -122.42894413088904],
[37.76886825173344, -122.42725970362768],
[37.771234417144086, -122.4240195951377],
[37.77133194563485, -122.42378356074438],
[37.771480358308715, -122.42361189936743],
[37.77146127669594, -122.42342950915442],
[37.771221696027176, -122.42318274592505],
[37.770717089955454, -122.42258193110571],
[37.76144062960402, -122.42167266224966],
[37.752163005729194, -122.42080630873785],
[37.751783393600405, -122.4273696741973],
],
},
})

