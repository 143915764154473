import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { Typography, Paper, Box, Container, makeStyles } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
const useStyles = makeStyles((theme) => ({
  btn: {
    width: "100%",  
    height: 30,
    color: "black", 
    color: "#a99136",
    marginTop: 5,
    display: "flex",
    overflow: "hidden",
   borderRadius: 6,
   color: "black",
   alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 5, 
    marginLeft: 0,
    marginRight: 5,
    border: "1px solid gray",
    
    padding: 5,
 
    cursor: "pointer",
    "&:hover": {
    
    borderColor: "#a99136",
    },
  },
    checkbox: {
      marginLeft: 2,
      '&$checked': {
        backgroundColor: "black"
      },
    }

}))

const GreenCheckbox = withStyles({
  root: {
    color: "#a99136",
    transform: "scale(.75)",
    height: 10,
    width: 10,
    '&$checked': {
      color: "#a99136",
      height: 10,
      width: 10,
    },
  },
  checked: {},
})((props) => <Checkbox color="#a99136" {...props} />);

const BtnComponent = (props) => {
  const classes = useStyles();
  const style = {
    width: "auto",  
    height: 30,
    color: "black", 
    color: "#a99136",
    marginTop: 5,

   borderRadius: "50%",
   color: "black",
    display: "flex",
    justifyContent: "center",
    marginBottom: 5, 
    marginLeft: 0,
    marginRight: 5,
    border: "1px solid gray",
    
    padding: 5,
    paddingLeft: 30,
    paddingRight: 30,
    cursor: "pointer",
    "&:hover": {
    backgroundColor: "#ebebeb",
    borderColor: "#a99136",
    },
  };


if (props.neighborhood === props.selected) {
  style.borderBottom = "3px solid #a99136";
  style.borderColor = "#a99136";
  style.fontWeight = 600;
};
return (
  <div style={{display: "flex", alignItems: "center", marginRight: 2}}> <div id={props.neighborhood}  className={classes.btn} key={props.neighborhood}>{props.neighborhood.split("_").join(" ")}<GreenCheckbox  checked={props.neighborhoodParams.includes(props.neighborhood)} className={classes.checkbox} type="checkbox" style={{marginRight: 2}} value={props.neighborhood} onChange={props.checked} ></GreenCheckbox></div></div>
);

}
export default BtnComponent;
