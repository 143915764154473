import React from "react";
import DehazeIcon from "@material-ui/icons/Dehaze";
import ClearIcon from '@material-ui/icons/Clear';

const ToggleIconComponent = (props) => {
let icon;
if (props.iconState === true) {
icon = <ClearIcon style={{transform: "scale(1.5)"}}
        aria-haspopup="true" onClick={props.click} />;
}
if (props.iconState === false) {
icon = <DehazeIcon style={{transform: "scale(1.5)"}}
aria-haspopup="true" onClick={props.click} />
}

return icon

}
export default ToggleIconComponent;