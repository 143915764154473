import React, { useState, useEffect } from "react";
import { Fade as Slideshow } from "react-slideshow-image";
import {
    makeStyles, Box, Container, Typography, Paper, Button, Chip, MenuList, MenuItem
    } from "@material-ui/core";
    import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
    import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
    const useStyles = makeStyles((theme) => ({
        serviceContainer: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
    
            paddingBottom: "2.5%",
            fontFamily: "sweet-sans-pro"
        },
        serviceSplit: {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            paddingTop: "2.5%",
            alignItems: "flex-start"
        },

        serviceEntry: {
            fontSize: "12px",
            fontWeight: "400",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            flexShrink: "1",
            padding: 0,
            textAlign: "left",
            width: "50%",
            alignItems: "flex-start"
        },
        soldBox: {
            width: "600px",
            height: "600px",
            position: "relative"
        },
        box: {
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%"
        },
        button: {
            backgroundColor: "#a99136",
            "&:hover": {
            backgroundColor: "#ead983",
            },
            color: "white",
            borderRadius: 0,
            fontWeight: 800,
            marginTop: 10,
            marginRight: 5,
        },
        arrow: {
            fontSize: "50px", 
            color: "#a99136",
            "&:hover": {
                color: "black"
            }
        },
        grayText: {
            fontWeight: 600,
            color: "gray", 
            marginTop: 10
        },
        serviceHeading: {
            fontSize: "24px",
            fontWeight: "600"
        }
    }));  
    const slideProperties = {
        duration: 5000,
        arrows: false,
        transitionDuration: 500,
        infinite: true,

      }
      
    export default ()  => 
{
    const classes = useStyles();
    const [num, setNum] = useState(0);
    
    const salesObj = {
        "5345Olm": {
          name: "5345-5375 Olmeda Avenue, Atascadero",
          price: "$2,750,000",
          units: 16,
          source: require("../assets/images/5345olm.jpeg")
        },
        "6597Trig": {
            name: "6597 Trigo Road, Isla Vista",
            price: "$2,775,000",
            units: 10,
            source: require("../assets/images/6597trig.jpeg")
        },
        "116Nop": {
            name: "116 N Nopal Street, Santa Barbara",
            price: "2,150,000",
            units: 5,
           source: require("../assets/images/116nop.PNG")
        }
    }
    
    const Slide = (props) => {
return (
<Box className={classes.box}>
            <Typography className={classes.serviceHeading} style={{ textAlign: "left"}}>
                    Recent Sales
                </Typography>
                <hr
        style={{
            color: "#a99136",
            width: "66%",
            backgroundColor: "#a99136",
            height: 2,
            borderRadius: 2,
            marginTop: 10,
            marginBottom: 20
        }}
    />
    
    <div style={{display: "flex", justifyContent: "flex-start", width: "100%"}}>
        
           <img style={{height: "500px", width: "600px"}} src={props.source} />
           
           </div>
           <div style={{display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center"}}>
         

           <Typography className={classes.grayText}>SOLD</Typography>
      
         
           <hr
        style={{
            color: "#a99136",
            width: "100%",
            backgroundColor: "#a99136",
            height: 2,
            borderRadius: 2,
            marginBottom: 10
        }}
    />   

    <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
           <Typography style={{fontWeight: 800}}>{props.name} </Typography>
           <Typography style={{fontWeight: 800}}>{props.units} Units</Typography>
           <Typography style={{fontWeight: 800}}>{props.price} </Typography>
           </div>
           
           
           </div>

            </Box>
)
    }
const slidesArr = [
    <Slide name={"5345 Olmeda Avenue, Atascadero"}
    price={"$2,750,000"}
    units={"16"}
    source={"https://townzphotos.s3.us-west-2.amazonaws.com/images/5345olm.jpeg"} />,
    <Slide name={"6597 Trigo Road, Isla Vista"}
    price={"$2,775,000"}
    units={"10"}
    source={"https://townzphotos.s3.us-west-2.amazonaws.com/images/6597trig.jpeg"} />,
    <Slide name={"116 Nopal St, Santa Barbara"}
    price={"$2,150,000"}
    units={"5"}
    source={"https://townzphotos.s3.us-west-2.amazonaws.com/images/116nop.PNG"} />,
]
    return (
        <Container className={classes.serviceContainer}>
            <Box className={classes.serviceSplit}>
            <Box className={classes.serviceEntry} style={{marginTop: 0}}>
                <Typography className={classes.serviceHeading}>
                    Multi Family Sales
                </Typography>
                <Typography>Sharif specializes in the sale of multi-family investments along the central coast. Since 2015, Sharif has been involved with all aspects of the local investment property market including underwriting, marketing, analysis, negotiations, and sales. After obtaining a bachelors degree in economics, Sharif went to work for Lee & Associates commercial real estate as an analyst, where he learned the ins and outs of the business after being involved in a multitutde of large-scale sale & lease transactions from Ventura up to Paso Robles. After two years, Sharif began his sales career, and in his first two years consummated close to $10 MM in sales. </Typography>
                <Button className={classes.button}>View Sale Listings</Button>
                <Typography className={classes.grayText}>Notable Sales</Typography>
                <Typography>5345-5375 Olmeda Avenue, Atascadero - 16 Units - $2,750,000 - 7/2/2021</Typography>
                <Typography>6597 Trigo Road, Goleta, CA 93117 - 10 Units, $2,775,000</Typography>
                <Typography>116 N Nopal Street, Santa Barbara, CA 93101, 5 Units - $2,150,000</Typography>
                <Typography>401 W Los Olivos St, Santa Barbara - 16 Units - $4,700,000 </Typography>
                <div style={{display: "flex", justifyContent: "flex-start", width: "100%"}}>
                
                <Button className={classes.button}>View Full Transaction List</Button>
                </div>
            </Box>
            <Slideshow className={classes.soldBox} {...slideProperties}>
        {
          slidesArr.map((show, index) => {
            return (
              slidesArr[index]
            )
             
          })
        }
      </Slideshow>
 
            </Box>
            <Box className={classes.serviceSplit}>
                
            <Box className={classes.serviceEntry}>
                <Typography className={classes.serviceHeading}>
                    Valuation
                </Typography>
                <Typography>With a background in economics and expensive experience evaluating complex investment properties, Sharif is well suited to unlock the highest potential value for your investment property. With specialized knowledge in market rents, local and statewide regulations, comparable sales on a citywide and micro-neighborhood level, I can put a price on your property that will unlock the highest value without spending excess time on the market and losing momentum. 
                </Typography>
                <Button className={classes.button}>Free Valuation</Button>
            </Box>
            </Box>
            <Box className={classes.serviceSplit}>
            <Box className={classes.serviceEntry}>
                <Typography className={classes.serviceHeading}>
                    Analytics
                </Typography>
                <Typography>
                    In addition to his experience in investment real estate, Sharif is fascinated by technical applications to the industry. He has built several tools to help himself and property owners analyze their properties value, more conveniently evaluate investment opportunities, and stay atop of extraneous factors, such as upcoming regulations and increased construction, that impact property values.
                </Typography>
                <Button className={classes.button}>NEIGHBORHOOD ANALYZER</Button>
            </Box>
            </Box>
        </Container>
        );
    };