import React, { useState } from 'react';
import {
    Toolbar, makeStyles, Box, Paper, Container, Typography, AppBar, Collapse, fade, Button, Hidden
} from "@material-ui/core";
import PropertyEntry from "../components/PropertyEntry";
import Footer from "../components/Footer";
import CompsShow from "../components/compsShow";
import FilterBtn from "../components/filterBtn";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CircularProgress from '@material-ui/core/CircularProgress';
import Map from "../components/NeighborhoodMap";

const useStyles = makeStyles((theme) => ({
    structure: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column-reverse",
        padding: 0,
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
      padding: 0,
  },   
    },
    loadMoreButton: {
      width: "80%",
      backgroundColor: "black",
      color: "white",
      marginTop: 10,
      marginBottom: 10, 
      height: 30,

      paddingLeft: 30,
      paddingRight: 30,
    },
    filters: {
        display: "none", 
        width: "100%", 
        direction: "ltr", 
  
        
        [theme.breakpoints.down("sm")]: {
          display: "flex",
          flexDirection: "column", 
          justifyContent: "center",
          alignItems: "center",
        },
        [theme.breakpoints.down("md")]: {
          display: "flex",
          flexDirection: "column", 
          justifyContent: "center",
          alignItems: "center",
        },
      },
      button3: {
        width: "auto",  
        height: 30,
        color: "black", 
        color: "#a99136",
        marginTop: 5,
        fontFamily: "sweet-sans-pro",
        borderRadius: 2,
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 5, 
        marginLeft: 0,
        marginRight: 5,
        border: "1px solid gray", 
        padding: 5,
        paddingLeft: 30,
        [theme.breakpoints.down("sm")]: {
            marginRight: 0,
          fontSize: 10,
        },
        [theme.breakpoints.down("md")]: {
          marginRight: 0,
        fontSize: 10,
      },
        paddingRight: 30,
        cursor: "pointer",
      },
    properties: {
        display: "flex", 
        zIndex: 10, 
        direction: "ltr", 
        boxShadow: "30px 0px 17px -20px rgba(105,105,105,0.75)", 
        maxHeight: "80vh", 
        width: "45%", 
        justifyContent: "center", 
        overflowY: "scroll", 
        flexFlow: "row wrap",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            padding: 0,
            justifyContent: "space-evenly"
        },
        [theme.breakpoints.down("md")]: {
          width: "100%",
          padding: 0,
          justifyContent: "space-evenly"
      }, 
    },
    map: {
        display: "flex", 
        height: "80vh", 
        flexDirection: "column", 
        width: "55%",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        [theme.breakpoints.down("md")]: {
          width: "100%",
      },
    }
}))

const MapAndProperties = (props) => {
    const classes = useStyles();
    return (
<div className={classes.structure}>
      <Paper className={classes.properties}>
       
      {props.distributeState.propertyHolder.length === 0 && !props.distributeState.propertiesLoading ? <Typography>Sorry No Properties Are Available to Display</Typography> : props.distributeState.propertiesLoading ? <CircularProgress /> : !props.distributeState.propertiesLoading ?
        props.distributeState.propertyHolder.map((entry, index) => {

          return (
          <PropertyEntry style={{direction: "ltr"}}
          Address={entry["Unparsed Address"]}
          type={"sale"}
          sf={entry.SqFt}
          originalPropertyId={entry["_id"]}
          id={index}
          latitude={entry["Geo Lat"]}
          longitude={entry["Geo Lon"]}
          fetchCompsHandler={props.fetchCompsHandler}
          beds={entry["Total Bedrooms"]}
          baths={entry["Total Full Baths"]}
          neighborhood={entry.neighborhood}
          price={entry["Current Price"]}
          image={entry.Photos.Uri1280[0]}
          imageArray={entry.Photos.Uri1280}
          />
        )}) : null }
        {/* {props.distributeState.neighborhoodParamString === "any" && props.distributeState.propertyHolder.length > 0 ? 
        <Button className={classes.loadMoreButton}>Load More Listings <KeyboardArrowDownIcon /></Button> : null} */}
        <Typography style={{fontSize: 10, marginTop: 10, marginBottom: 10}}>The data relating to real estate for sale on this web site comes from the Internet Data Exchange Program of the Santa Barbara Multiple Listing Service. Real estate listings held by brokerage firms other than Sharif Elseify are marked with the "MLS" logo and detailed information about them includes the name of the listing brokers. </Typography>
          <Footer />
          </Paper>
          <div className={classes.filters}><div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}><Typography style={{fontSize: 20, fontWeight: 400, marginLeft: 25}}>{props.region} Properties For Sale</Typography>
        </div>
        <Container style={{display: "flex", direction: "ltr", justifyContent: "center", alignItems: "center", height: 30}}>
       
<FilterBtn changeFilters={props.changeFilters} title={"Type"} filterType={"type"} transactionType={props.transactionType}/>
<FilterBtn initialMinPrice={props.initialMinPrice} initialMaxPrice={props.initialMaxPrice} changeFilters={props.changeFilters} title={"Price"} filterType={"price"} transactionType={props.transactionType}/>
<FilterBtn 
initialGuests={props.initialGuests} 
initialBeds={props.initialBeds} 
initialBedrooms={props.initialBedrooms} 
initialBathrooms={props.initialBathrooms} 
changeFilters={props.changeFilters} title={"Size"} filterType={"size"} transactionType={props.transactionType}/>
<Button className={classes.button3} >Save Search</Button>
  
</Container>
</div>
        <div className={classes.map}>
      {props.distributeState.mapCenter ?
       <Map
        id="myMap"
        compMode={props.distributeState.compMode}
        comps={props.comps}
        closeDialog={props.closeDialog}
        subject={props.distributeState.propertyHolder[props.subjectId]}
        mode={props.distributeState.trackedNeighborhoodGroupType}
        geoJSON={props.distributeState.geoJSON}
        center={props.distributeState.mapCenter}
        geoLoading={props.distributeState.geoLoading}
        neighborhoodParams={props.distributeState.neighborhoodParams}
        neighborhoodParamString={props.distributeState.neighborhoodParamString}
        lastSetter={props.distributeState.lastSetter}
        listings={props.distributeState.propertyHolder}
        shouldUpdateMap={props.distributeState.updateMap}
        setLoadStatus={props.updateMapLoadStatus}
        lastNeighborhood={props.distributeState.lastNeighborhood}
        callBack={props.updateChildComponentHandler}
        callBackAsGroup={props.updateChildComponentAsGroup}
        options={{
          center: { lat: props.distributeState.mapCenter[0], lng: -1 * props.distributeState.mapCenter[1] },
          zoom: 12
        }}
        onMapLoad={map => {
        }}
      /> : null}
      </div>
     
      </div>
    )
}
export default MapAndProperties;