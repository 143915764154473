import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import search from "../webRequests/search";


const defaultOptions = ["", "2"];

const useStyles = makeStyles((theme) => ({
  auto: {
    width: 300, 
    height: 50, 
    display: "flex", 
    justifyContent: "center", 
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: 150,
    }
  }
}))

export default function ControllableStates(props) {

  const [value, setValue] = React.useState(defaultOptions[0]);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState(["none"])
  const classes = useStyles();
    useEffect(() => {
    
        (async () => {
    
    if (inputValue !== null) {
    if (inputValue.length > 0) {
    const results = await search(inputValue)
  
    if (results.data) {
        setOptions(results.data)
    }
    }
}
    })();
},[inputValue]);

  return (
    <div style={{maxWidth: "100%", display: "flex", justifyContent: "center", maxHeight: 40}}>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          props.updateSearch(newValue);
        }}
        inputValue={inputValue}
        underlineStyle={{display: "none"}}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="controllable-states-demo"
        options={options}
        className={classes.auto}
        renderInput={(params) => <TextField {...params}
        InputProps={{...params.InputProps, disableUnderline: true}}
        label="Choose A City" />}
      />
    </div>
  );
}
