import React, { useState, useEffect } from "react";
import {
    makeStyles, Box, Container, Typography, Paper, Button, Chip, MenuList, MenuItem
    } from "@material-ui/core";
    const useStyles = makeStyles((theme) => ({
        text: {
            marginTop: 10,
            marginBottom: 10,
        },
        textDiv: {
            textAlign: "center",
    [theme.breakpoints.down('sm')]: {
      width: "80%",
      marginLeft: "10%",
      marginRight: "10%",
    },
        },
        contentWrapper: {
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginBottom: 50,
            marginTop: 50,
            [theme.breakpoints.down('sm')]: {
                width: "100%",
                flexDirection: "column",
              },
        },
        aboutHolder: {
            minWidth: "25%",
            maxWidth: "25%",
            [theme.breakpoints.down('sm')]: {
                maxWidth: "100%",
                width: "100%",
              },
        },
        flex: {
            display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: "50%",
    [theme.breakpoints.down('sm')]: {
      maxWidth: "100%",
      width: "100%",
    },
        },
        infoBox2: {
            height: "100%",
            marginTop: 10,
            backgroundColor: "rgba(146, 129, 122, .15)",
            color: "black",
            display: "flex",
            marginRight: 50,
            flexDirection: "column",
            justifyContent: "center",
            marginBottom: 50,
            [theme.breakpoints.down('sm')]: {
                width: "80%",
                marginLeft: "10%",
                marginRight: "10%",
              },
          },
    }));  

    export default ()  => 
{
    const classes = useStyles();
   
    return (
        <div className={classes.contentWrapper}>
       
    <div className={classes.flex}>
    <div className={classes.textDiv}>
        <Typography style= {{ fontFamily: "sweet-sans-pro", fontWeight: 800 }} className={classes.text}><h1>SB APARTMENT GROUP</h1></Typography>
        <Typography style= {{ fontFamily: "sweet-sans-pro" }} className={classes.text}>SB Apt was created to fill a much needed gap in the local residential income sector. 
        With rapid changes in rents, interest rates, and regulations occurring almost daily, property values are a quickly moving target.
        To rely on a broker to guide you, you need someone who can take into account all those variables and work to earn you the highest price possible for your asset.</Typography>
        <Typography style= {{ fontFamily: "sweet-sans-pro", fontWeight: 800 }} className={classes.text}>
        I built SBAPT with that in mind. 
        </Typography>
        <Typography style= {{ fontFamily: "sweet-sans-pro" }} className={classes.text}>
        Over the past 5 years, my passion and business has been the sale of real estate investments on the central coast. I have been a broker specializing in the sale of multi-family and have worked on various large-scale transactions in residential and commercial real estate. My passion is analysis. My goal is to understand the best possible price for your asset, and achieve that for you. 
        </Typography>
        </div>
        </div>
        </div>
        );
    };
