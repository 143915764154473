import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import CssBaseline from '@material-ui/core/CssBaseline';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { NavLink } from "react-router-dom";
import Menu from "./MenuStructure";
import {
    Toolbar, makeStyles, Box, Container, Typography, AppBar, Collapse, fade, Button, Hidden
} from "@material-ui/core";
import { theme } from "./theme";
const useStyles = makeStyles((theme) => ({

      barApp: {
    maxHeight: 100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    maxWidth: "100%",
    padding: 0,
      },

      login: {
          fontSize: 12,
      },
  
    toolbar: {
        maxHeight: "75",
        maxWidth: "100%",
      },
      smallTypeClass: {
        fontWeight: 800,
        marginRight: 5,
        fontSize: 9, 
        color: "#a99136"
      },
      holder: {
          position: "fixed",
          width: "100%",
          padding: 0,
          margin: 0,
          padding: 0,
          zIndex: 15,
          maxWidth: "100%",
          [theme.breakpoints.down('sm')]: {
            width: "100%",
            left: "0",
          },
      },
      vanish: {
          marginTop: 64,
          marginLeft: 0,
          marginRight: 0,
          padding: 0,
          backgroundColor: "transparent",
          maxWidth: "100%",
          [theme.breakpoints.down('sm')]: {
      
          },
      },
      headerCont: {
        width: "100%", 
        height: "100%",
        display: "flex", 
        padding: 0,
        position: "relative",
        marginLeft: "10%", 
        justifyContent: "flex-start",
        [theme.breakpoints.down('sm')]: {
          alignItems: "center",
          marginLeft: "5%",
          marginRight: "5%"
        },
      },
      stripeClass: {
        width: "100%", 
        display: "flex",
        boxShadow: "0px 2px 18px -5px #DBDBDB", 
        backgroundColor: "#f7f7f7", 
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
          position: "absolute", 
          bottom: 0,
        },
        [theme.breakpoints.up('lg')]: {
          position: "absolute", 
          top: 0,

          },
      },
      hide: {
        [theme.breakpoints.down('sm')]: {
          display: "none"

          },
      },
      alert: {
          position: "absolute",
          display: "flex",
          paddingLeft: "10%",
          justifyContent: "flex-start",
          left: 0,
          right: 0,
          maxHeight: 75,
          maxWidth: "100%",
          fontWeight: 800,
          fontFamily: "sweet-sans-pro",
          backgroundColor: "rgba(255, 255, 255, .85)",
          borderTop: "5px solid #a99136",
      },
      button: {
          backgroundColor: "#a99136",
          "&:hover": {
          backgroundColor: "#ead983",
          },
          color: "white",
          borderRadius: 0,
          fontWeight: 800,
          marginLeft: 50
      },
      hidetoolBar: {
        [theme.breakpoints.down('sm')]: {
          display: "none"

          },
      },
      hideBar: {
        height: 50,
        maxWidth: "100%",
        [theme.breakpoints.down('sm')]: {
          display: "none"

          },
      }
  
}));
function HideOnScroll(props) {
    const { children, window } = props;
    let trigger = useScrollTrigger({ threshold: children.key, target: window ? window() : undefined });
    return (
      <Collapse appear={false} direction="down" in={!trigger}>
        {children}
      </Collapse>
    );
  }
  HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
  };
export const Header = (props, menuProps) =>
{
const classes = useStyles();
    return (
        <Container className={classes.holder}>
          
        <AppBar color="white" className={classes.barApp} style={{margin: 0, padding: 0}}>
        <HideOnScroll {...props}>
        <div className={classes.stripeClass}><Typography className={classes.smallTypeClass}>S A N T A </Typography> 
      <Typography className={classes.smallTypeClass}> B A R B A R A </Typography> 
      <Typography className={classes.smallTypeClass}> A P A R T M E N T </Typography>
      <Typography className={classes.smallTypeClass}> G R O U P </Typography> <Typography className={classes.smallTypeClass}>{'\u00A9'}</Typography></div>
      </HideOnScroll>
        <HideOnScroll className={classes.hide} {...props}>
        <Toolbar className={classes.hidetoolBar} key="0">
        
        </Toolbar>
        </HideOnScroll>
        <Container className={classes.headerCont}>

            <Menu />
        </Container>
    </AppBar>
    <Container className={classes.vanish}>
    <HideOnScroll className={classes.hide}><Container className={classes.hideBar} key="0"></Container></HideOnScroll>


    </Container>
    </Container>
    )
}
