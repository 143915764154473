import React from "react";
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Button, Popper, makeStyles, Typography } from "@material-ui/core";
import EventIcon from '@material-ui/icons/Event';
import { addDays } from 'date-fns';
import { useState, useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
    button2: {
        width: "100%",  
        height: 30,
        color: "black", 
        color: "#a99136",
        marginTop: 5,
        fontFamily: "sweet-sans-pro",
        color: "black",
        color: "gray",
        display: "flex",
        borderRadius: 0,
        justifyContent: "space-between",
        marginBottom: 5, 
        marginLeft: 0,
        marginRight: 5,
        borderBottom: "1px solid gray",
        padding: 5,
        paddingLeft: 5,
        paddingRight: 5,
        cursor: "pointer",   
      },   
}))

const BasicDatePicker = (props) => {
  console.log(props.endDate);
    let startDate = new Date(`${props.checkin}T00:00:00`);
    let endDate = new Date(`${props.checkout}T00:00:00`);
    if (!props.checkin) {
        startDate = new Date();
    }
    if (!props.checkout) {
        endDate = addDays(new Date(), 3);
    }
    const [state, setState] = useState([
        {
          startDate: startDate,
          endDate: endDate,
          key: 'selection'
        }
      ]);

useEffect(() => {
props.callBack({
  checkin: state[0].startDate.toISOString().split('T')[0],
  checkout: state[0].endDate.toISOString().split('T')[0]
})
},[state])
const classes = useStyles();
const [open, SetOpen] = useState(false);
const [anchorEl, SetAnchorEl] = useState(null);

        const handleClick = (event) => {
            console.log(state);
            SetOpen(!open);
            SetAnchorEl(anchorEl ? null : event.currentTarget);
        }
return (
<div style={{marginLeft: 50}}>
<Button className={classes.button2} onClick={handleClick}><EventIcon  style={{color: "black",  transform: "scale(.75)"}}/><Typography style={{fontSize: 10}}>{state[0].startDate.toDateString()} / {state[0].endDate.toDateString()}</Typography></Button>
<Popper style={{zIndex: 1000 }} key={"02"} open={open} anchorEl={anchorEl}>
<DateRange
  editableDateInputs={true}
  onChange={item => setState([item.selection])}
  moveRangeOnFirstSelection={false}
  ranges={state}
/>
</Popper>
</div>
)
}
export default BasicDatePicker;