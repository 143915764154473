import React, { useEffect, useState } from "react";
import { Typography, Box, Container, makeStyles } from "@material-ui/core"
import { useHistory, useParams } from "react-router-dom";
import GeoLocate from "../webRequests/geoLocate";
import LatLong from "../util/latLong";
import NeighborhoodShapes from "../webRequests/neighborhoodShapes";
import Insights from "../webRequests/insights";
import Map from "../components/NeighborhoodMap";

const useStyles = makeStyles((theme) => ({

}))

const NeighborhoodAnalyzer = (props) => {
const classes = useStyles();
const { region, state } = useParams();
const history = useHistory();
const [coordinates, SetCoordinates] = useState(null);
const [neighborhoods, SetNeighborhoods] = useState(null);
const [insights, SetInsights] = useState(null);
const [neighborhoodsLoading, SetNeighborhoodsLoading] = useState(true);
const [insightsLoading, SetInsightsLoading] = useState(true);

const LoadNeighborhoods = async (region, state) => {
    const neighbs = await NeighborhoodShapes(region, state);
    if (neighbs) {
        SetNeighborhoods(neighbs.data);
    }

    SetNeighborhoodsLoading(false);
}

const LoadInsights = async (region, state) => {
const ins = await Insights(region, state);
if (ins) {
SetInsights(ins.data)
}
SetInsightsLoading(false);

}

useEffect(() => {
const coords = LatLong[`${region}, ${state}`];
SetCoordinates(coords);
LoadNeighborhoods(region, state);
LoadInsights(region, state);
}, [region, state])
console.log(coordinates, neighborhoods, insights);
    if (neighborhoodsLoading || insightsLoading ) return <Typography>Loading</Typography>
    return (
        <div style={{width: "100%", height: "1000px"}}>
        <Map 
        id="myMap"
        geoJSON={neighborhoods}
        center={coordinates}
        listings={[]}
        geoLoading={neighborhoodsLoading}
        options={{
            center: { lat: coordinates[0], lng: -1 * coordinates[1] },
            zoom: 12
          }}
          onMapLoad={map => {
          }}
        // neighborhoodParams={props.distributeState.neighborhoodParams}
        // neighborhoodParamString={props.distributeState.neighborhoodParamString}
        // lastSetter={props.distributeState.lastSetter}
        // listings={props.distributeState.propertyHolder}
        // shouldUpdateMap={props.distributeState.updateMap}
        // setLoadStatus={props.updateMapLoadStatus}
        // lastNeighborhood={props.distributeState.lastNeighborhood}
        // callBack={props.updateChildComponentHandler}
        // callBackAsGroup={props.updateChildComponentAsGroup}
        />
    </div>
    )

}
export default NeighborhoodAnalyzer;