import React, { useState } from "react";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import TextField from '@material-ui/core/TextField';
import { Typography, Button, Paper, Popper, makeStyles } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { AirlineSeatIndividualSuiteSharp } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
    popper: { 
        fontFamily: "sweet-sans-pro",
        minHeight: 200,
        borderRadius: 2,
        color: "black",
        display: "flex",
        flexDirection: "column",
        maxHeight: 500,
    
    
        marginBottom: 5, 
        marginLeft: 0,
        backgroundColor: "white",
        border: "1px solid gray",
        paddingTop: 5,
        paddingBottom: 5,
        margin: 0,
        zIndex: 1000,
        cursor: "pointer",           
      },
      contentClass: {
        display: "flex",
        flexDirection: "column",
        padding: 10,
        alignItems: "flex-start",
        fontWeight: "800",
        color: "black",
        textAlign: "left",
        width: "100%"
      },
      button5: {
        backgroundColor: "white",
        color: "#a99136",
        fontSize: 12,
        fontWeight: 400,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20,
        display: "flex",
        marginRight: 5,
        fontWeight: 800,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 0,
        margin: 0,
        "&:hover": {
            backgroundColor: "white"
        }
      },

}))

const HomePageButton = (props) => {
const classes = useStyles();
const [open, SetOpen] = useState(false);
const [propertyTypes, SetPropertyTypes] = useState(null);
const [price, SetPrice] = useState(null);
const [units, SetUnits] = useState(null);
const propChoices = ["residential", "multi-family", "commercial"];
const [anchorEl, SetAnchorEl] = useState(null);


const handleClick = (event) => {
    SetOpen(!open);
    SetAnchorEl(anchorEl ? null : event.currentTarget);
}

let options;
let content;

if (props.filterType === "type") {

    content = <div className={classes.contentClass}>
    <div style={{display: "flex", justifyContent: "center", marginBottom: 5, alignItems: "center", }}>
        <Checkbox checked={props.propertyType === "Res"} id={"Res"} onChange={() => props.setValues("type", "Res")} />Residential</div>
        <div style={{display: "flex", justifyContent: "center", marginBottom: 5, alignItems: "center"}}>
        <Checkbox id={"Res Inc"} checked={props.propertyType === "Res Inc"} onChange={() => props.setValues("type", "Res Inc")}/>Residential Income</div>
        <div style={{display: "flex", justifyContent: "center", marginBottom: 5, alignItems: "center"}}>
        <Checkbox id={"Comm/Ofc"} checked={props.propertyType === "Comm/Ofc"} onChange={() => props.setValues("type", "Comm/Ofc")} />Commercial / Office</div>
        <div style={{display: "flex", justifyContent: "center", marginBottom: 5, alignItems: "center"}}>
        <Checkbox id={"Rent"} checked={props.propertyType === "Rent"} onChange={() => props.setValues("type", "Rent")}/>Rent</div>
     
        </div>
}

if (props.filterType === "location") {

    content = <div className={classes.contentClass}>
    <div style={{display: "flex", justifyContent: "center", marginBottom: 5, alignItems: "center", }}>
        <Checkbox onChange={() => props.setValues("location", "Santa Barbara")} id={"Res"} checked={props.location === "Santa Barbara"} />Santa Barbara</div>
        <div style={{display: "flex", justifyContent: "center", marginBottom: 5, alignItems: "center"}}>
        <Checkbox onChange={() => props.setValues("location", "Goleta")} id={"Res Inc"} checked={props.location === "Goleta"} />Goleta</div>
        <div style={{display: "flex", justifyContent: "center", marginBottom: 5, alignItems: "center"}}>
        <Checkbox id={"Comm/Ofc"} onChange={() => props.setValues("location", "Carpinteria")} checked={props.location === "Carpinteria"}  />Carpinteria</div>
        <div style={{display: "flex", justifyContent: "center", marginBottom: 5, alignItems: "center"}}>
        <Checkbox id={"Rent"} onChange={() => props.setValues("location", "Santa Ynez Valley")} checked={props.location === "Santa Ynez Valley"}/>Santa Ynez Valley</div>
     
        </div>
}

if (props.filterType === "price") {

    content = <div className={classes.contentClass}>
    <div style={{display: "flex", justifyContent: "center", marginBottom: 5, alignItems: "center", }}>
    <TextField  onChange={(event) => props.setValues("minPrice", event.target.value)} style={{height: 10, width: "80%", transform: "scale(.75)", marginBottom: 50}} id="outlined-basic" label="Min Price" variant="outlined" />
    </div>
    <div style={{display: "flex", justifyContent: "center", marginBottom: 5, alignItems: "center", }}>
                    <TextField defaultValue={props.maxPrice} defaultValue={props.maxPrice} onChange={(event) => props.setValues("maxPrice", event.target.value)}style={{height: 10, width: "80%", transform: "scale(.75)", marginBottom: 50}} id="outlined-basic" label="Max Price" variant="outlined" />
                    </div>
        </div>
}

if (props.filterType === "units") {

    content = <div className={classes.contentClass}>
    <div style={{display: "flex", justifyContent: "center", marginBottom: 5, alignItems: "center", }}>
    <TextField  defaultValue={props.minUnits} onChange={(event) => props.setValues("minUnits", event.target.value)} style={{height: 10, width: "80%", transform: "scale(.75)", marginBottom: 50}} id="outlined-basic" label="Min Units" variant="outlined" />
    </div>
    <div style={{display: "flex", justifyContent: "center", marginBottom: 5, alignItems: "center", }}>
                    <TextField defaultValue={props.maxUnits} onChange={(event) => props.setValues("maxUnits", event.target.value)} style={{height: 10, width: "80%", transform: "scale(.75)", marginBottom: 50}} id="outlined-basic" label="Max Units" variant="outlined" />
                    </div>
        </div>
}
return (
    <div style={{ padding: 0}}>
    <Button key={"01"} onClick={handleClick} className={classes.button5}>
        {props.title}
        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </Button>
    <Popper style={{zIndex: 1000, padding: 0, margin: 0 }} key={"02"} open={open} anchorEl={anchorEl}>
        <div className={classes.popper}>{content}</div>
    </Popper>
    </div>
)
}

export default HomePageButton;


