import SETTINGS from "../settings";
import axios from "axios";

const getPosts = async (postId) => {
    let baseUrl = SETTINGS.DOMAINREQUIREMENTS.BACKEND.baseUrl;
    const posts = await axios.get(`${baseUrl}/posts/${postId}`);
    return posts;
}

const getAll = async (postId) => {
    let baseUrl = SETTINGS.DOMAINREQUIREMENTS.BACKEND.baseUrl;
    const posts = await axios.get(`${baseUrl}/posts`);
    return posts;
}

export { getPosts, getAll };