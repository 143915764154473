import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { Container, Paper, Button, Toast } from "@material-ui/core";
import PropertyEntry from "../components/PropertyEntry";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import MenuSmall from "../components/MenuStructureSmall";
import BtnComponent from "../components/BtnComponent";
import geoLocate from "../webRequests/geoLocate";
import NeighborhoodsHolder from "../components/NeighborhoodsHolder";
import queryString from "query-string";
import BasicDatePicker from "../components/DatePicker";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MediaQuery from "react-responsive";
import LoginNav from "../components/loginNav";
import GetInsights from "../webRequests/insights";
import MapAndProperties from "../components/MapAndProps";
import LatLongs from "../util/latLong";
import FilterBtn from "../components/filterBtn";
import Footer from "../components/Footer";
import MobileStyles from "../components/mobileProperties.module.css"
import getAvailableProperties from "../webRequests/availableProperties";
import getSoldProperties from "../webRequests/soldProperties";
import Select from '@material-ui/core/Select';
import getNeighborhoodShapes from "../webRequests/neighborhoodShapes";
import SETTINGS from "../settings";
import Map from "../components/Map";
import Login from "../components/Login";
import { MAPCOLORS } from "../mapData";
import CircularProgress from '@material-ui/core/CircularProgress';

const classes = {
  root: {
    display: 'flex',

  },
  content: {
    flexGrow: 1,
    marginLeft: 5,
  },
  button2: {
    width: "auto",
    height: 30,
    color: "black",
    color: "#a99136",
    marginTop: 5,
    fontFamily: "sweet-sans-pro",
    borderRadius: 2,
    color: "black",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 5,
    marginLeft: 0,
    marginRight: 5,
    border: "1px solid gray",
    padding: 5,
    paddingLeft: 30,
    paddingRight: 30,
    cursor: "pointer",

  },
  stats: {
    fontWeight: 800,
    fontFamily: "sweet-sans-pro",
    fontSize: 14,
  },
  stats2: {
    fontWeight: 600,
    fontFamily: "sweet-sans-pro",
    fontSize: 14,

  },

  button3: {
    width: "auto",
    height: 30,
    color: "black",
    color: "#a99136",
    marginTop: 5,
    fontFamily: "sweet-sans-pro",
    borderRadius: 2,
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 5,
    marginLeft: 0,
    marginRight: 5,
    border: "1px solid gray",
    padding: 5,
    paddingLeft: 30,
    paddingRight: 30,
    cursor: "pointer",
  },
  structure: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    "@media screen and (maxWidth: 600px)": {
      flexDirection: "column",
    }
  },
  button: {
    backgroundColor: "#a99136",
    "&:hover": {
      backgroundColor: "#ead983",
    },
    color: "white",
    borderRadius: 0,
    fontWeight: 800,
    marginLeft: 60,
    borderRadius: 2,
    height: 30,
  },
}
class Properties extends Component {
  constructor(props) {
    super(props);
    this.state = {
      geoJSON: null,
      currentNeighborhood: null,
      clickedProperty: null,
      propertiesLoading: true,
      areaNeighborhoods: null,
      compMode: false,
      cityInsights: null,
      subjectId: null,
      neighborhoodTypeSelected: "any",
      neighborhoodParams: null,
      neighborhoodParamString: null,
      requestMulti: false,
      trackedNeighborhoodGroupType: "any",
      geoLoading: true,
      compsHolder: [],
      mapChangeType: null,
      noResultsQuery: false,
      propertiesLoading: true,
      loggingIn: null,
      lastNeighborhood: null,
      propertyHolder: [],
      updateMap: false,
      updateMapCenter: false,
      lastSetter: null,
      pagination: null,
      mapCenter: null,
    };
  }
  clickedPropertyHandler = (event, lat) => {
    this.setState({ clickedProperty: lat })

  }
  checkedNeighborhoodsHandler = (event) => {
    console.log(event.target.value);
    const deleteCheckedListings = () => {

      const newArr = [];
      const oldArr = this.state.propertyHolder;
      oldArr.map((property) => {
        if (property.neighborhoodOrigin !== event.target.value.split(" ").join("_")) {
          newArr.push(property);
        }
      })
      this.setState({ propertyHolder: newArr });
    }

    this.setState({ lastSetter: "checkbox", neighborhoodTypeSelected: "custom" })
    const query = this.props.location.search;
    const currentNeighborhoodGroup = this.props.match.params.neighborhood;
    const oldPath = this.props.history.location.pathname;

    if (event.target.checked) {
      let newGroup = ""
      if (currentNeighborhoodGroup !== "any") {
        newGroup = `${currentNeighborhoodGroup}&${event.target.value}`;
        this.setState({ mapChangeType: "add" });
      }

      if (currentNeighborhoodGroup === "any") {
        newGroup = `${event.target.value}`
        this.setState({ mapChangeType: "add" });
      }
      const newPath = oldPath.replace(currentNeighborhoodGroup, newGroup)
      this.props.history.push(`${newPath}${query}`)
    }
    if (!event.target.checked) {

      let newGroup;

      if (event.target.value === currentNeighborhoodGroup) {
        newGroup = "any"
        this.setState({ mapChangeType: "add" });
      }
      if (event.target.value !== currentNeighborhoodGroup) {
        newGroup = currentNeighborhoodGroup.replace(`&${event.target.value}`, "")
        deleteCheckedListings()
        this.setState({ mapChangeType: "delete" });

        if (currentNeighborhoodGroup.split("&")[0] === event.target.value && currentNeighborhoodGroup !== event.target.value) {

          newGroup = currentNeighborhoodGroup.replace(`${event.target.value}&`, "")
          deleteCheckedListings()
          this.setState({ mapChangeType: "delete" });
        }
      }

      deleteCheckedListings();
      const newPath = oldPath.replace(currentNeighborhoodGroup, newGroup)
      this.props.history.push(`${newPath}${query}`)
    }
    this.setState({ updateMap: true })
  }
  neighborhoodTypeSelectorHandler = (event) => {
    const areaNeighborhoods = this.state.areaNeighborhoods;
    this.setState({ neighborhoodTypeSelected: event.target.value });
    const neighborhoods = [];
    areaNeighborhoods.map((neighborhood) => {
      if (neighborhood.properties.neighborhoodTypes.includes(event.target.value)) {
        neighborhoods.push(neighborhood.properties.NAME);
      }
    })
    this.updateChildComponentAsGroup(neighborhoods);
    this.setState({ neighborhoodParams: neighborhoods, trackedNeighborhoodGroupType: event.target.value });
  }
  searchHandler = (searchResult) => {
    if (searchResult !== null) {
      if (searchResult.length > 0) {
        const arr = searchResult.split(", ");
        const currentCity = this.props.match.params.region;
        const currentState = this.props.match.params.state;
        const query = this.props.location.search;
        const currentNeighborhoods = this.props.match.params.neighborhood;
        const oldPath = this.props.history.location.pathname;
        const newCity = oldPath.replace(currentCity, arr[0].split(" ").join("_"));
        const newState = newCity.replace(currentState, arr[1].split(" ").join("_"));
        const newNeighborhood = newState.replace(currentNeighborhoods, "any");
        this.props.history.push(`${newNeighborhood}${query}`);
      }
    }
  }

  updatePaginationHandler = () => {

  }

  updateChildComponentHandler = (dataFromChild, addorRemove) => {
    this.setState({ lastSetter: "map" })
    this.setState({ neighborhoodTypeSelected: "custom" })
    const deleteListings = () => {
      const newArr = [];
      const oldArr = this.state.propertyHolder;
      oldArr.map((property) => {
        if (property.neighborhoodOrigin !== dataFromChild.split(" ").join("_")) {
          newArr.push(property);
        }
      })
      this.setState({ propertyHolder: newArr });
    }
    const currentNeighborhoodGroup = this.props.match.params.neighborhood;
    const query = this.props.location.search;
    const oldPath = this.props.history.location.pathname;
    if (addorRemove === "add") {
      let newGroup = ""
      if (currentNeighborhoodGroup !== "any") {
        newGroup = `${currentNeighborhoodGroup}&${dataFromChild}`;
        this.setState({ mapChangeType: "add" });
      }

      if (currentNeighborhoodGroup === "any") {
        this.setState({ mapChangeType: "add" });
        newGroup = `${dataFromChild}`
      }
      const newPath = oldPath.replace(currentNeighborhoodGroup, newGroup)
      const newerPath = `${newPath}${query}`
      this.props.history.push(newerPath);
    }
    if (addorRemove === "remove") {
      let newGroup;
      if (currentNeighborhoodGroup !== dataFromChild) {
        newGroup = currentNeighborhoodGroup.replace(`&${dataFromChild}`, "")
        this.setState({ mapChangeType: "delete" });
      }

      if (currentNeighborhoodGroup === dataFromChild) {
        newGroup = "any";
        this.setState({ mapChangeType: "add" });
      }

      if (currentNeighborhoodGroup.split("&")[0] === dataFromChild && currentNeighborhoodGroup !== dataFromChild) {
        newGroup = currentNeighborhoodGroup.replace(`${dataFromChild}&`, "")
        this.setState({ mapChangeType: "delete" });
      }

      const newPath = oldPath.replace(currentNeighborhoodGroup, newGroup);
      const newerPath = `${newPath}${query}`;
      this.props.history.push(newerPath);
      deleteListings();

      this.setState({ updateMap: true })
    }

  }

  closeDialogHandler = () => {
    this.setState({ compMode: false });
    console.log("executed")
  }
  updateMapLoadStatus = () => {
    this.setState({ updateMap: false });
  }
  updateChildComponentAsGroup = (dataFromChild) => {
    this.setState({ lastSetter: "checkbox" })
    this.setState({ mapChangeType: "add" });
    const currentNeighborhoodGroup = this.props.match.params.neighborhood;
    const query = this.props.location.search;
    const oldPath = this.props.history.location.pathname;
    let newGroup = ""
    newGroup = newGroup + dataFromChild.join("&");
    if (!newGroup) {
      newGroup = "any"
    }
    this.setState({ requestMulti: true });
    const newPath = oldPath.replace(currentNeighborhoodGroup, newGroup);
    this.props.history.push(`${newPath}${query}`);
  }
  changeFilterHandler = (filterType, filterObject) => {
    const oldPath = this.props.history.location.pathname;
    const { minPrice, maxPrice, minSF, maxSF, minUnits, maxUnits, minBedrooms, maxBedrooms,
      minBathrooms, maxBathrooms, type } = queryString.parse(this.props.location.search);

    const queryObject = {
      minPrice: minPrice,
      maxPrice: maxPrice,
      minSF: minSF,
      maxSF: maxSF,
      minUnits: minUnits,
      maxUnits: maxUnits,
      minBedrooms: minBedrooms,
      maxBedrooms: maxBedrooms,
      type: type

    }
    Object.entries(filterObject).map((param) => {
      queryObject[param[0]] = param[1];
    });

    let query = "?";
    Object.entries(queryObject).map((param) => {
      if (param[1] && param[1] !== 0) {
        if (query !== "?") {
          query = query + `&${param[0]}=${param[1]}`
        }
        if (query === "?") {
          query = query + `${param[0]}=${param[1]}`
        }
      }
    })
    this.props.history.push(`${oldPath}${query}`);
  }

  fetchComps = async (transactionType, baseLat, baseLng, subjectId, originalPropertyId) => {
    try {
      this.setState({ compMode: true });
      this.setState({ subjectId: subjectId })
      const properties = await getSoldProperties(transactionType, baseLat, baseLng, originalPropertyId)
      console.log(properties.data)
      this.setState({ compsHolder: properties.data })
    }
    catch (error) {
      return "Sorry comps not found"
    }
  }
  fetchProperties = async (transactionType, neighborhoods, region, state, filters, fetchType) => {
    this.setState({ propertiesLoading: true });
    let arr;
    if (this.state.propertyHolder && this.state.lastNeighborhood !== "any") {
      arr = this.state.propertyHolder;
    }
    if (!this.state.propertyHolder || this.state.lastNeighborhood === "any" || fetchType === "multi" || neighborhoods === "any") {
      arr = [];
    }
    if (fetchType === "regionChanged" || fetchType === "filtersChanged" || fetchType === "firstLoad") {
      arr = [];
    }
    try {
      const properties = await getAvailableProperties(transactionType, neighborhoods, region, state, fetchType, filters)

      const selectedInfo = properties.data;
      if (selectedInfo[0]) {
        selectedInfo.map((property) => {
          console.log(property);
          if (!property.neighborhoodOrigin) {
            property.neighborhoodOrigin = neighborhoods;
          }
          arr.push(property);
        })
      }
      this.setState({ propertyHolder: arr });
      this.setState({ lastNeighborhood: neighborhoods });
      this.setState({ updateMap: true });

    }
    catch (error) {

    }
    this.setState({ propertiesLoading: false })
  }
  async componentDidMount() {
    const region = this.props.match.params.region.replace(/[0-9]/g);
    const state = this.props.match.params.state;
    const transactionType = this.props.match.params.transactiontype;
    const filters = this.props.location.search.replace("?", "");
    this.setState({ lastSetter: "render" })
    const neighborhoods = this.props.match.params.neighborhood.split("%20").join("_");
    if (neighborhoods !== "any") {
      this.setState({ neighborhoodTypeSelected: "custom" })
    }
    const neighborhoodParams = this.props.match.params.neighborhood.replace("%20", " ").split("&")
    this.setState({ neighborhoodParams: neighborhoodParams, neighborhoodParamString: neighborhoods })

    if (neighborhoodParams.length === 1) {
      await this.fetchProperties(transactionType, neighborhoodParams[0].split(" ").join("_"), region, state, filters, "firstLoad")

    }
    if (neighborhoodParams.length > 1) {
      await this.fetchProperties(transactionType, neighborhoods, region, state, filters, "multi")
    }

    const getMapCenter = LatLongs[`${region}, ${state}`];

    if (getMapCenter) {
      this.setState({ mapCenter: getMapCenter })
    }

    const neighborhoodDescriptors = await getNeighborhoodShapes(region, state)
    const neighborhoodData = neighborhoodDescriptors.data;
    // const insights = await GetInsights(region, state);
    // this.setState({cityInsights: insights.data.city});
    // insights.neighborhoods.forEach((neighborhood) => {

    // })
    this.setState({ geoJSON: neighborhoodDescriptors.data, areaNeighborhoods: neighborhoodDescriptors.data.features })
    this.setState({ geoLoading: false })
  }
  async componentDidUpdate(prevProps, prevState) {
    const region = this.props.match.params.region.replace(/[0-9]/g);
    const state = this.props.match.params.state;
    const transactionType = this.props.match.params.transactiontype;
    const filters = this.props.location.search.replace("?", "");
    let neighborhoodParams;
    if (this.props.match.params.neighborhood !== "any") {
      neighborhoodParams = this.props.match.params.neighborhood.replace("%20", " ").split("&")
    }
    if (this.props.match.params.neighborhood === "any") {
      neighborhoodParams = [];
      neighborhoodParams[0] = "any";
    }
    const neighborhoods = this.props.match.params.neighborhood.split("%20").join("_");
    if (prevProps.match.params.neighborhood !== this.props.match.params.neighborhood) {
      this.setState({ neighborhoodParams: neighborhoodParams, neighborhoodParamString: neighborhoods })
      if (this.state.mapChangeType === "add") {
        if (neighborhoodParams.length > 0) {
          if (!this.state.requestMulti) {
            await this.fetchProperties(transactionType, neighborhoodParams[neighborhoodParams.length - 1].split(" ").join("_"), region, state, filters, "single")
          }
          if (this.state.requestMulti) {
            await this.fetchProperties(transactionType, neighborhoods, region, state, filters, "multi")
            this.setState({ requestMulti: false })
          }
        }
      }

    }
    if (prevProps.match.params.region !== this.props.match.params.region) {
      this.setState({ neighborhoodParams: neighborhoodParams, neighborhoodParamString: neighborhoods, propertiesLoading: true, geoLoading: true })
      const getMapCenter = LatLongs[`${region}, ${state}`];
      if (getMapCenter) {
        this.setState({ mapCenter: getMapCenter })
      }

      const neighborhoodDescriptors = await getNeighborhoodShapes(region, state)
      this.setState({ geoJSON: neighborhoodDescriptors.data, areaNeighborhoods: neighborhoodDescriptors.data.features, geoLoading: false })
      await this.fetchProperties(transactionType, "any", region, state, filters, "regionChanged")
    }

    if (prevProps.location.search !== this.props.location.search) {
      if (neighborhoodParams.length === 1) {
        await this.fetchProperties(transactionType, neighborhoodParams[neighborhoodParams.length - 1].split(" ").join("_"), region, state, filters, "filtersChanged")
      }
      if (neighborhoodParams.length > 1) {
        await this.fetchProperties(transactionType, neighborhoods, region, state, filters, "multi")
      }
    }
  }

  render() {
    const neighborhoods = this.state.areaNeighborhoods;
    const first = [];
    const second = [];
    if (neighborhoods !== null && this.state.neighborhoodParams !== null) {
      neighborhoods.map((neighborhood) => {
        if (this.state.neighborhoodParams.includes(neighborhood.properties.NAME)) {
          first.push(neighborhood);
        }
        if (!this.state.neighborhoodParams.includes(neighborhood.properties.NAME)) {
          second.push(neighborhood);
        }
      });
      second.map((neighborhood) => {
        first.push(neighborhood);
      })
    }
    return (
<div className="App" style={{ margin: 0, padding: 0 }}>
      <div style={classes.root}>
        {this.state.loggingIn ? <Login /> : null}
        <CssBaseline />
        <main style={classes.content}>
          <div style={{ display: "flex", marginLeft: 5, flexDirection: "column", padding: 0, justifyContent: "flex-start", maxWidth: "100%", width: "100%", marginLeft: 0, marginRight: 0 }}>
            <div style={{ borderBottom: "1px solid rgba(105, 105, 105, .2)", display: "flex" }}><MenuSmall updateSearch={this.searchHandler} /></div>
            <NeighborhoodsHolder distributeState={this.state} checkin={queryString.parse(this.props.location.search).checkin}
              checkout={queryString.parse(this.props.location.search).checkout}

              changeFilters={this.changeFilterHandler}
              neighborhoods={neighborhoods}
              first={first}
              region={this.props.match.params.region.split("_").join(" ")}
              transactionType={this.props.match.params.transactiontype}
              initialMinPrice={queryString.parse(this.props.location.search).minPrice}
              initialMaxPrice={queryString.parse(this.props.location.search).maxPrice}
              initialMinSF={queryString.parse(this.props.location.search).minSF}
              initialMaxSF={queryString.parse(this.props.location.search).maxSF}
              initialMinUnits={queryString.parse(this.props.location.search).minUnits}
              initialMaxUnits={queryString.parse(this.props.location.search).maxUnits}
              initialMinBedrooms={queryString.parse(this.props.location.search).minBedrooms}
              initialMaxBedrooms={queryString.parse(this.props.location.search).maxBedrooms}
              initialMinBathrooms={queryString.parse(this.props.location.search).minBathrooms}
              initialMaxBathrooms={queryString.parse(this.props.location.search).maxBathrooms}
              checkedNeighborhoodsHandler={this.checkedNeighborhoodsHandler}
              neighborhoodTypeSelectorHandler={this.neighborhoodTypeSelectorHandler}
              initialGuests={queryString.parse(this.props.location.search).guests}
              initialBeds={queryString.parse(this.props.location.search).beds}
              initialBedrooms={queryString.parse(this.props.location.search).bedrooms}
              initialBathrooms={queryString.parse(this.props.location.search).bathrooms}
              transactionType={this.props.match.params.transactiontype} />




          </div>

          <MapAndProperties distributeState={this.state} clickedPropertyHandler={this.clickedPropertyHandler}
            updateMapLoadStatus={this.updateMapLoadStatus}
            region={this.props.match.params.region.split("_").join(" ")}
            transactionType={this.props.match.params.transactiontype}
            initialMinPrice={queryString.parse(this.props.location.search).minPrice}
            closeDialog={this.closeDialogHandler}
            initialMaxPrice={queryString.parse(this.props.location.search).maxPrice}
            checkin={queryString.parse(this.props.location.search).checkin}
            checkout={queryString.parse(this.props.location.search).checkout}
            fetchCompsHandler={this.fetchComps}
            changeFilters={this.changeFilterHandler}
            subjectId={this.state.subjectId}
            compMode={this.state.compMode}
            comps={this.state.compsHolder}
            initialGuests={queryString.parse(this.props.location.search).guests}
            initialBeds={queryString.parse(this.props.location.search).beds}
            initialBedrooms={queryString.parse(this.props.location.search).bedrooms}
            initialBathrooms={queryString.parse(this.props.location.search).bathrooms}
            updateChildComponentHandler={this.updateChildComponentHandler}
            updateChildComponentAsGroup={this.updateChildComponentAsGroup} />

        </main>
      </div>
      </div>
    );
  }
}
export default Properties;