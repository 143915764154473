import React, { useState, useRef, useEffect } from "react";
import {
  Container, Typography, Box,
  makeStyles, Button, Paper, fade,
} from "@material-ui/core";
import MailIcon from '@material-ui/icons/Mail';
import { withRouter } from "react-router-dom";
import { Fade as Slideshow } from "react-slideshow-image";
import SearchIcon from '@material-ui/icons/Search';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import Slider from "react-slick";
import InputBase from '@material-ui/core/InputBase';
import PhoneIcon from '@material-ui/icons/Phone';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import EventIcon from '@material-ui/icons/Event';
import  { NavLink, useHistory } from "react-router-dom";
import HomePageButton from "../components/HomePageButton";


const useStyles = makeStyles((theme) => ({
container: {
    position: "relative",
    justifyContent: "space-evenly",
    flexDirection: "column",
    alignItems: "flex-start",
    maxWidth: "100%",
    bottomBorder: "5px solid black",
    display: "flex",
    minHeight: "80vh",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      minHeight: "160vh",
    },
    [theme.breakpoints.down("sm")]: {

      justifyContent: "flex-start",
      paddingTop: "10vh",
      minHeight: "120vh",
      height: "120vh",
      maxHeight: "120vh"
    },
  },
  santabarb: {
    top: 0,
    position: "absolute",
    height: "80vh",
    width: "100%",
    overflow: "hidden",
    zIndex: -1,
    opacity: 1.0,
    [theme.breakpoints.down("md")]: {
      height: "160vh"
    },
    [theme.breakpoints.down("sm")]: {
      height: "130vh",
      maxHeight: "130vh"
    },
   "-webkit-mask-image": "linear-gradient(0deg, transparent 5%, black 50%)",
   "mask-image": "linear-gradient(0deg, transparent 5%, black 50%)",
  },
  avatar: {
     "-webkit-mask-image": "linear-gradient(0deg, transparent 1%, black 50%)",
   "mask-image": "linear-gradient(0deg, transparent 1%, black 50%)",
  },
infoBox: {
  height: "36.6vh",
  backgroundColor: "rgba(74, 69, 66, .45)",
  color: "white",
  width: "25%",
  display: "flex",
  display: "none",
  flexDirection: "column",
  justifyContent: "space-between",
  marginLeft: "0",
  [theme.breakpoints.down('sm')]: {
    display: "none",
  },

},
infoBox2: {
  display: "none",
  height: "36.6vh",
  backgroundColor: "rgba(74, 69, 66, .45)",
  color: "white",
  width: "25%",
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  left: "75%",

},
  slideshowImage: {
    display: "flex",
    objectFit: "cover",
    opacity: .60,
    objectPosition: "middle",
    width: "100%",
    height: "80vh",
    [theme.breakpoints.down("md")]: {
      height: "160vh"
    },
    [theme.breakpoints.down("sm")]: {
      height: "100vh",
    },
  },
  


  vector: {
    bottomBorder: "1px solid white"
  },
  icon: {
    [theme.breakpoints.down("xs")]: {
      width: 100,
      height: 10,
      marginLeft: 20,
    },
    width: 15,
    height: 40,
    marginTop: 0,
    position: "relative",
  
  },

  search: {
    position: "relative",
    top: "8vh",
    display: "flex",
    justifyContent: "center",
    height: 50,
    backgroundColor: fade(theme.palette.common.white, .25),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, .15),
    },
    width: '25%',
    [theme.breakpoints.down('sm')]: {
      height: 50,
      top: "10vh",
      width: "80%",
    },
  },
  searchButton: {
    backgroundColor: "#a99136",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 5,
    "&:hover": {
      backgroundColor: "#a99136"
    }
  },
  searchIcon: {
    
    color: "white",
    fontSize: "30px"
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {

    display: "flex",
    justifyContent: "center",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  button: {
    backgroundColor: "#a99136",
    "&:hover": {
    backgroundColor: "#ead983",
    },
    color: "white",
    borderRadius: 0,
    fontWeight: 800,
    marginTop: 10,
    marginLeft: 50
},
button3: {
  backgroundColor: "#a99136",
  "&:hover": {

  },
  color: "white",
  borderRadius: 0,
  fontWeight: 800,
  marginBottom: -5,
  marginTop: 10,
  height: 36,
  marginRight: 10,
  [theme.breakpoints.down("sm")]: {
    marginBottom: 0,
    backgroundColor: "white",
    color: "#a99136",
    width: 200,
    textAlign: "left"
  }
},
icon: {
  color: "#a99136",
  marginTop: 0,
  marginBottom: 0,
  transform: "scale(2.0)",
  [theme.breakpoints.down("sm")]: {
    display: "none"
  }
}, 

button4: {
  backgroundColor: "white",
  "&:hover": {
  backgroundColor: "white",
  },
  color: "#a99136",
  borderRadius: 0,
  height: 36,
  marginBottom: -5,
  fontWeight: 800,
  marginTop: 10,
  marginRight: 10,
  [theme.breakpoints.down("sm")]: {
    marginBottom: 0,
    width: 200,
    textAlign: "left"
  }
},
button5: {
  backgroundColor: "white",
  color: "#a99136",
  fontSize: 12,
  fontWeight: 400,
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 20,
  paddingRight: 20,
  display: "flex",
  fontWeight: 800,
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 0,
  "&:hover": {
    backgroundColor: "white",
    },
  margin: 0
},
button2: {
  backgroundColor: "#ead983",
  "&:hover": {
  backgroundColor: "#c86430",
  },
  color: "white",
  borderRadius: 0,
  fontWeight: 800,
},
buttonArrowDiv: {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  height: 46,
  padding: 0,
  margin: 0,
  [theme.breakpoints.down("sm")]: {
    height: "100%",
    textAlign: "left",
    marginTop: 10
  }
},

slideArrowLeft: {
  position: "absolute",
  bottom: "-10vh",
  right: "12.5%",
  color: "black",
  fontSize: 30,
  pointerEvents: "none",
  transform: "rotate(180deg)",
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "none"
  },
},
  slideArrowRight: {
    position: "absolute",
    bottom: "-10vh",
    fontSize: 30,
    right: "10%",
    color: "black",
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
    },
slidePaper1: {
  position: "absolute", 
  marginLeft: "10%", 
  boxShadow: "0px 2px 18px -3px #DBDBDB",
  borderRadius: 2,
  paddingLeft: "2.5px", 
  paddingRight: "2.5px", 
  paddingTop: "15px", 
  paddingBottom: "15px", 
  display: "flex", 
  justifyContent: "center", 
  alignItems: "center", 
  width: "80%", 
  backgroundColor: "rgba(255, 255, 255, .3)",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    justifyContent: "flex-start",
    maxHeight: "80vh",
    height: "100%",
    alignItems: "center",
    marginLeft: "5%",
    width: "90%",
    top: "40vh"

},
},
slidePaper2: {
  backgroundColor: "#f7f7f7", 
  boxShadow: "0px 2px 18px -3px #DBDBDB",
  height: "240px", 
  borderRadius: 2,
  width: "98%", 
  display: "flex", 
  justifyContent: "flex-start",
  alignItems: "flex-start",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    height: "80vh",
    maxWidth: "300px",
  overflow: "auto"
}
},
tagline: {
  fontSize: "3.2em",
  marginTop: "2vh",
  [theme.breakpoints.down("sm")]: {
    marginTop: "0vh",
    fontSize: "2.2em", 
    textAlign: "left",
    display: "none",
  },
},
tagline2: {
  fontSize: "1.5em",

  [theme.breakpoints.down("sm")]: {
    display: "none"
  },
},
Box: {
  height: "100%", 
  width: "300px",
  [theme.breakpoints.down("sm")]: {
    height: "220px",
    minHeight: "220px",
    minWidth: "300px",
    width: "300px",
    maxHeight: "220px",
    maxWidth: "300px"
  }
},
headlineDiv: {
  display: "flex", 
  flexDirection: "column", 
  marginLeft: "10%", 
  justifyContent: "flex-start", 
  alignItems: "flex-start", 
  position: "relative", 
  top: 60, 
  [theme.breakpoints.down("sm")]: {
    marginLeft: "5%",
    top: 0
  }
},
controlPanel: {
  backgroundColor: "rgba(255, 255, 255, .6)", 
  paddingLeft: 10, 
  paddingRight: 10, 
  paddingTop: 10, 
  paddingBottom: 10, 
  marginTop: 15, 
  display: "flex", 
  justifyContent: "space-evenly",
  [theme.breakpoints.down("sm")]: {
    display: "none"
},
},
paperBox: {
  width: "70%", 
  height: "90%", 
  display: "flex", 
  marginLeft: 20, 
  marginTop: 20, 
  flexDirection: "column", 
  justifyContent: "space-evenly",
  alignItems: "flex-start",
  [theme.breakpoints.down("sm")]: {
    textAlign: "left",
    width: "90%"
},
},
innerBox: {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
},
  selectionButtons: {
    display: "flex",  
    marginBottom: 0, 
    justifyContent: "center", 
    padding: 0, 
    height: 46, 
    alignItems: "center", 
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: "100%"
  }
}

}));

const slideshow = [
  require("../assets/images/map.jpg"),
];


const HeaderSlideShow = (props) => {
  /* eslint-disable-next-line */
  const history = useHistory();
  const classes = useStyles();
  const [propertyType, SetPropertyType] = useState(null);
  const [location, SetLocation] = useState(null);
  const [minPrice, SetMinPrice] = useState(null);
  const [maxPrice, SetMaxPrice] = useState(null);
  const [minUnits, SetMinUnits] = useState(null);
  const [width, SetWidth] = useState(0);
  const [height, SetHeight] = useState(0);;
  const [maxUnits, SetMaxUnits] = useState(null);
  const [loading, SetLoading] = useState(false);
  const widthRef = useRef(null);
  const slideRef = useRef(null);

  const [selection, SetSelection] = useState("findProperty");
  const [searchString, SetSearchString] = useState(null);
  const handleChangeSelection = (buttonValue) => {
    if (buttonValue && buttonValue !== selection) {
      SetSelection(buttonValue);
    }
  }


const handleSetValues = (category, value) => {
SetLoading(true);
 switch(category) {
  case "type": 
  SetPropertyType(value);
  break;
  case "location":
    SetLocation(value);
    break;
  case "minPrice":
    SetMinPrice(value);
    break;
  case "maxPrice":
    SetMaxPrice(value);
    break;
  case "minUnits":
    SetMinUnits(value);
    break;
  case "maxUnits":
    SetMaxUnits(value);
    break;
}
SetLoading(false);
}
const navigateToSearch = async () => {
  const mapValues = () => {
    let navString;
    const stateObj = {
      "type": propertyType,
      "location": location,
      "minPrice": minPrice,
      "maxPrice": maxPrice,
      "minUnits": minUnits,
      "maxUnits": maxUnits,
    }
    Object.entries(stateObj).map((val) => {
      if (val[1]) {
        if (navString) {
        navString += `&${val[0]}=${val[1]}`
      }
      if (!navString) {
        navString = `${val[0]}=${val[1]}`
      }
    }
  
    })
  
    if (navString) {
      SetSearchString(navString);
      history.push(`/Properties/sale/Santa_Barbara/CA/any?${navString}`)
    }
    if (!navString) {
      history.push(`/Properties/sale/Santa_Barbara/CA/any?`)
    }
  }

  const checkLoadingandUpdate = () => {
    if (!loading) {
      mapValues();
    }
    else if (loading) {
      return checkLoadingandUpdate();
    }
  }
  await checkLoadingandUpdate();
}

const Slide = (props) => {
  
  return (
  <div ref={slideRef} className={classes.slidePaper1}>
  <div className={classes.slidePaper2} ><Box className={classes.Box}>
  <img src={props.source} style={{height: "100%", borderRadius: 2, width: "100%"}}>

  </img>
  </Box>
  <Box className={classes.paperBox}>
    <Box className={classes.innerBox}>
    <Typography style={{color: "#a99136"}}>{props.category}</Typography>
    <Typography style={{color: "black", fontWeight: 800}}>{props.headline}</Typography>
    <Typography style={{color: "black", textAlign: "left"}}>{props.text}</Typography>
    </Box>
    <Button id={props.slideID} className={classes.button} style={{ marginLeft: 0, zIndex: 10}} onClick={(event) => history.push(`/posts/${props.postID}`)}>Read More</Button>
  </Box>
</div>
</div>
  )
}
const SlideArrow = (props) => {
  return props.direction === "left" ? <ArrowRightAltIcon className={classes.slideArrowLeft} /> : <ArrowRightAltIcon className={classes.slideArrowRight} />
}

const slideProperties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  pauseOnHover: false,
  prevArrow: <SlideArrow direction="left" />,
  nextArrow: <SlideArrow direction="right" />,

}

const slidesArr = [
    <Slide category={"News"} source={"https://townzphotos.s3.us-west-2.amazonaws.com/images/cord.PNG"} postID="iv1683" headline={"Sharif Elseify Brokers 6 Unit Apartment Sale in Isla Vista"} text={"Sharif was a  dual broker in the sale of a highly sought after 6 unit building in the heart of Isla Vista, close to campus."} />,
<Slide category={"News"} source={"https://townzphotos.s3.us-west-2.amazonaws.com/images/sold.jpg"} 
headline={"Sharif Elseify Arranges Apartment Sale in Atascadero"} postID="hce803" text={"Sharif Elseify represented the buyer in this off market sale of a 16 unit multi-family asset in Atascadero for $2,750,000"} />,
  <Slide category={"News"} source={"https://townzphotos.s3.us-west-2.amazonaws.com/images/news.jpg"} postID="aeX1HC" headline={"Sharif Elseify Launches Santa Barbara Apartment Group"} text={"After 7 years in the industry working for Lee & Associates and Radius Group, Sharif Elseify launched SB APT to serve the apartment market from San Luis Obispo to Ventura Counties"} />,
];

useEffect(() => {
if (widthRef) {
  if (widthRef.current) {
    SetWidth(widthRef.current.offsetWidth);
    SetHeight(widthRef.current.offsetHeight);
  }
}
}, [])


  return (
    <Box className={classes.container}>
      <Box className={classes.santabarb}>
        <Slideshow arrows={false}>
          {
            slideshow.map((show, index) => (
              <img style={{ borderBottom: "5px solid black" }}
                key={index}
                alt="img"
                src={show}
                className={classes.slideshowImage}
              />
            ))
          }
        </Slideshow>
      </Box>
      <div className={classes.headlineDiv} style={{}}>
      <Typography className={classes.tagline} variant="h2" style={{  fontWeight: 800, fontFamily: "sweet-sans-pro", color: "black", marginBottom: 0,  }}>Santa Barbara Apartment Group</Typography>
      <Typography className={classes.tagline2} variant="h2" style={{  fontWeight: 800, fontFamily: "sweet-sans-pro", color: "black", marginBottom: 10, marginTop: 10  }}>Specializing in Multi Family Investments Along the Central Coast</Typography>
      <div className={classes.selectionButtons}>
        {selection === "findProperty" ?
        <div className={classes.buttonArrowDiv}>
          <Button className={classes.button3} onClick={()=> handleChangeSelection("findProperty")}>FIND A PROPERTY</Button><ArrowDropUpIcon className={classes.icon} /></div> : <div className={classes.buttonArrowDiv}><Button className={classes.button4} onClick={()=> handleChangeSelection("findProperty")}>FIND A PROPERTY</Button></div> }
          {selection === "insights" ? <div className={classes.buttonArrowDiv}><Button className={classes.button3} onClick={()=> handleChangeSelection("insights")} >GAIN INSIGHTS</Button><ArrowDropUpIcon className={classes.icon} /></div> : <div className={classes.buttonArrowDiv}><Button className={classes.button4 } onClick={()=> handleChangeSelection("insights")}>GAIN INSIGHTS</Button></div> }
          {selection === "connect" ? 
          <div className={classes.buttonArrowDiv}><Button className={classes.button3} onClick={()=> handleChangeSelection("connect")}>CONNECT</Button><ArrowDropUpIcon className={classes.icon} /></div> : <div className={classes.buttonArrowDiv}><Button className={classes.button4} onClick={()=> handleChangeSelection("connect")}>CONNECT</Button></div> }
      </div>
   
        
    
      <Paper className={classes.controlPanel} >
            {selection === "findProperty" ? 
           <div id={"parentDiv"} style={{display: "flex", justifyContent: "flex-start", alignItems: "center", padding: 0, margin: 0}} ref={widthRef}>
           <HomePageButton minUnits={minUnits} maxUnits={maxUnits} location={location} propertyType={propertyType} minPrice={minPrice} maxPrice={maxPrice}  setValues={handleSetValues} filterType={"type"} title={"All Property Types"} />
           <HomePageButton minUnits={minUnits} maxUnits={maxUnits} location={location} propertyType={propertyType} minPrice={minPrice} maxPrice={maxPrice}  setValues={handleSetValues} filterType={"location"} title={"Any Location"} /> 
           <HomePageButton minUnits={minUnits} maxUnits={maxUnits} location={location} propertyType={propertyType} minPrice={minPrice} maxPrice={maxPrice}  setValues={handleSetValues}filterType={"price"} title={"Any Price"} />
           <HomePageButton minUnits={minUnits} maxUnits={maxUnits} location={location} propertyType={propertyType} minPrice={minPrice} maxPrice={maxPrice}  setValues={handleSetValues}filterType={"units"} title={"Any Units"} />
           <Button className={classes.searchButton} onClick={() => navigateToSearch()}><SearchIcon className={classes.searchIcon}/></Button>
           </div>
          : selection === "insights" ?       <div style={{width: width, height: height, display: "flex", justifyContent: "space-evenly", alignItems: "center", padding: 0, margin: 0}}>
          <Button className={classes.button5}>Neighborhood Analyzer</Button>
          <Button className={classes.button5}>Value my Property</Button>
          <Button className={classes.button5}>Market Reports</Button></div>
           : selection ==="connect" ?   <div style={{ width: width, height: height, display: "flex", justifyContent: "space-around", alignItems: "center", padding: 0, margin: 0}}>
           <Button className={classes.button5}><MailIcon />EMAIL</Button>
           <Button className={classes.button5}><PhoneIcon />PHONE</Button>
           <Button className={classes.button5}><EventIcon />SET MEETING</Button></div> : null}
      </Paper>
      </div>
      {props.page === "Home" ? 
      <Slideshow {...slideProperties}>
        {
          slidesArr.map((show, index) => {
            return (
              slidesArr[index]
            )
        })
      }

      </Slideshow>
 : null}
      {/* <Paper className={classes.search}>
           
           <InputBase style={{ marginTop: 8 }}
             placeholder="Search by Property"
            className={classes.inputInput}
             inputProps={{ 'aria-label': 'search' }}
           />
           <NavLink className={classes.button} to="valuation" style={{ textDecoration: "none", height: "100%", fontFamily: "sweet-sans-pro", textAlign: "center" }}><Button style={{ borderRadius: 2, fontFamily: "sweet-sans-pro", fontWeight: 600, color: "white", height: "100%" }}>GO</Button></NavLink>
           </Paper> */}
    </Box>
  );
}

export default HeaderSlideShow;
