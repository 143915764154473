import SETTINGS from "../settings";
import axios from "axios";

const register = async (username, password, email) => {
    const baseUrl = SETTINGS.DOMAINREQUIREMENTS.BACKEND.baseUrl
    const registerRequest = await axios.post(`${baseUrl}/register`, {
        data: { 
        username: username,
        password: password,
        email: email,
        },
        withCredentials: true,
    });

    return registerRequest
}

export default register