import React, { useState } from "react";
import { Typography, Button, Paper, Popper, makeStyles, withStyles } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import TextField from '@material-ui/core/TextField';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Checkbox from "@material-ui/core/Checkbox";
const useStyles = makeStyles((theme) => ({
    button2: {
        width: 120,  
        height: 30,
        color: "black", 
        color: "#a99136",
        marginTop: 5,
        fontFamily: "sweet-sans-pro",
        borderRadius: 2,
        color: "black",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 5, 
        marginLeft: 0,
        marginRight: 5,
        border: "1px solid gray",
        padding: 5,
        paddingLeft: 30,
        paddingRight: 30,
        cursor: "pointer",   
      },
      button: {
        width: 60,
        padding: 0,
        borderRadius: "2",
        backgroundColor: "black",
        color: "white",
        border: "1px solid black"
      },
      popper: {
        width: 200,  
        fontFamily: "sweet-sans-pro",
        minHeight: 100,
        borderRadius: 2,
        color: "black",
        display: "flex",
        flexDirection: "column",
        maxHeight: 500,
        overflowY: "auto",
        marginBottom: 5, 
        marginLeft: 0,
        backgroundColor: "white",
        border: "1px solid gray",
        paddingTop: 5,
        paddingBottom: 5,
        zIndex: 1000,
        cursor: "pointer",           
      }
    }));

    const GreenCheckbox = withStyles({
        root: {
          color: "#a99136",
          transform: "scale(.75)",
          height: 10,
          width: 10,
          '&$checked': {
            color: "#a99136",
            height: 10,
            width: 10,
          },
        },
        checked: {},
      })((props) => <Checkbox color="#a99136" {...props} />);
    const FilterBtn = (props) => {
        let initialMinPrice = 0;
        let initialMaxPrice = 0;
        let initialMinBaths = 0;
        let initialMaxBaths = 0;
        let initialMinBedrooms = 0;
        let initialMaxBedrooms = 0;
        let initialMinSF = 0;
        let initialMaxSF = 0;
        let initialMinUnits = 0;
        let initialMaxUnits = 0;

        if (props.initialMinPrice && parseInt(props.initialMinPrice > 0)) {
            initialMinPrice = parseInt(props.initialMinPrice, 10);
        }
        if (props.initialMaxPrice && parseInt(props.initialMaxPrice > 0)) {
            initialMaxPrice = parseInt(props.initialMaxPrice, 10);
        }
        if (props.initialMinBaths && parseInt(props.initialMinBaths > 0)) {
            initialMinBaths = parseInt(props.initialMinBaths, 10);
        }
        if (props.initialMaxBaths && parseInt(props.initialMaxBaths > 0)) {
            initialMaxBaths = parseInt(props.initialMaxBaths, 10);
        }
        if (props.initialMinBedrooms && parseInt(props.initialMinBedrooms, 10) > 0) {
            initialMinBedrooms = parseInt(props.initialMinBedrooms, 10);
        }
        if (props.initialMaxBedrooms && parseInt(props.initialMaxBedrooms, 10) > 0) {
            initialMaxBedrooms = parseInt(props.initialMaxBedrooms, 10);
        }
        if (props.initialMinSF && parseInt(props.initialMinSF, 10) > 0) {
            initialMinSF = parseInt(props.initialMinSF, 10);
        }
        if (props.initialMaxSF && parseInt(props.initialMaxSF, 10) > 0) {
            initialMaxSF = parseInt(props.initialMaxSF, 10);
        }
        if (props.initialMinUnits && parseInt(props.initialMinUnits, 10) > 0) {
            initialMinUnits = parseInt(props.initialMinUnits, 10);
        }
        if (props.initialMaxUnits && parseInt(props.initialMaxUnits, 10) > 0) {
            initialMaxUnits = parseInt(props.initialMaxUnits, 10);
        }

        let defaultType = "Res Inc"

        if (props.initialType) {
            defaultType = props.initialType;
        }

        const classes = useStyles();
        const [open, SetOpen] = useState(false);
        const [type, SetType] = useState(null)
        const [minPrice, SetMinPrice] = useState(initialMinPrice);
        const [maxPrice, SetMaxPrice] = useState(initialMaxPrice);
        const [minBathrooms, SetMinBathrooms] = useState(initialMinBaths);
        const [maxBathrooms, SetMaxBathrooms] = useState(initialMaxBaths);
        const [minBedrooms, SetMinBedrooms] = useState(initialMinBedrooms);
        const [maxBedrooms, SetMaxBedrooms] = useState(initialMaxBedrooms);
        const [minSF, SetMinSF] = useState(initialMinSF);
        const [maxSF, SetMaxSF] = useState(initialMaxSF);
        const [minUnits, SetMinUnits] = useState(initialMinUnits);
        const [maxUnits, SetMaxUnits] = useState(initialMaxUnits);
        const [anchorEl, SetAnchorEl] = useState(null);

        const handleClick = (event) => {
            SetOpen(!open);
            SetAnchorEl(anchorEl ? null : event.currentTarget);
        }

        const handleType = (event) => {
            SetType(event.target.id)
        }
        const handleSubmit = (filterType) => {
            let filterObj;

            if (filterType === "size") {
                filterObj = {
                        minBedrooms: minBedrooms,
                        maxBedrooms: maxBedrooms,
                        minBathrooms: minBathrooms,
                        maxBathrooms: maxBathrooms,
                        minSF: minSF,
                        maxSF: maxSF,
                        minUnits: minUnits,
                        maxUnits: maxUnits,
                    }

            }
            if (filterType === "type") {
                filterObj = {
                    type: type
                }
            }

            if (filterType === "price") {
                filterObj = {
                    minPrice: minPrice,
                    maxPrice: maxPrice,
                }
            }
            props.changeFilters(filterType, filterObj);
        }

        let options;
        let content;

        if (props.filterType === "type") {

            content = <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%"}}>
            <div style={{display: "flex", paddingLeft: 10, justifyContent: "center", marginBottom: 5, alignItems: "center"}}>
                <GreenCheckbox id={"Res"} checked={type==="Res"} onChange={(event) => handleType(event)} /><Typography>Residential</Typography></div>
                <div style={{display: "flex", paddingLeft: 10, justifyContent: "center", marginBottom: 5, alignItems: "center"}}>
                <GreenCheckbox id={"Res Inc"} checked={type==="Res Inc"} onChange={(event) => handleType(event)} /><Typography>Residential Income</Typography></div>
                <div style={{display: "flex", paddingLeft: 10, justifyContent: "center", marginBottom: 5, alignItems: "center"}}>
                <GreenCheckbox id={"Comm/Ofc"} checked={type==="Comm/Ofc"} onChange={(event) => handleType(event)} /><Typography>Commerical / Office</Typography></div>
                <div style={{display: "flex", paddingLeft: 10, justifyContent: "center", marginBottom: 5, alignItems: "center"}}>
                <GreenCheckbox id={"Rent"} checked={type==="Rent"} onChange={(event) => handleType(event)}/><Typography>Rent</Typography></div>
                <div style={{display: "flex", paddingLeft: 10, width: "100%", justifyContent: "flex-start", paddingLeft: 10, marginBottom: 5, alignItems: "center"}}>
                <Button onClick={() => handleSubmit("type")} className={classes.button}>Go</Button></div>
                </div>
        }

        if (props.filterType === "price") {
                content = <div style={{display: "flex", maxWidth: "30", justifyContent: "flex-start", alignItems: "center"}}>
                    <form style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between"}}>
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start"}}>
                    <TextField defaultValue={props.initialMinPrice} onChange={(event) => SetMinPrice(event.target.value)} style={{height: 10, width: "80%", transform: "scale(.75)", marginBottom: 50}} id="outlined-basic" label="Min Price" variant="outlined" />
                    <TextField defaultValue={props.initialMaxPrice} onChange={(event) => SetMaxPrice(event.target.value)} style={{height: 10, width: "80%", transform: "scale(.75)", marginBottom: 50}} id="outlined-basic" label="Max Price" variant="outlined" />
                    </div>
                    <Button onClick={() => handleSubmit("price")} className={classes.button}>Go</Button>
                    </form>
                    </div>;

            
        }
        if (props.filterType === "size") {
            content = <div style={{display: "flex", maxWidth: "30", justifyContent: "flex-start", alignItems: "center"}}>
            <form style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between"}}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start"}}>
            <TextField defaultValue={props.initialMinBedrooms} onChange={(event) => SetMinBedrooms(event.target.value)} style={{height: 10, width: "80%", transform: "scale(.75)", marginBottom: 50}} id="outlined-basic" label="Min Bedrooms" variant="outlined" />
            <TextField defaultValue={props.initialMaxBedrooms} onChange={(event) => SetMaxBedrooms(event.target.value)} style={{height: 10, width: "80%", transform: "scale(.75)", marginBottom: 50}} id="outlined-basic" label="Max Bedrooms" variant="outlined" />
            <TextField defaultValue={props.initialMinBathrooms} onChange={(event) => SetMinBathrooms(event.target.value)} style={{height: 10, width: "80%", transform: "scale(.75)", marginBottom: 50}} id="outlined-basic" label="Min Bathrooms" variant="outlined" />
            <TextField defaultValue={props.initialMaxBathrooms} onChange={(event) => SetMaxBathrooms(event.target.value)} style={{height: 10, width: "80%", transform: "scale(.75)", marginBottom: 50}} id="outlined-basic" label="Max Bathrooms" variant="outlined" />
            <TextField defaultValue={props.initialMinSF} onChange={(event) => SetMinSF(event.target.value)} style={{height: 10, width: "80%", transform: "scale(.75)", marginBottom: 50}} id="outlined-basic" label="Min SF" variant="outlined" />
            <TextField defaultValue={props.initialMaxSF} onChange={(event) => SetMaxSF(event.target.value)} style={{height: 10, width: "80%", transform: "scale(.75)", marginBottom: 50}} id="outlined-basic" label="Max SF" variant="outlined" />
            <TextField defaultValue={props.initialMinUnits} onChange={(event) => SetMinUnits(event.target.value)} style={{height: 10, width: "80%", transform: "scale(.75)", marginBottom: 50}} id="outlined-basic" label="Min Units" variant="outlined" />
            <TextField defaultValue={props.initialMaxUnits} onChange={(event) => SetMaxUnits(event.target.value)} style={{height: 10, width: "80%", transform: "scale(.75)", marginBottom: 50}} id="outlined-basic" label="Max Units" variant="outlined" />
            </div>
            <Button onClick={() => handleSubmit("size")} className={classes.button}>Go</Button>
            </form>
            </div>;
        }
        return (
            <div>
            <Button key={"01"} onClick={handleClick} className={classes.button2}>
                <Typography style={{fontWeight: 0, fontFamily: "sweet-sans-pro"}}>{props.title}</Typography>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </Button>
            <Popper style={{zIndex: 1000 }} key={"02"} open={open} anchorEl={anchorEl}>
                <div className={classes.popper}>{content}</div>
            </Popper>
            </div>
        )
    }
export default FilterBtn;
