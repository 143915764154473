import React from "react";
import { Typography, Paper, Box, Container, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    mainBox: {
        width: "100%",
        padding: "5px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    }
}))
const formatter = (numStr) => {
    const numRet = new Intl.NumberFormat('en-US', {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
    })
    return numRet.format(numStr)
}
const CompEntry = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.mainBox}>
            <Paper style={{width: "98%", height: "180px", borderRadius: 2}}>
            <img style={{height: "98%", width: "98%"}} src={props.imageURL}></img>
            </Paper>

            <Typography>{props.address}</Typography>
            <Typography style={{fontWeight: 800}}>{formatter(props.price)}</Typography>
            {!props.subject ? 
            <div>
            <Typography>{props.distance.toFixed(2)} mi away</Typography>
            <Typography>Sold {props.date}</Typography></div> :null}
        </div>
    )
}
export default CompEntry