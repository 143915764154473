import React, { useState } from 'react';
import { makeStyles, Toolbar, Box, Typography, Container } from '@material-ui/core';
import MenuEntry from "./MenuEntries";
import DehazeIcon from '@material-ui/icons/Dehaze';
import MobileMenuEntry from "./MobileMenuEntry";
import { NavLink } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  
  logo: {
    marginLeft: 0,
    marginRight: 50,
    padding: 0,
    width: 150,
    marginTop: 0,
    [theme.breakpoints.down("sm")]: {
      width: "200",
      display: "flex",
      height: "200",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      margin: 0,
      padding: 0,
      },
  },
  img: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
    height: "100%",
    width: "100%",
    }
  },
  expand: {
    bottom: 0,
    [theme.breakpoints.down("sm")]: {
    display: "none",
    },
  },
  iconMenuMobile: {
    // [theme.breakpoints.up("lg")]: {
    //   display: "none",
    //   },
    //   [theme.breakpoints.up("md")]: {
    //     display: "none",
    //     },
        [theme.breakpoints.down("sm")]: {
          width: "100",
          padding: 0,
          margin: 0,
          marginRight: 5,
          },

  },
  smallTypeClass: {
    fontWeight: 800,
    marginRight: 5,
    fontSize: 9, 
    color: "#a99136"
  },
  phoneNumber: {
    position: "absolute", 
    right: "0%", 
    bottom: "5%", 
    fontWeight: 800,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
  },
  toolbar: {
    maxHeight: 75,
    marginLeft: 0,
    padding: 0,
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
     display: "flex",
     width: "100%",
     height: "100%",
     maxHeight: "100%",
     paddingLeft: 0,
     padding: 0,
     paddingRight: 0,
     marginRight: 0,
     marginLeft: 0,
     alignItems: "center",
     justifyContent: "space-between"
      },
  },

}));

const Menu = (menuProps) => {
const classes = useStyles();
const [open, setOpen] = React.useState(false);
const anchorRef = React.useRef(null);
function handleListKeyDown(event) {
  if (event.key === 'Tab') {
    event.preventDefault();
    setOpen(false);
  }
}
const handleToggle = (event, props) => {
//setMenuIndex(event.target.id);
setOpen((prevOpen) => !prevOpen);
}
const prevOpen = React.useRef(open);
React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
       anchorRef.current.focus();
     }
     prevOpen.current = open;

   }, [open]);
return (
   <Toolbar ref={anchorRef} className={classes.toolbar}>
    <Box className={classes.logo}
      href="/"
        style={{ lineHeight: 0, }}
    >
      <NavLink style={{width: "100%", height: "100%", textDecoration: 'none'}} to="/">
    <img className={classes.img}
    alt="logo"
    width="100%"
    src={require("../assets/images/logocrop.svg")}
    />
    </NavLink>
    
    </Box>
    
    
    <Box className={classes.expand}>
<MenuEntry name="SERVICES" />
    <MenuEntry name="LISTINGS" />
   <MenuEntry name="RESEARCH" />
    <MenuEntry name="POSTS" />
    <MenuEntry name="ABOUT" />
    </Box>
   
  
  <Typography className={classes.phoneNumber}>(805) 858-8872</Typography>
 <MobileMenuEntry ankr={anchorRef} tog={handleToggle} opn={open} keydown={handleListKeyDown}/>
</Toolbar>

  );
}
export default Menu;