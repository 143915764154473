module.exports = Object.freeze(
   
{

colors: [
    "#000000",
"#00FF3F",
"#00FF7F",
"#B57EDC",
"#40826D",
"#7B3F00",
"#9966CC",
"#FFFFFF",
"#960018",
"#FF0000",
"#0F52BA",
"#003153",
"#CD7F32",
"#B87333",
"#708090",
"#CCCCFF",
"#C8A2C8",
"#FFD700",
"#CC8899",
"#3FFF00",
"#1C39BB",
"#DC143C",
"#000080",
"#FFE5B4",
"#0000FF",
"#7FFFD4",
"#808080",
"#FF007F",
"#F5F5DC",
"#800020",
"#FFBF00",
"#6F4E37",
"#FFFFF0",
"#00A86B",
"#800000",
"#C71585",
"#D2B48C",
"#DE3163",
"#FBCEB1",
"#007BA7",
"#FF00AF",
"#29AB87",
"#007FFF",
"#CC7722",
"#0095B6",
"#00FFFF",
"#800080",
"#E0115F",
"#40E0D0",
"#4B0082",
"#DA70D6",
"#DE5D83",
"#C0C0C0",
"#FA8072",
"#BFFF00",
"#008000",
"#FFF700",
"#FD6C9E",
"#7FFF00",
"#FFFF00",
"#3F00FF",
"#702963",
"#A7FC00",
"#FF7F50",
"#483C32",
"#8E4585",
"#8A2BE2",
"#808000",
"#008080",
"#7DF9FF",
"#EDC9Af",
"#E0B0FF",
"#7F00FF",
"#E30B5C",
"#FF00FF",
"#FF6600",
"#92000A",
"#FF4500",
"#F7E7CE",
"#89CFF0",
"#964B00",
"#0047AB",
"#50C878",
"#CB4154",
"#D1E231",
"#FF2400",
"#003153",
"#CD7F32",
"#B87333",
"#708090",
"#CCCCFF",
"#C8A2C8",
"#FFD700",
"#CC8899",
"#3FFF00",
"#1C39BB",
"#DC143C",
"#000080",
"#FFE5B4",
"#0000FF",
"#7FFFD4",
"#808080",
"#FF007F",
"#F5F5DC",
"#800020",
"#FFBF00",
"#6F4E37",
"#FFFFF0",
"#00A86B",
"#800000",
"#C71585",
"#D2B48C",
"#DE3163",
"#FBCEB1",
"#007BA7",
"#FF00AF",
"#29AB87",
"#007FFF",
"#CC7722",
"#0095B6",
"#00FFFF",
"#800080",
"#E0115F",
"#40E0D0",
"#4B0082",
"#DA70D6",
"#DE5D83",
"#C0C0C0",
"#FA8072",
"#BFFF00",
"#008000",
"#FFF700",
"#FD6C9E",
"#7FFF00",
"#FFFF00",
"#3F00FF",
"#702963",
"#A7FC00",
"#FF7F50",
"#483C32",
"#8E4585",
"#8A2BE2",
"#808000",
"#008080",
"#7DF9FF",
"#EDC9Af",
"#E0B0FF",
"#7F00FF",
"#E30B5C",
"#FF00FF",
"#FF6600",
"#92000A",
"#FF4500",
"#F7E7CE",
"#89CFF0",
"#964B00",
"#0047AB",
"#50C878",
"#CB4154",
"#D1E231",
"#FF2400",
"#7FFF00",
"#FFFF00",
"#3F00FF",
"#702963",
"#A7FC00",
"#FF7F50",
"#483C32",
"#8E4585",
"#8A2BE2",
"#808000",
"#008080",
"#7DF9FF",
"#EDC9Af",
"#E0B0FF",
"#FF6600",
"#92000A",
"#FF4500",
"#F7E7CE",
"#89CFF0",
"#964B00",
"#0047AB",
"#50C878",
"#CB4154",
"#D1E231",
"#FF2400",
"#7F00FF",
"#E30B5C",
"#FF00FF",
"#FF6600",
],
});
