const SANTABARBARA = require("./santabarbara");
const MAPCOLORS = require("./mapColorList");
const SANTABARBARA2 = require("./santabarbara2");
const SANFRANCISCO = require("./sanfrancisco2");

module.exports = {
SANTABARBARA,
SANTABARBARA2,
SANFRANCISCO,
MAPCOLORS,
};
