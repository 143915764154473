import React from "react";
import { Typography, Box, Paper, makeStyles, Container } from "@material-ui/core";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    container: {
        position: "relative",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "100%",
        bottomBorder: "5px solid black",
        display: "flex",
    
        minHeight: "80vh",
        textAlign: "center",
    
        [theme.breakpoints.down("md")]: {
          height: "120vh"
        },
        [theme.breakpoints.down("sm")]: {
          height: "80vh"
        },
      },
      santabarb: {
        top: 0,
        position: "absolute",
        borderBottom: "1px solid black",
        height: "80vh",
        width: "100%",
        overflow: "hidden",
        zIndex: -1,
        opacity: 1.0,
    
        [theme.breakpoints.down("md")]: {
          height: "120vh"
        },
        [theme.breakpoints.down("sm")]: {
          height: "80vh"
        },
       // "-webkit-mask-image": "linear-gradient(0deg, transparent 5%, black 50%)",
       // "mask-image": "linear-gradient(0deg, transparent 5%, black 50%)",
      },
      slideshowImage: {
        display: "flex",
        objectFit: "cover",
        objectPosition: "top",
        
        width: "100%",
        height: "80vh",
        [theme.breakpoints.down("md")]: {
          height: "120vh"
        },
        [theme.breakpoints.down("sm")]: {
          height: "80vh"
        },
      },
even: {
    position: "absolute",
    width: "50%", 
    left: "50%", 
    fontWeight: 800, 
    fontSize: 50, 
    color: "white", 
    display: "flex", 
    justifyContent: "center", 
    flexDirection: "column", 
    alignItems: "center",  
    backgroundColor: "rgba(0, 0, 0, 0.5)", 
    height: "100%"
},
mobileText: {
    fontWeight: 800, 
    fontFamily: "sweet-sans-pro", 
    fontSize: 50, 
    zIndex: 2,
    color: "white",
    [theme.breakpoints.up("lg")]: {
        display: "none"
    },
    [theme.breakpoints.up("md")]: {
        display: "none"
    }
},
standardText: {
    fontWeight: 800, 
    fontFamily: "sweet-sans-pro", 
    fontSize: 50, 
    color: "white",
    [theme.breakpoints.down("sm")]: {
        display: "none"
    }
},
mobileDiv: {
    zIndex: 3,
    [theme.breakpoints.up("lg")]: {
        display: "none"
    },
    [theme.breakpoints.up("md")]: {
        display: "none"
    }
},
standardDiv: {
    zIndex: 3,
    [theme.breakpoints.down("sm")]: {
        display: "none"
    }
},
odd: {
    position: "absolute",
    width: "50%", 
    left: "0%", 
    fontWeight: 800, 
    fontSize: 50, 
    color: "white", 
    display: "flex", 
    justifyContent: "center", 
    flexDirection: "column", 
    alignItems: "center",  
    backgroundColor: "rgba(0, 0, 0, 0.5)", 
    height: "100%"
},

}));
const FeaturedCity = (props) => {
const classes = useStyles();
const arr = props.cityArr;
const MobileDiv = () => {
return (
    <div className={classes.mobileDiv}>
    <Typography className={classes.mobileText}>FEATURED CITY: {props.city.toUpperCase()}</Typography>
    {props.direction === 0 ? 
    <div style={{position: "absolute", bottom: "5%", left: "50%", width: "50%", display: "flex", justifyContent: "center", textAlign: "center"}}>
<NavLink style={{textDecoration: "none"}} to={`properties/vacation/${props.city.split(" ").join("_")}/${props.state}/any?`}><Typography style={{color: "white", textDecoration: "underline", marginTop: 20, fontWeight: 400, fontSize: 25}}>Explore</Typography></NavLink>
</div> : props.direction !== 0 ? 
<div style={{position: "absolute", bottom: "5%", left: "0%", width: "50%", display: "flex", justifyContent: "center", textAlign: "center"}}>
<NavLink style={{textDecoration: "none"}} to={`properties/vacation/${props.city.split(" ").join("_")}/${props.state}/any?`}><Typography style={{color: "white", textDecoration: "underline", marginTop: 20, fontWeight: 400, fontSize: 25}}>Explore</Typography></NavLink>
</div> : null}
</div>
)
}

const StandardDiv = () => {
    return (
        <div className={classes.standardDiv}>
        <Typography className={classes.standardText}>FEATURED CITY: {props.city.toUpperCase()}</Typography>
    <NavLink style={{textDecoration: "none"}} to={`properties/vacation/${props.city.split(" ").join("_")}/${props.state}/any?`}><Typography style={{color: "white", textDecoration: "underline", marginTop: 20, fontWeight: 400, fontSize: 25}}>Explore</Typography></NavLink>
    </div>
    )
}
return (
    <Box className={classes.container}>
      <Box className={classes.santabarb}>
    {props.hiLo === "top" ?
  <img style={{ borderBottom: "5px solid black", opacity: .9 }}
                alt="img"
                src={arr[0]}
                className={classes.slideshowImage}
              /> : props.hiLo === "bottom" ? 
              <img style={{ borderBottom: "5px solid black", opacity: .9 }}
              alt="img"
              src={arr[0]}
              className={classes.slideshowImage}
              style={{objectPosition: "bottom"}}
            /> : null}
              </Box>
    <MobileDiv />
              {props.direction === 0 ? 
                            <Box className={classes.even}>
<StandardDiv />

                          </Box> : props.direction !== 0 ?               <Box className={classes.odd}>
                          <StandardDiv />
              </Box> : null}

              </Box> 
)
}

export default FeaturedCity;
