import React from "react";
import { createMuiTheme, ThemeProvider, CssBaseline } from "@material-ui/core";

export const theme = createMuiTheme({
  Typography: {
    allVariants: {
      fontFamily: "sweet-sans-pro",
    },
  
  },
  palette: {
    text: {
      primary: "#000",
      secondary: "#BBC9E0",
      disabled: "#9B9DA4",
    },
    primary: {
      main: "#fff",
    },
    secondary: {
      main: "#BBC9E0",
      // "#272D3A"
    },

  },

});

export default (Component) => () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Component />
  </ThemeProvider>
);
